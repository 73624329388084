/*eslint unicode-bom: ["error", "always"]*/

import React, { useEffect } from 'react';
import { addAppContentFullSize, removeAppContentFullSize } from '../helpers/ElementHelpers';

function Offline() {

    useEffect(() => {
        addAppContentFullSize();

        return () => {
            removeAppContentFullSize();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="home">
            <div className="welcome">
                <div className="title">Oops...!</div>
                <div className="description">
                    <p>
                        Du er visst offline.
                    </p>
                    <p>
                        Pr&oslash;v p&aring; nytt n&aring;r du er online igjen.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Offline;
