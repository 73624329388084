import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ValidateAccess from '../access/ValidateAccess';
import NoAccess from '../access/NoAccess';
import { isBoolean } from '../helpers/ObjectHelpers';
import { initializeTokenAsync } from '../helpers/TokenHelpers';
import { isBetaUser } from '../helpers/AuthHelpers';
import BaseIdbRepository from '../../repositories/idb/BaseIdbRepository';
import { RestoreDatabase } from '../access/RestoreDatabase';
import { RouteGuardStates } from '../../services/SystemNames';

export const RouteGuardDefault = ({ beta: Beta }) => {

    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    const location = useLocation();

    useEffect(() => {
        
        const validateAsync = async () => {

            const isDatabaseValid = await BaseIdbRepository.isDatabaseValidAsync();
            if (isDatabaseValid) {
                let flag = true
                await initializeTokenAsync();

                if (isBoolean(Beta)) {
                    if (Beta) {
                        flag = isBetaUser();
                    }
                }

                setGuardState(flag ? RouteGuardStates.Valid : RouteGuardStates.Login);

            } else {
                setGuardState(RouteGuardStates.RestoreDatabase);
            }
        }

        validateAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        (() => {
            switch (guardState)
            {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.RestoreDatabase:
                    return (<RestoreDatabase />);

                default:
                    return (<ValidateAccess />);
            }
        })()
    )

};
