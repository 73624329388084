import React from 'react';
import {
    InputPrefix,
    TextBox,
} from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import {
    searchIcon,
} from "@progress/kendo-svg-icons";

export default function SearchTextbox({
    value = "",
    onChange,
    placeholder = "",
    disabled = false,
    }) {

    return (
        <TextBox
            prefix={() => (
                <>
                    <InputPrefix>
                        <SvgIcon icon={searchIcon} />
                    </InputPrefix>
                </>
            )}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={onTextChange}
        />
    );

    function onTextChange(e) {
        if (disabled === true || onChange === undefined) return;

        onChange(e);
    }
}

