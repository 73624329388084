import './titlecontainer.css'

import React from 'react';
import parse from 'html-react-parser';
import Icons from './icons/Icons';
import { IconColors } from '../../services/SystemNames';
import CopyToClipboard from './CopyToClipboard';
import { isArrayEmpty, isNullOrEmpty } from '../helpers/ObjectHelpers';

export default function TitleContainer(
    {
        title = "",
        subTitle = "",
        canCopySubTitle = false,
        size = "medium",
        icon = "",
        iconDimensions = 40,
        actions = [],
        error = false
    }
) {
    return (
        <>
            <div className={`${error ? 'title-container-error' : ''} title-container title-container-${size}`}>
                <div>
                    {
                        !isNullOrEmpty(icon) &&
                        <Icons iconName={icon} color={IconColors.Primary} dimensions={iconDimensions} />
                    }
                </div>
                <div>
                    <div>
                        {parse(title)}
                    </div>
                </div>
                <div className={`${error ? 'title-container-error-actions' : ''} ${isArrayEmpty(actions) ? '' : 'title-container-actions'}`}>
                    {
                        actions.map((action, index) =>
                            <div key={index} onClick={action.onClick}>
                                <Icons iconName={action.icon} color={IconColors.Primary} dimensions={26} />
                            </div>
                        )
                    }
                </div>
            </div>
            {
                (!isNullOrEmpty(subTitle)) &&
                <div className="title-container-sub-title">
                    {
                        canCopySubTitle ?
                            <CopyToClipboard text={subTitle} />
                            :
                            parse(subTitle)
                    }
                </div>
            }
        </>
    );
}
