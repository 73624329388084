import React, { useState } from 'react';

import {
    TabStates,
} from '../../../services/SystemNames';

import Spacer from '../../../components/layout/Spacer'
import { Button } from "@progress/kendo-react-buttons";
import parse from 'html-react-parser';
import { CalendarCheck, CopyIcon } from '../../../components/layout/icons/Icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextAreaFieldWrapper from '../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { DialogConfirm } from '../../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { publishSuccessNotificationTopic } from '../../../components/helpers/PubSubHelpers';

export default function CalendarTabContent(
    {
        tabState = TabStates.Default,
        setTabState,
        calendarUrl = "",
        onReset,
        onOpenGoogleUrl,
        onOpenWebCalUrl
    }
) {

    const [isResetting, setIsResetting] = useState(false);

    const renderTabStateDefault = () => {
        return (
            <div className="container">
                <Spacer height={20} />
                <div className="row">
                    <div className="col text-center">
                        <Button
                            size="large"
                            svgIcon={CalendarCheck}
                            themeColor="primary"
                            onClick={onOpenGoogleUrl}>
                            Google kalender
                        </Button>
                    </div>
                </div>
                <Spacer height={15} />
                <div className="row">
                    <div className="col text-center">
                        <Button
                            size="large"
                            svgIcon={CalendarCheck}
                            themeColor="primary"
                            onClick={onOpenWebCalUrl}>
                            Annen kalender*
                        </Button>
                    </div>
                </div>
                <Spacer height={25} />
                <div className="row">
                    <div className="col">
                        * Annen kalender er Microsoft Outlook, Apple kalender og &oslash;vrige kalendere som bruker webcal som l&oslash;sning
                    </div>
                </div>
                <Spacer height={25} />
                <div className="row">
                    <div className="col text-end">
                        <Button
                            onClick={() => setTabState(TabStates.Advanced)}>
                            Avansert
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const renderTabStateAdvanced = () => {
        return (
            <div className="container">
                <Spacer height={20} />
                <div className="row">
                    <div className="col">
                        <TextAreaFieldWrapper
                            title="Avansert"
                            rows={3}
                            value={calendarUrl}
                        />
                    </div>
                </div>
                <Spacer height={25} />
                <div className="row">
                    <div className="col text-center">
                        <CopyToClipboard text={calendarUrl} onCopy={onCopy}>
                            <Button
                                size="large"
                                themeColor="primary"
                                svgIcon={CopyIcon}>
                                Kopier linken
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
                <Spacer height={45} />
                <div className="row">
                    <div className="col">
                        <Button
                            onClick={() => setIsResetting(true)}>
                            Nullstill
                        </Button>
                    </div>
                    <div className="col text-end">
                        <Button
                            onClick={() => setTabState(TabStates.Default)}>
                            Abonnere automatisk
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {(() => {
                switch (tabState) {
                    case TabStates.Default:
                        return renderTabStateDefault();
                    case TabStates.Advanced:
                        return renderTabStateAdvanced();
                    default:
                        return null;
                }
            })()}

            {
                isResetting &&
                <DialogConfirm properties={{
                        ...DialogProperties,
                        title: "Nullstill link",
                        closeText: "Avbryt",
                        clickText: "Nullstill",
                        onClose: () => {
                            setIsResetting(false);
                        },
                        onClick: handleOnReset
                    } }>
                        {parse("Ved &aring; nullstille linken, kanselleres alle abonnementer hvor linken er benyttet.") }
                    </DialogConfirm>
            }

        </>
    );

    function onCopy() {
        publishSuccessNotificationTopic("Linken er kopiert til utklippstavlen");
    }

    function handleOnReset() {
        setIsResetting(false);
        onReset();
    }
}
