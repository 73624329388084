import React, { useState } from 'react';

import { validate as emailValidator } from 'react-email-validator';
import { useEffectOnce } from '../../components/useEffectOnce';
import Spacer from '../../components/layout/Spacer';
import MyPageApiRepository from '../../repositories/api/MyPageApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import { Card } from '../../components/layout/card/Card';
import { UploadIcon } from '../../components/layout/icons/Icons';
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import SettingsBottomMenu from './components/SettingsBottomMenu';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import TextFieldWrapper from '../../components/layout/fieldWrappers/TextFieldWrapper';
import TextBoxFieldWrapper from '../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import { copyObject } from '../../components/helpers/ObjectHelpers';

function PersonalData() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [personData, setPersonData] = useState({ details: {} });
    const [contactData, setContactData] = useState({ });
    const [canSave, setCanSave] = useState(false);

    // Functions for processing data
    const initializeAsync = async () => {

        const response = await MyPageApiRepository.getDetailsAsync();

        if (response.ok === true) {
            const data = await response.json();
            setPersonData(data);

            updateContactData({
                mobile: data.mobileContactValue.contactValue,
                email: data.emailContactValue.contactValue
            });
        } else {
            handleError(response.status);
        }

        setIsBusy(false);
    }

    const saveAsync = async () => {
        setIsBusy(true);

        personData.mobileContactValue.contactValue = contactData.mobile;
        personData.emailContactValue.contactValue = contactData.email;

        const response = await MyPageApiRepository.setContactValues(personData);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Persondata ble lagret.`);
            initializeAsync();
        } else {
            handleError(response.status);
        }
        setIsBusy(false);
    }

    // Initialize function
    useEffectOnce(() => {
        publishHeaderTopic("Innstillinger", "Persondata");
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <ContentGrid title="Persondata" titleIcon="bulletList" fullSize={false}>
                <Card>
                    <Spacer height={20} />
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <TextFieldWrapper
                                    boldTitle={true}
                                    title="Navn"
                                    text={personData.details.fullName}
                                />
                            </div>
                        </div>
                        <Spacer height={20} />
                        <div className="row">
                            <div className="col">
                                <TextFieldWrapper
                                    boldTitle={true}
                                    title="Adresse"
                                    text={personData.details.address}
                                />
                            </div>
                        </div>
                        <Spacer height={20} />
                        <div className="row">
                            <div className="col">
                                <TextBoxFieldWrapper
                                    boldTitle={true}
                                    title="Mobil"
                                    value={contactData.mobile}
                                    prefix="+47"
                                    onChange={onChange}
                                    disabled={isBusy}
                                />
                            </div>
                        </div>
                        <Spacer height={20} />
                        <div className="row">
                            <div className="col">
                                <TextFieldWrapper
                                    boldTitle={true}
                                    title="E-post"
                                    text={contactData.email}
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </ContentGrid>
            <FloatingButtonContainer hasBottomNavigation={true}>
                <FloatingActionButton
                    themeColor={canSave ? "primary" : "secondary"}
                    disabled={!canSave}
                    svgIcon={UploadIcon}
                    onClick={onSaveClickAsync}
                />
            </FloatingButtonContainer>
            <SettingsBottomMenu />

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    function updateContactData(newContactData) {
        setContactData(contactData => ({
            ...contactData,
            ...newContactData
        }));
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }

    async function onSaveClickAsync() {
        await saveAsync();
    }

    function onChange(e) {
        const val = e.value;
        
        if (isNaN(val) || val.length > 8) return;

        const newContactData = copyObject(contactData);
        newContactData.mobile = val;
        
        updateContactData(newContactData);
        validate(newContactData);
    }

    function validate(newContactData) {
        try {
            let validated = true;
            
            if (newContactData.mobile.length !== 8 || newContactData.email.length === 0) validated = false;

            if (emailValidator(newContactData.email) === false) validated = false;

            if (Number(newContactData.mobile) === Number(personData.mobileContactValue.contactValue) &&
                newContactData.email === personData.emailContactValue.contactValue) validated = false;
            
            setCanSave(validated);
        } catch (e) {
            setCanSave(false);
        }
    }

    function handleError(response) {
        publishWarningNotificationTopic("En feil oppstod ved behandling av persondata.", response.status);
        setHasErrors(true);
    }
}

export default PersonalData;