import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import { isNumeric } from "../../components/helpers/ObjectHelpers";
import BaseApiRepository from "./BaseApiRepository";
const PilotApiRepository = {


    async getPilotageByIdAsync(id) {
        return await BaseApiRepository.getAsync(`pilot/pilotage/${id}`);
    },

    async searchCurrentPilotagesAsync(query) {
        return await BaseApiRepository.postAsync('pilot/pilotages/current', query);
    },

    async getAssignedPilotagesAsync() {
        return await BaseApiRepository.getAsync('pilot/pilotages/assigned');
    },

    async getExecutedPilotagesAsync(toTime, batchSize = 0) {
        if (!isNumeric(batchSize)) { batchSize = 0; }
        return await BaseApiRepository.getAsync(`pilot/pilotages/executed?toTime=${toTime.format('YYYY-MM-DDTHH:mm:ss')}&batchSize=${batchSize}`);
    },
    async getPilotagePilotAsync(pilotageId) {
        return await BaseApiRepository.getAsync(`pilot/pilotage-pilot/${pilotageId}`)
    },

    async getWorkTimeActualDetailsSummaryAsync(id) {
        return await BaseApiRepository.getAsync(`pilot/work-time/actual/details/summary/${id}`);
    },

    async getWorkTimeActualAsync(fromTime, toTime) {
        return await BaseApiRepository.getAsync(`pilot/work-time/actual?fromTime=${fromTime.format('YYYY-MM-DDTHH:mm:00')}&toTime=${toTime.format('YYYY-MM-DDTHH:mm:00') }`);
    },

    async getReadOnlyPilotageByIdAsync(pilotageId) {
        return await BaseApiRepository.getAsync(`pilot/pilotage/${pilotageId}/read-only`);
    },

    async getPilotDetailsAsync(fromTime, toTime) {
        return await BaseApiRepository.getAsync(`pilot/details?fromTime=${fromTime}&toTime=${toTime}`);
    },

    async getDayTimeAsync() {
        return await BaseApiRepository.getAsync(`pilot/day-time`);
    },

    async getLocationsAsync() {
        return await BaseApiRepository.getAsync("pilot/locations");
    },

    async getAppointmentAsync() {
        return await BaseApiRepository.getAsync(`pilot/appointments`)
    },

    async getAppointmentByIdAsync(appointmentId) {
        return await BaseApiRepository.getAsync(`pilot/appointment/${appointmentId}`)
    },

    async getAppointmentTypesAsync() {
        return await BaseApiRepository.getAsync(`pilot/appointment-types`)
    },

    async savePilotAppointmentAsync(obj) {
        return await BaseApiRepository.postAsync('pilot/appointment', obj)
    },

    async updateAppointmentAsync(obj) {
        return await BaseApiRepository.putAsync('pilot/appointment', obj)
    },

    async deleteAppointmentAsync(appointmentToDelete) {
        return await BaseApiRepository.deleteAsync('pilot/appointment', appointmentToDelete)
    },

    async searchAppointmentAsync(query) {
        return await BaseApiRepository.postAsync('pilot/appointments', query)
    },

    async getHistory(fromTime, toTime) {
        return await BaseApiRepository.getAsync(`pilot/work-time/history?fromTime=${fromTime}&toTime=${toTime}`)
    },

    async savePilotageReceiptAsync(obj) {
        return await BaseApiRepository.putAsync(`pilot/pilotage/${obj.pilotage.pilotageId}/receipt`, obj.commands)
    },

    async registerPilotPosition(longitude, latitude) {
        return await BaseApiRepository.postAsync(`pilot/register-position?longitude=${longitude}&latitude=${latitude}`)
    },

    async getPushSubscriptionTypesAsync() {
        return await BaseApiRepository.getAsync(`pilot/push-subscription-categories`)
    },

    async registerDeviceAsync() {
        return await BaseApiRepository.postAsync(`pilot/devices/register`)
    },

    async getDeviceByIdAsync(id) {
        return await BaseApiRepository.getAsync(`pilot/devices/${id}`)
    },

    async getCurrentDeviceAsync() {
        return await BaseApiRepository.getAsync(`pilot/devices/current`)
    },

    async getPushSubscriptionByIdAsync(id) {
        return await BaseApiRepository.getAsync(`pilot/devices/push/subscriptions/${id}`)
    },

    async getPushSubscriptionsAsync() {
        return await BaseApiRepository.getAsync(`pilot/devices/push/subscriptions`)
    },

    async addPushSubscriptionsAsync(type) {
        return await BaseApiRepository.putAsync(`pilot/devices/push/subscriptions/include?type=${type}`)
    },

    async removePushSubscriptionsAsync(type) {
        return await BaseApiRepository.putAsync(`pilot/devices/push/subscriptions/exclude?type=${type}`)
    },

    async refreshPushSubscriptionsAsync(id) {
        return await BaseApiRepository.putAsync(`pilot/devices/${id}/refresh`)
    },

    async savePushTokensAsync(token) {
        return await BaseApiRepository.putAsync(`pilot/devices/push/subscriptions/token?token=${token}`)
    },

    async deleteAllPushSubscriptionsAsync() {
        return await BaseApiRepository.deleteAsync(`pilot/devices/push`)
    },

    async getNjordPortalFairwayAreaUrlAsync(pilotageId, displayPersonId, pilotagePecExamId) {
        return await BaseApiRepository.getAsync(`pilot/njord-portal-url/${pilotageId}/${displayPersonId}/${pilotagePecExamId}`);
    },

    async updatePersonMobileAppAsync(obj) {
        return await BaseApiRepository.putAsync(``, obj);
    },

    async getPilotDataValidity() {
        return await BaseApiRepository.getAsync(`pilot/validity`);
    },
};

wrapMethodsWithLogging(PilotApiRepository);

export default PilotApiRepository;