import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const SourceApiRepository = {

    async getWhoAmIAsync() {
        return await BaseApiRepository.getAsync(`source/who-am-i`);
    },

    async getPositionAsync(obj) {
        return await BaseApiRepository.postAsync('source/position/location-name', { longitude: obj.lng, latitude: obj.lat });
    },

    async getPilotStationsAsync() {
        return await BaseApiRepository.getAsync("source/pilot-stations");
    },

    async getConfigAsync() {
        return await BaseApiRepository.getAsync('source/config');
    },

    async getPilotagePilotTypesAsync() {
        return await BaseApiRepository.getAsync('source/pilotage-pilot/types');
    },

    async getShipPositionsAsync(shipIds) {
        return await BaseApiRepository.postAsync('source/ship-position', shipIds)
    },

    async getShipPositionsWithinAreaAsync(latitude, longitude, width, height) {
        return await BaseApiRepository.getAsync(`source/ship-position/area?latitude=${latitude}&longitude=${longitude}&width=${width}&height=${height}`)
    }

};
wrapMethodsWithLogging(SourceApiRepository);

export default SourceApiRepository;