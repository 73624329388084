import AuthApiRepository from "../../repositories/api/AuthApiRepository";
import PilotApiRepository from "../../repositories/api/PilotApiRepository";
import SourceApiRepository from "../../repositories/api/SourceApiRepository";
import MetaIdbRepository from "../../repositories/idb/MetaIdbRepository";
import { LoginTypes } from "../../services/SystemNames";
import { isNullOrEmpty } from "./ObjectHelpers";
import { publishLogoutTopic } from "./PubSubHelpers";
import { getDecodedToken, getToken, getTokenFromUrl, hasTokenExpired, setToken, tokenExists } from "./TokenHelpers";

function isLoggedIn() {
    try {
        let token = getToken();
        if (isNullOrEmpty(token) === true || getDecodedToken(token) === null) {
            token = getTokenFromUrl();
            setToken(token);
        }
        const hasExpired = hasTokenExpired(token);
        return hasExpired === false;
    } catch (e) {
        return false;
    }
}

function isLoggedInPerson() {
    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return false;
        }

        const decodedToken = getDecodedToken(token);
        return decodedToken.loginType === LoginTypes.Person;
    } catch (e) {
        return false;
    }
}

function isBetaUser() {
    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return false;
        }

        const decodedToken = getDecodedToken(token);
        const betaAccessType = decodedToken.betaAccessType;
        return betaAccessType.toLowerCase() === "true" || false;

    } catch (e) {
        return false;
    }
}

function getLoggedInPersonId() {
    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return 0;
        }

        const decodedToken = getDecodedToken(token);
        return Number(decodedToken.sub);
    } catch (e) {
        return 0;
    }
}

function loggedInId() {
    try {
        let token = getToken();

        if (tokenExists(token) === false) {
            return 0;
        }

        const decodedToken = getDecodedToken(token);
        return decodedToken.sub;
    } catch (e) {
        return 0;
    }
}

function canLogin() {
    // sjekke om tokenet har application right NJORD_PILOT_WEB_LOGIN
    try {
        const decodedToken = getDecodedToken(getToken());
        const hasApplicationRight = decodedToken.role.indexOf('NJORD_PILOT_WEB_LOGIN') > -1;

        return hasApplicationRight;
    } catch (e) {
        return false;
    }
}

async function handleLoginAsync(returnUrl = "") {
    if (!navigator.onLine) {
        this.history.push("/");
    }

    const response = await AuthApiRepository.getLoginUrlAsync(returnUrl);
    const data = await response.json();

    window.location.href = data.loginUrl;
}

async function handleLogoutAsync(navigate) {

    await MetaIdbRepository.deleteWhoAmIAsync();

    publishLogoutTopic();
    navigate("/");
}

async function hasRightToLoginAsync() {
    return await hasApplicationRightsAsync(['NJORD_PILOT_WEB_LOGIN']);
}

function hasPersonType(personType) {

    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return false;
        }

        const decodedToken = getDecodedToken(token);
        return decodedToken.personType.toUpperCase() === personType.toUpperCase();
    } catch (e) {
        return false;
    }
}

async function hasApplicationRightsAsync(applicationRights) {
    if (navigator.onLine === false) return false;
    if (!isLoggedIn()) return false;
    
    const response = await AuthApiRepository.validateApplicationRightsAsync(applicationRights);
    if (response.ok === true) {
        const data = await response.json();
        return data === true;
    } else {
        return false;
    }
}

async function setWhoAmIAsync() {
    if (!navigator.onLine) return;

    const response = await SourceApiRepository.getWhoAmIAsync();
    if (response.ok === true) {
        const data = await response.json();
        await MetaIdbRepository.setWhoAmIAsync(data);
    }

    const dayTimeResponse = await PilotApiRepository.getDayTimeAsync();
    if (dayTimeResponse.ok === true) {
        const data = await dayTimeResponse.json();
        await MetaIdbRepository.setDayTimeAsync(data);
    }
}

export {
    isLoggedIn,
    isLoggedInPerson,
    isBetaUser,
    getLoggedInPersonId,
    loggedInId,
    canLogin,
    handleLoginAsync,
    handleLogoutAsync,
    hasRightToLoginAsync,
    hasPersonType,
    hasApplicationRightsAsync,
    setWhoAmIAsync
}
