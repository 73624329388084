import React from 'react';
import ActionSheet from '../../ActionSheet';

export const CardActionSheet = (
    {
        properties = {},
        isActionSheetOpen = false,
        setIsActionSheetOpen
    }) => {
        

    return (
        (properties.canUseActionSheet()) &&
        <ActionSheet
            expand={isActionSheetOpen}
            animation={true}
            onOverlayClick={() => setIsActionSheetOpen(false)}
            onItemClick={ontemClick}
            items={properties.sheetActions} />
    );

    function ontemClick(item) {
        setIsActionSheetOpen(false);

        item.onClick();
    }
};