import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ValidateAccess from '../access/ValidateAccess';
import NoAccess from '../access/NoAccess';
import Offline from '../access/Offline';
import { isBoolean } from '../helpers/ObjectHelpers';
import { getToken, initializeTokenAsync } from '../helpers/TokenHelpers';
import { hasPersonType, isBetaUser, isLoggedIn } from '../helpers/AuthHelpers';
import { RouteGuardStates } from '../../services/SystemNames';
import { RestoreDatabase } from '../access/RestoreDatabase';
import BaseIdbRepository from '../../repositories/idb/BaseIdbRepository';
import Login from '../access/Login';

export const RouteGuardPersonType = ({ personType: PersonType, onlineOnly: OnlineOnly, beta: Beta }) => {

    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    useEffect(() => {

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);
        
        initializeAsync();

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function initializeAsync() {

        setGuardState(RouteGuardStates.Busy);

        await initializeTokenAsync();

        const isDatabaseValid = await BaseIdbRepository.isDatabaseValidAsync();
        if (isDatabaseValid) {

            let canContinue = true;

            if (isBoolean(Beta)) {
                if (Beta) {
                    canContinue = isBetaUser();
                }
            }

            if (canContinue) {
                if (OnlineOnly && !navigator.onLine) {
                    setGuardState(RouteGuardStates.Offline);
                } else {
                    if (isLoggedIn() === true) {
                        const token = getToken();
                        let flag = token !== undefined;

                        if (flag === true) {
                            setGuardState(hasPersonType(PersonType) ? RouteGuardStates.Valid : RouteGuardStates.NoAccess);
                        } else {
                            setGuardState(RouteGuardStates.NoAccess);
                        }
                    } else {
                        setGuardState(RouteGuardStates.Login);
                    }
                }
            } else {
                setGuardState(RouteGuardStates.NoAccess);
            }
        } else {
            setGuardState(RouteGuardStates.RestoreDatabase);
        }
    }

    function handleOffline() {
        if (!OnlineOnly) return;
        setGuardState(RouteGuardStates.Offline);
    }

    function handleOnline() {
        initializeAsync();
    }

    return (
        (() => {
            switch (guardState) {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.Offline:
                    return (<Offline />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.RestoreDatabase:
                    return (<RestoreDatabase />);
                case RouteGuardStates.Login:
                    return (<Login />);

                default:
                    return (<ValidateAccess />);
            }
        })()
    )

};
