import React, { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutesLoggedInPerson, AppRoutesPersonType, AppRoutesApplicationRights, AppRoutesDefault, AppRoutesLoggedIn } from './AppRoutes';
import Layout from './components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import localization from 'moment/locale/nb';
import moment from 'moment';
import PubSub from 'pubsub-js';
import RouteGuardLoggedInPerson from "./components/routes/RouteGuardLoggedInPerson"
import BaseIdbRepository from './repositories/idb/BaseIdbRepository';
import { RouteGuardPersonType } from './components/routes/RouteGuardPersonType';
import { RouteGuardApplicationRights } from './components/routes/RouteGuardApplicationRights';
import NotFound from './components/access/NotFound';
import RouteGuardLoggedIn from './components/routes/RouteGuardLoggedIn';
import { RouteGuardDefault } from './components/routes/RouteGuardDefault';
import {AppInsightsService} from './services/AppInsightsService';
import PilotAssignmentIdbRepository from './repositories/idb/PilotAssignmentIdbRepository';
import { isNullOrEmpty } from './components/helpers/ObjectHelpers';
import { PubSubTopics } from './components/helpers/PubSubHelpers';
import { getToken } from './components/helpers/TokenHelpers';
import { getLoggedInPersonId, isLoggedIn } from './components/helpers/AuthHelpers';
import { initCacheAsync } from './components/helpers/CacheHelpers';
import SourceApiRepository from './repositories/api/SourceApiRepository';

moment.updateLocale('nb', localization);

BaseIdbRepository.init();

export function App() {

    const navigate = useNavigate();
    const connectionString= useRef("");

    // Initialize application insights
    useEffect(() => {
        initializeApplicationInsights();

    }, [connectionString]);

    // Initialize function
    useEffect(() => {
        PubSub.subscribe(PubSubTopics.Logout, handlePubSubTopic);

        const isLoggedInTimer = setInterval(() => onIntervalAsync(), 300000); // Validate login every 5 minutes

        initCacheAsync();

        // todo: remove code after 7.0 release
        resetDataAsync();

        return () => {
            clearInterval(isLoggedInTimer);
            PubSub.unsubscribe(PubSubTopics.Logout);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    useEffect(() => {
        let theme = localStorage.getItem("theme");
        if (!isNullOrEmpty(theme)) {
            document.documentElement.classList = theme;
            return;
        }
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.classList = "theme-dark";
            localStorage.setItem("theme", "theme-dark");
        } else {
            document.documentElement.classList = "theme-light";
        } 
    }, []);

    return (
        <Layout>

            <Routes>

                {AppRoutesDefault.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={<RouteGuardDefault beta={route.beta} />}>
                        <Route exact {...rest} element={element} />
                    </Route>
                })}

                {AppRoutesLoggedInPerson.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={<RouteGuardLoggedInPerson beta={route.beta} />}>
                        <Route {...rest} element={element}/>
                    </Route>
                })}

                {AppRoutesLoggedIn.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={<RouteGuardLoggedIn beta={route.beta} />}>
                        <Route {...rest} element={element} />
                    </Route>
                })}

                {AppRoutesPersonType.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={<RouteGuardPersonType personType={route.type} onlineOnly={route.onlineOnly} beta={route.beta} />}>
                        <Route {...rest} element={element} />
                    </Route>
                })}

                {AppRoutesApplicationRights.map((route, index) => {
                    const { element, ...rest } = route;

                    return <Route key={index} {...rest} element={<RouteGuardApplicationRights applicationRights={route.applicationRights} onlineOnly={route.onlineOnly} beta={route.beta} />}>
                        <Route {...rest} element={element} />
                    </Route>
                })}
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Layout>
    );
    
    async function initializeApplicationInsights () {
        connectionString.current = await AppInsightsService.getConfig(SourceApiRepository);
        await AppInsightsService.initAsync(connectionString.current, getLoggedInPersonId);           
      }

    async function onIntervalAsync() {

        await initCacheAsync();

        const token = getToken();
        if (isNullOrEmpty(token)) return;

        const loggedIn = isLoggedIn();
        if (loggedIn) return;

        window.location.reload();
    }

    // todo: remove code after 7.0 release
    async function resetDataAsync() {
        const propertyName = "DataReset";
        const item = localStorage.getItem(propertyName);
        if (isNullOrEmpty(item)) {
            await PilotAssignmentIdbRepository.deleteAllAsync();
        }
        localStorage.setItem(propertyName, 'true');
    }

    function handlePubSubTopic() {
        navigate("/");
    }
}

export default App;
