import React, { useEffect, useState } from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import parse from 'html-react-parser';
import { ApplicationRights } from '../../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import { isArrayEmpty } from '../../../components/helpers/ObjectHelpers';
import { hasApplicationRightsAsync } from '../../../components/helpers/AuthHelpers';

export function PcsBottomMenu({ callback, index = 1 }) {

    const navigate = useNavigate();

    const [items, setItems] = useState([]);

    useEffect(() => {
        async function initializeAsync() {
            const result = [];

            let hasApplicationRight = await hasApplicationRightsAsync([ApplicationRights.EditPilotCoastalSegments]);
            if (hasApplicationRight) {
                result.push({
                    text: parse("Sertifikatomr&#229;der"),
                    icon: "mapSegments",
                    action: 1
                });
            }

            hasApplicationRight = await hasApplicationRightsAsync([ApplicationRights.EditPilotCoastalSegmentsEvaluation]);
            if (hasApplicationRight) {
                result.push({
                    text: "Evalueringer",
                    icon: "evaluation",
                    action: 2
                });
            }

            hasApplicationRight = await hasApplicationRightsAsync([ApplicationRights.EditPilotCoastalSegmentsLocations]);
            if (hasApplicationRight) {
                result.push({
                    text: "Lokasjoner",
                    icon: "pin",
                    action: 3
                });
            }

            setItems(result);

            callback(!isArrayEmpty(result));
        }

        initializeAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        (!isArrayEmpty(items)) &&
            <BottomNavigation
            items={items}
            selectedAction={index}
            onCallback={onBottomMenuCallback }
            />
    );

    function onBottomMenuCallback(e) {
        let url = "";
        switch (e.action) {
            case 2:
                url = 'evaluations';
                break;
            case 3:
                url = 'locations';
                break;
            default:
                url = 'groups';
                break;
        }
        navigate(`/pcs/${url}`)
    }
}