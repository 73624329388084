import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconColors } from "../../../../../services/SystemNames"
import { getElementById, getElementsByClassName } from "../../../../helpers/ElementHelpers";
import { isArrayEmpty, isBoolean, isObjectNull } from "../../../../helpers/ObjectHelpers";
import { CornersOut, CornersIn } from "phosphor-react-sc";
import Icons from "../../../icons/Icons"
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { isMobile } from "../../../../helpers/DeviceHelpers";
import { hideMapControl, markersVisibleAtZoom, showMapControl } from "../../GoogleMapHelpers";


export const MapControlsTop = forwardRef(({
    mapId,
    properties,
}, ref) => {

    useImperativeHandle(ref, () => ({
        onZoomChanged() {
            const zoom = map.getZoom();
            if (zoom >= markersVisibleAtZoom) {
                hideMapControl(infoElemId);
            } else {
                showMapControl(infoElemId);
            }
        },
        onLocationActiveChanged(isLocationsActive) {
            if (properties.pilotageId > 0 || properties.shipId > 0) return;
            if (!isBoolean(isLocationsActive)) return;

            if (isLocationsActive) {
                showMapControl(infoElemId);
            } else {
                hideMapControl(infoElemId);
            }
        }
    }));

    const map = useMap();

    const [isAdded, setIsAdded] = useState(false);
    const [isFullScreen, setIsFulllScreen] = useState(false);

    const settingsElemId = `googleMapControlSettings-${mapId}`;
    const settingsContentId = `googleMapControlSettingsContent-${mapId}`;
    const fullScreenElemId = `googleMapControlFullScreen-${mapId}`;
    const infoElemId = `googleMapControlMarkerInformation-${mapId}`;

    useEffect(() => {

        if (!properties.showSettings) return;
        if (!map || isAdded) return;

        const settingsElem = getElementById(settingsElemId);
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(settingsElem);

        const infoElem = getElementById(infoElemId);
        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(infoElem);

        const fullScreenElem = getElementById(fullScreenElemId);
        if (!isMobile()) {
            map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(fullScreenElem);
        } else {
            fullScreenElem.classList.add("display-none");
        }

        setIsAdded(true);

        if (properties.pilotageId === 0 && properties.shipId === 0) return;

        const zoom = map.getZoom();
        if (zoom > markersVisibleAtZoom) return;

        showMapControl(infoElemId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties]);

    return (
        <>
            <div id={settingsElemId} className="google-map-control google-map-control-button google-map-settings-gear" onClick={onShowSettings}>
                <Icons iconName="settings" dimensions={30} color={IconColors.Primary} />
            </div>

            <div id={infoElemId} className="google-map-control google-map-control-zoom-info">
                <div className="text-truncate">Zoom inn for &aring; se elementer i kartet</div>
            </div>

            <div id={fullScreenElemId} onClick={onToggleFullScreen} className="google-map-control-button google-map-control-fullscreen google-map-control">
                {
                    isFullScreen ?
                        <CornersIn size={30} color={IconColors.Primary} />
                        :
                        <CornersOut size={30} color={IconColors.Primary} />
                }
            </div>
        </>
    )

    function onShowSettings() {
        const elem = getElementById(settingsContentId);
        if (isObjectNull(elem)) return;
        elem.classList.add("google-map-settings-active");
    }

    function onToggleFullScreen() {
        const elems = getElementsByClassName("gm-fullscreen-control");
        if (isArrayEmpty(elems)) return;
        elems[0].click();
        setIsFulllScreen(!isFullScreen);
    }
})