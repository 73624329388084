import { Card } from "../../../../components/layout/card/Card";
import { CardProperties } from "../../../../components/layout/card/components/CardProperties";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Spacer from "../../../../components/layout/Spacer";
import Slide from "../../../../components/layout/Slide";
import PilotAssignmentIdbRepository from "../../../../repositories/idb/PilotAssignmentIdbRepository";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import Switch from "../../../../components/layout/switch";
import { addConvertToPilotageInclCommand, hasCommandType } from "../helpers/PilotAssignmentCommandHelpers";
import {
    isPilotAssignmentEditable, isPilotAssignmentTrainee
} from "../helpers/PilotAssignmentHelpers";
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const ConvertToPilotInclCard = forwardRef((
    {
        pilotAssignmentId,
        onExaminerOnlyChanged,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }
    }));

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);
    // husk å disable hvis losoppdraget er completed!!

    return (
        <Slide show={show}>
            <Spacer height={30} />
            <Card properties={{
                ...CardProperties,
                title: "Var du b&aring;de sensor og los på oppdraget?",
                backgroundColor: "var(--card-question-bg)"
            }}>
                <p>
                    Dersom du ogs&aring; var ansvarlig los (farledsbevisinnehaver forlot brua),
                    gj&oslash;res oppdraget om til et losoppdrag slik at losingstimene kan faktureres.
                </p>
                <Switch
                    onLabel="Ja"
                    offLabel="Nei"
                    onChange={onChange}
                    checked={isChecked}
                    disabled={!isEditable}
                />
            </Card>
        </Slide>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        const hasCommand = hasCommandType(pilotAssignment, PilotAssignmentCommands.ConvertToPilotageIncl);

        setShow(
            pilotAssignment.isExaminer &&
            pilotAssignment.pilotage.pilotagePilotsInformation.length === 1 &&
            pilotAssignment.pilotage.canConvertToPilotIncl
        );
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
        setIsChecked(hasCommand);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    function onChange(e) {
        setIsChecked(e.value);
        onUpdateAsync(e.value);
    }

    async function onUpdateAsync(canConvertToPilotIncl) {
        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        pilotAssignment = addConvertToPilotageInclCommand(pilotAssignment, canConvertToPilotIncl);

        await onUpdatePilotAssignmentAsync(pilotAssignment);

        onExaminerOnlyChanged();
    }
})
