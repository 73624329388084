import "./styles/pcs.css"

import React, { useEffect, useRef, useState } from 'react';
import { GroupListCards } from './components/GroupListCards';
import { EvaluationCard } from './components/EvaluationCard';
import PcsApiRepository from '../../repositories/api/PcsApiRepository';
import Overlay from '../../components/layout/overlay/Overlay';
import { GoogleMapCard } from '../../components/layout/map/GoogleMapCard';
import { GoogleMapProperties } from '../../components/layout/map/GoogleMapProperties';
import { PcsNoEdit } from "./components/PcsNoEdit";
import { PcsBottomMenu } from "./components/PcsBottomMenu";
import { isObjectNull } from "../../components/helpers/ObjectHelpers";
import { publishHeaderTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import Spacer from "../../components/layout/Spacer";
import TitleContainer from "../../components/layout/TitleContainer";

function Evaluations() {

    const [isBusy, setIsBusy] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [evaluation, setEvaluation] = useState(null);
    const [zoom, setZoom] = useState(8);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [mapChanges, setMapChanges] = useState(null);
    const [hasBottomNavigation, setHasBottomNavigation] = useState(false);
    
    const mapRef = useRef();
    const evaluationRef = useRef();
    const listRef = useRef();

    useEffect(() => {
        publishHeaderTopic("Sertifikatomr&aring;der", "Evaluering");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={`pcs-container ${hasBottomNavigation ? "pcs-container-mnu" : "pcs-container-no-mnu"}`}>

                <div>
                    <div>
                        <Spacer height={10} />
                        <div className="container">
                            <div className="row">
                                <TitleContainer
                                    title={getTitle()}
                                    icon={getTitleIcon()}
                                    iconDimensions={32}
                                    actions={getTitleActions()}
                                />
                            </div>
                        </div>
                        <Spacer height={10} />
                    </div>
                    {
                        (isObjectNull(evaluation)) ?
                            <GroupListCards
                                ref={listRef}
                                onClick={onGroupClickAsync}
                                onMouseOver={onGroupListMouseOver}
                                onMouseOut={onGroupListMouseOut}
                                mapChanges={mapChanges}
                            />
                            :
                            <EvaluationCard
                                ref={evaluationRef}
                                setIsBusy={setIsBusy}
                                evaluation={evaluation}
                                handleError={handleError}
                                onCancelClick={onCancelEvaluationClick}
                                onSaved={onEvaluationSaved }
                            />
                    }
                </div>

                <GoogleMapCard
                    ref={mapRef}
                    properties={{
                        ...GoogleMapProperties,
                        zoom: zoom,
                        center: center,
                        showPcsGroups: true,
                        showLocations: true,
                        onDragEnd: onMapChange,
                        onZoomChanged: onMapChange,
                        onTilesLoaded: onMapChange,
                        onResize: onMapChange,
                        onGeoJsonLoaded: onMapChange,
                        onFeatureClick: onMapFeatureClick,
                        onFeatureMouseOver: onMapFeatureMouseOver,
                        onFeatureMouseOut: onMapFeatureMouseOut
                    }}
                />
                <PcsNoEdit/>
            </div>

            <PcsBottomMenu callback={onBottomMenuCallback} index={2} />

            <Overlay
                isBusy={isBusy}
                onReloadClick={onReloadClick}
                hasErrors={hasErrors} />
        </>
    );

    function handleError(response) {
        publishWarningNotificationTopic("En feil oppstod ved behandling av sertifikatomr&aring;dets evaluering.", response.status);
        setHasErrors(true);
    }

    function onReloadClick() {
        setHasErrors(false);
        setIsBusy(true);
    }

    async function onGroupClickAsync(groupId) {

        setIsBusy(true);

        const groupResponse = await PcsApiRepository.getGroupAsync(groupId);
        const evaluationResponse = await PcsApiRepository.getGroupEvaluationAsync(groupId);

        if (evaluationResponse.ok === true && groupResponse.ok === true) {
            const evaluationData = await evaluationResponse.json();
            const groupData = await groupResponse.json();

            evaluationData.groupName = groupData.groupName;
            mapRef.current.onUpdatePcsTitle(groupData.groupName);
            
            setEvaluation(evaluationData);
            setZoom(11);
            setCenter({ lat: groupData.latitude, lng: groupData.longitude });

            selectFeatureInMap(groupId);

        } else {
            handleError(evaluationResponse);
        }

        setIsBusy(false);
    }

    function onCancelEvaluationClick() {
        setEvaluation(null);
        selectFeatureInMap(0);

        if (!isObjectNull(listRef.current)) {
            listRef.current.onGroupMouseOut();
        }
        mapRef.current.onToggleSelected();
        mapRef.current.onUpdatePcsTitle("");
    }

    function onMapChange(e) {
        setMapChanges(e);
    }

    function onMapFeatureClick(e) {
        onGroupClickAsync(e);
    }

    function selectFeatureInMap(e) {
        mapRef.current.onFeatureSelected({
            property: "segmentGroupId",
            value: e
        });
    }

    function onGroupListMouseOver(groupId) {
        mapRef.current.onFeatureMouseOver({
            property: "segmentGroupId",
            value: groupId
        });
    }

    function onGroupListMouseOut() {
        mapRef.current.onFeatureMouseOut();
    }

    function onMapFeatureMouseOver(e) {
        if (isObjectNull(listRef.current)) return;
        listRef.current.onGroupMouseOver(e);
    }

    function onMapFeatureMouseOut() {
        if (isObjectNull(listRef.current)) return;
        listRef.current.onGroupMouseOut();
    }

    function onEvaluationSaved() {
        setEvaluation(null);
        setIsBusy(false);
        mapRef.current.onUpdatePcsTitle("");
    }

    function onBottomMenuCallback(e) {
        setHasBottomNavigation(e);
    }

    function getTitle() {
        if (isObjectNull(evaluation)) return "Evaluering av sertifikatomr&aring;der"
        return evaluation.groupName;
    }

    function getTitleIcon() {
        if (isObjectNull(evaluation)) return "bulletList";

        return "evaluation";
    }

    function getTitleActions() {
        
        if (isObjectNull(evaluation)) return [];

        if(evaluation.id === 0) return []

        return [
            {
                icon: "trash",
                onClick: () => evaluationRef.current.onDelete()
            }
        ];
    }
}

export default Evaluations;