import { useState } from "react";
import ContentGrid from "../layout/ContentGrid"
import Spacer from "../layout/Spacer"
import { Card } from "../layout/card/Card"
import { Button } from "@progress/kendo-react-buttons";
import Overlay from "../layout/overlay/Overlay";
import BaseIdbRepository from "../../repositories/idb/BaseIdbRepository";

export const RestoreDatabase = () => {

    const [isBusy, setIsBusy] = useState(false);

    return (
        <>
            <ContentGrid title="Uventet feil" titleIcon="warning">
                <Card>
                    <Spacer/>
                    <div>
                        <p>
                            En uventet feil har desværre oppstått.
                        </p>
                        <p>
                            Problemet kan bli løst med å gjenopprette dataene dine. 
                        </p>
                        <p>
                            Klikk på knappen for å forsøke og gjenopprette dine data. 
                        </p>
                        <p>
                            Vennligst ta kontakt med Administrator hvis problemet vedvarer.
                        </p>
                    </div>
                    <Spacer height={20} />
                    <div>
                        <center>
                            <Button themeColor="primary" onClick={onRestore}>
                                Gjenopprett data
                            </Button>
                        </center>
                    </div>
                </Card>
            </ContentGrid>
            <Overlay isBusy={isBusy}/>
        </>
    )

    async function onRestore() {
        setIsBusy(true);
        await BaseIdbRepository.deleteDatabaseAsync();
        window.location.href = "/";
    }
}
