/*eslint unicode-bom: ["error", "always"]*/

import React, { useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import { addAppContentFullSize, removeAppContentFullSize } from '../helpers/ElementHelpers';

function NotFound() {

    // Initialize function
    useEffect(() => {
        addAppContentFullSize();

        return () => {
            removeAppContentFullSize();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
         <div className="home">
            <div className="welcome">
                <div className="title">Siden finnes ikke!</div>
                <div className="description">
                    <p>
                        Du har navigert til en side som ikke finnes.
                    </p>
                    <p>
                        Klikk <NavLink to="/">her</NavLink>  for å gå navigere til forsiden.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NotFound;