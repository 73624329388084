import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const AuthApiRepository = {

    async validateApplicationRightsAsync(applicationRights) {
        return await BaseApiRepository.postAsync(`auth/validate/application-rights`, applicationRights);
    },
    
    async getLoginUrlAsync(returnUrl) {
        return await BaseApiRepository.getAsync(`auth/login-url?returnUrl=${returnUrl}`);
    },

    async getLogoutUrlAsync() {
        return await BaseApiRepository.getAsync('auth/logout-url');
    }

};
wrapMethodsWithLogging(AuthApiRepository);

export default AuthApiRepository;