import { isExpired } from "react-jwt";
import { decodeToken } from "react-jwt";
import Moment from 'moment';
import { cleanUrl } from "./UrlHelpers";
import { isNullOrEmpty } from "./ObjectHelpers";
import { publishLoggedInTopic } from "./PubSubHelpers";
import { hasRightToLoginAsync, setWhoAmIAsync } from "./AuthHelpers";
import { Tokens } from "../../services/SystemNames";


async function initializeAsync() {

    try {
        const token = getTokenFromUrl();
        const localToken = getToken();

        if (isNullOrEmpty(token) === false) {

            const hasExpired = isExpired(token);
            if (hasExpired === false) {
                if (token !== localToken) {

                    const canLogin = await hasRightToLoginAsync();

                    if (canLogin) {
                        setToken(token);
                        await setWhoAmIAsync();
                        publishLoggedInTopic();
                    }
                }
            }
        }
    } catch (e) {
        console.warn(e);
    }
}

function getTokenFromUrl() {
    const inputUrl = new URL(cleanUrl(window.location.href));
    const inputUrlSearchParams = inputUrl.searchParams;

    let token = "";

    inputUrlSearchParams.forEach((value, key) => {
        switch (key) {
            case "token":
                token = value;
                break;
            default:
                break;
        }
    });

    return token;
}

function getToken() {
    return localStorage.getItem(Tokens.Token);
}

function setToken(token) {
    localStorage.setItem(Tokens.Token, token);
}

function getDecodedToken(token) {
    try {
        if (isNullOrEmpty(token) === true) return null;
        return decodeToken(token);
    } catch {
        return null;
    }
}

function hasTokenExpired(token) {
    try {
        if (isNullOrEmpty(token) === true) return true;

        const expireDate = new Date(decodeToken(token).exp * 1000);
        return Moment() > Moment(expireDate);;
    } catch {
        return true;
    }
}

function getTokenExpireDate() {
    try {
        const token = getToken();
        if (isNullOrEmpty(token)) return "";

        const expireDate = new Date(decodeToken(token).exp * 1000);
        return Moment(expireDate).format("DD.MM HH:mm");
    } catch {
        return "";
    }
}

function tokenExists(token) {
    if (token === null || token === undefined || token.length === 0 || decodeToken(token) === null) {
        return false;
    }
    return true;
}

export {
    initializeAsync as initializeTokenAsync,
    getTokenFromUrl,
    getToken,
    setToken,
    getDecodedToken,
    hasTokenExpired,
    getTokenExpireDate,
    tokenExists
}
