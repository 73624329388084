import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import './styles/compensation.css';
import { useNavigate } from 'react-router-dom';
import CompensationApiRepository from '../../repositories/api/CompensationApiRepository';
import { Card } from '../../components/layout/card/Card';
import { CardProperties } from '../../components/layout/card/components/CardProperties';
import { ReceiptDialog } from '../pilot/pilot-assignment/components/dialogs/ReceiptDialog';
import { PilotOvertimeDialog } from './components/dialogs/PilotOvertimeDialog';
import { PilotHourCompensationDialog } from './components/dialogs/PilotHourCompensationDialog';
import parse from 'html-react-parser';
import { CompensationType } from '../../services/SystemNames';

export default function CompensationCard({ compensation, reload }) {

    const navigate = useNavigate();
    const dateFormat = 'DD.MM HH:mm';

    const [comp, setComp] = useState(null);
    const [showReceiptDialog, setShowReceiptDialog] = useState(false);
    const [showOvertimeDialog, setShowOvertimeDialog] = useState(false);
    const [showHourCompDialog, setShowHourCompDialog] = useState(false);

    useEffect(() => {
        initialize();
    }, [compensation]);

    return (
        <>
            <Card properties={{
                ...CardProperties,
                title: `${compensation.title}`,
                borderColor: compensation.isCorrectionRequired ? `var(--c-correction-required-bg)` : `var(--card-border)`,
                isBorderLeftFat: true,
                onClick: () => handleCardClick(),
                backgroundColor: `var(--tertiary-bg)`
            }}>
            {comp &&
                <div className="semi-bold">
                    <div>
                        {parse(`${Moment(compensation.fromTime).format(dateFormat)} &rarr; ${Moment(compensation.toTime).format(dateFormat)} `)}
                    </div>
                    <div>
                        {`${compensation.status}`}
                    </div>
                </div>
            }
            </Card>
            {showReceiptDialog &&
                <ReceiptDialog onClose={() => setShowReceiptDialog(false)} onGoBack={() => navigate(`/pilot/pilotage/${comp.pilotageId}`)} pilotageId={comp.pilotageId} showDescription={false} actionText={"Gå til oppdrag"} />
            }
            {showOvertimeDialog &&
                <PilotOvertimeDialog onClose={() => { setShowOvertimeDialog(false) }
                } overtime={comp} canEdit={compensation.isCorrectionRequired} reload={() => reload()} />
            }
            {showHourCompDialog &&
                <PilotHourCompensationDialog onClose={() => setShowHourCompDialog(false)} compensation={comp} canEdit={compensation.isCorrectionRequired} reload={() => reload()} />
            }
        </>
    )

    async function initialize() {
        await CompensationApiRepository.getCompensation(compensation)
            .then((response) => response.json())
            .then((result) => {
                setComp(result);
            })
            .catch((error) => { console.error(error); })
    }

    function handleCardClick() {
        switch (compensation.type) {
            case CompensationType.HourCompensation:
                setShowHourCompDialog(true);
                return;
            case CompensationType.Overtime:
                setShowOvertimeDialog(true);
                return;
            case CompensationType.PilotageHourCompensation:
            case CompensationType.VariableCompensation:
            case CompensationType.PilotageCompensation:
                if (compensation.isCorrectionRequired) {
                    navigate(`/pilot/compensation/pilotage/${compensation.id}`)
                } else {
                    setShowReceiptDialog(true);
                }
                break;
            default:
                break;
        }
    }
}
