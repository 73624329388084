
function isNullOrEmpty(value) {
    return (!value || value === undefined || value === "" || value.length === 0 || value === null || value === '' || value === 'null');
}

function isObjectNull(value) {
    return (value === undefined || value === null || value === 'undefined' || value === 'null');
}

function isBoolean(value) {
    return !!value === value;
}

function isNumeric(value) {
    if (isNaN(value) || value === undefined || value == null || value.length === 0) {
        return false;
    }
    return true;
}

function isArrayEmpty(arr) {
    if (isObjectNull(arr)) return true;

    return arr.length === 0;
}

function isArraysSameSize(arr1, arr2) {
    return arr1.length === arr2.length;
}

function isArraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const aSorted = [...a].sort();
    const bSorted = [...b].sort();

    for (var i = 0; i < aSorted.length; ++i) {
        if (aSorted[i] !== bSorted[i]) return false;
    }
    return true;
}

function isObjectsEqual(copy, source) {

    const copyPropertyNames = Object.getOwnPropertyNames(copy);
    const sourcePropertyNames = Object.getOwnPropertyNames(source);

    if (copyPropertyNames.length !== sourcePropertyNames.length) {
        return false;
    }

    let result = true;

    for (const propertyName of copyPropertyNames) {

        if (!result) continue;
        result = sourcePropertyNames.includes(propertyName);
    }
    
    if (result) {
        for (const propertyName of copyPropertyNames) {

            if (!result) continue;

            const copyObject = copy[propertyName];
            const sourceObject = source[propertyName];

            if (typeof copyObject === 'object' && typeof sourceObject === 'object') {

                if (!isObjectNull(copyObject) && !isObjectNull(sourceObject)) {
                    result = isObjectsEqual(copyObject, sourceObject);
                } else {
                    result = copyObject === sourceObject;
                }
            } else {
                result = copyObject === sourceObject;
            }
        }
    }

    return result
}

function formatStringToList(val) {
    const maxLength = 50;

    if (isNullOrEmpty(val)) return "";
    if (val.length < maxLength) return val;

    return `${val.slice(0, maxLength - 3)}...`
}

function createTimeObject(value) {
    let valueText = value;
    if (value < 10) valueText = '0' + valueText;
    return { value: value, text: valueText };
}

function copyObject(obj) {
    return Object.assign({}, obj);
}

function deepCopyObject(obj) {
    if (isObjectNull(obj)) return null;
    return JSON.parse(JSON.stringify(obj));
}

function parseNewline(value) {
    if (isNullOrEmpty(value)) return;
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

async function responseToBase64Async(response) {
    const data = await response.text();
    return data.substring(1, data.length - 1);
}

function errorsContainsFromObject(source, obj) {
    let result = false;
    for (const [key, value] of Object.entries(obj)) {
        if (source.includes(value)) {
            result = true;
        }

    }
    return result
}

function getLastElement(array) {
    const length = array.length;
    return array[length - 1];
}

function getFormattedInfoString(infoStrings) {
    let result = "<ul>";
    for (const infoString of infoStrings) {
        if (isNullOrEmpty(infoString)) continue;
        result += `<li>${infoString}</li>`;
    }
    result += "</ul>"

    return result;
}

function removeQuotesFromString(str) {
    return str.replace(/(^"|"$)/g, '');
}

function getValueOrEmptyString(val) {
    if (isNullOrEmpty(val)) return "";
    return val;
}

function isTrue(val) {
    if (isNullOrEmpty(val)) return false;
    return val.toLowerCase() === "true" || val === true;
}

function isFalse(val) {
    if (isNullOrEmpty(val)) return false;
    return val.toLowerCase() === "false" || val === false;
}

// check for null and and all white-spaces
function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

export {
    isNullOrEmpty,
    isObjectNull,
    isBoolean,
    isNumeric,
    isArrayEmpty,
    isArraysSameSize,
    isArraysEqual,
    isObjectsEqual,
    formatStringToList,
    createTimeObject,
    copyObject,
    deepCopyObject,
    parseNewline,
    responseToBase64Async,
    errorsContainsFromObject,
    getLastElement,
    getFormattedInfoString,
    removeQuotesFromString,
    getValueOrEmptyString,
    isTrue,
    isFalse,
    isEmptyOrSpaces
}