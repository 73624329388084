import React, { useState } from 'react';
import PanelBar from '../../../../../components/layout/PanelBar';
import RadioButton from '../../../../../components/layout/RadioButton';
import Spacer from '../../../../../components/layout/Spacer';
import { useEffectOnce } from '../../../../../components/useEffectOnce';
import { EvaluationResponsibilityReasonEnum } from '../../../services/EvaluationHelpers';

function Responsibility(
    {
        evaluation,
        onCallback
    }
) {
    const totalSteps = 1;

    const [isActive, setIsActive] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [selectedReason, setSelectedReason] = useState(EvaluationResponsibilityReasonEnum.NONE);
    const [stepsCompleted, setStepsCompleted] = useState(0);

    useEffectOnce(() => {
        if (evaluation.isRenounced) {
            setSelectedReason(getRenounceType());
            setIsActive(true);
            setStepsCompleted(1);
        } else {
            setIsActive(evaluation.isEvaluationDocumentEmpty);
            if (evaluation.isEvaluationDocumentEmpty === false) {
                setStepsCompleted(1);
                setSelectedReason(getRenounceType());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PanelBar
                expanded={isActive}
                title="Evalueringsansvar"
                className="evaluation-panelbar-responsibility"
                subTitle={`${stepsCompleted}/${totalSteps}`}
                subClassName={`font-${(stepsCompleted === totalSteps) ? 'active' : 'tertiary'}`}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            Det er registrert flere sensorer på denne prøven.<br />
                            Vennligst angi din rolle:
                        </div>
                    </div>
                    <Spacer height={20} />
                    <div className="row g-2">
                        <div className="col-12">
                            <RadioButton
                                label="Jeg har ansvar for å fylle ut evaluering for prøven"
                                checked={selectedReason === EvaluationResponsibilityReasonEnum.YES}
                                onClick={() => onReasonClick(EvaluationResponsibilityReasonEnum.YES)}
                            />
                        </div>
                        <div className="col-12">
                            <RadioButton
                                label="Jeg er medsensor uten evalueringssansvar"
                                checked={selectedReason === EvaluationResponsibilityReasonEnum.NO_RESPONSIBILITY}
                                onClick={() => onReasonClick(EvaluationResponsibilityReasonEnum.NO_RESPONSIBILITY)}
                            />
                        </div>
                        <div className="col-12">
                            <RadioButton
                                label="Jeg er ikke deltakende på prøven"
                                checked={selectedReason === EvaluationResponsibilityReasonEnum.NO_PARTICIPATION}
                                onClick={() => onReasonClick(EvaluationResponsibilityReasonEnum.NO_PARTICIPATION)}
                            />
                        </div>
                    </div>
                </div>
                <Spacer height={20} />
                {
                    showWarning &&
                    <div className="evaluation-form-warning">
                        Ved å lagre endret status for evalueringssansvar, vil alle data i den gjeldende evalueringen slettes for godt.
                    </div>
                }
            </PanelBar>
            <Spacer height={10} />
        </>
    );

    function getRenounceType() {
        if (evaluation.isRenounced) return evaluation.renounceType;

        switch (evaluation.renounceType) {
            case "NO_RESPONSIBILITY":
                return EvaluationResponsibilityReasonEnum.NO_RESPONSIBILITY;
            case "NO_PARTICIPATION":
                return EvaluationResponsibilityReasonEnum.NO_PARTICIPATION;
            default:
                return EvaluationResponsibilityReasonEnum.YES;
        }
    }

    function onReasonClick(reason) {

        setSelectedReason(reason);
        setStepsCompleted(1);

        if (evaluation.isEvaluationDocumentEmpty === false) {
            setShowWarning(getRenounceType() !== EvaluationResponsibilityReasonEnum.NONE && reason !== getRenounceType());
        }

        onCallback(reason);
    }
}

export default Responsibility;
