import React, { useEffect, useState } from 'react';
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import "./quaymanual.css"
import { NavLink } from 'react-router-dom';
import PanelBar from '../../components/layout/PanelBar';
import ContentGridContainer from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import Spacer from '../../components/layout/Spacer';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import SearchQuayLocation from './SearchQuayLocation';

const DispatcherDepartmentItem = ({ item }) => {
    return (
        <>
            <PanelBar title={item.departmentName} className="quaymanual-panelbar">
                {
                    item.pilotMastery.map((p) => (
                        <NavLink key={p.departmentID} to={`/pilot/quaymanual/${p.departmentID}`}><span className="link-text">{p.departmentName}</span></NavLink>
                    ))
                }
            </PanelBar>
            <Spacer height={5} />
        </>
    );
};

const QuayDispatcherDepartments = () => {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [dispatcherDepartments, setDispatcherDepartments] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);

    const initializeAsync = async () => {
        var response = await QuayManualApiRepository.getDispatcherDepartments();
        setHasErrors(!response.ok);
        if(response.ok)
        {
            var result = await response.json()
            setDispatcherDepartments(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
        setIsBusy(false);
    };

    useEffect(() => {
        publishHeaderTopic("Kaibok");
        initializeAsync();
    }, []);

    return (
        <>
            <ContentGridContainer title="Kaibok" titleIcon="book">
                <div className="quaymanual">
                    <SearchQuayLocation setHasSearched={setSearchStatus} />
                    <Spacer height={20} />
                    {hasSearched && 
                        <h2>Formidlingssteder</h2>
                    }
                    {dispatcherDepartments.map(item => (
                        <DispatcherDepartmentItem key={item.departmentID} item={item} />
                    ))}
                </div>
            </ContentGridContainer>

            <Overlay
                isBusy={isBusy}
                hasErrors={hasErrors}
                onReloadClick={() => {
                    setHasErrors(false);
                    setIsBusy(true);
                    initializeAsync();
                }}
            />
        </>
    );


    function setSearchStatus(e) {
        setHasSearched(e);
    }
};

export default QuayDispatcherDepartments;