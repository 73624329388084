/* eslint-disable react-hooks/exhaustive-deps */
import './styles/worktime.css';

import React, { useEffect, useReducer } from 'react';
import { useEffectOnce } from '../../components/useEffectOnce';
import Spacer from '../../components/layout/Spacer';
import moment from 'moment';
import TimelineViewService from '../../components/layout/timelineView/services/TimelineViewService';
import { TimelineView } from '../../components/layout/timelineView/TimelineView';
import { TimelineViewWorkTimes } from '../../components/layout/timelineView/components/TimelineViewWorkTimes';
import { TimelineViewAppointments } from '../../components/layout/timelineView/components/TimelineViewAppointments';
import { TimelineViewType } from '../../components/layout/timelineView/services/Types';
import { TimelineViewNow } from '../../components/layout/timelineView/components/TimelineViewNow';
import { DispatcherActions } from './services/DispatcherActions';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import WorkTimeApiRepository from '../../repositories/api/WorkTimeApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import WorkTimeBottomNavigation from './components/WorkTimeBottomNavigation';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { copyMoment, formatMoment, getFormatMoment } from '../../components/helpers/DateTimeHelpers';
import { getConfigurationAsync } from '../../components/helpers/DataHelpers';

function Timeline() {

    const initialState = {
        isBusy: true,
        hasErrors: false,
        timelineTimes: [],
        appointments: [],
        workTimes: [],
        nowTime: null,
        fromTime: null,
        toTime: null,
        pilotDetails: {},
        initialized: false
    };

    const reducer = (state = initialState, action) => {
        switch (action.type) {

            case DispatcherActions.IsBusy:
                return Object.assign({}, state, {
                    isBusy: true
                });

            case DispatcherActions.HasErrors:
                return Object.assign({}, state, {
                    isBusy: false,
                    hasErrors: true
                });

            case DispatcherActions.Reset:
                return Object.assign({}, state, {
                    isBusy: true,
                    hasErrors: false
                });

            case DispatcherActions.Initialized:
                return Object.assign({}, state, {
                    fromTime: action.payload.fromTime,
                    nowTime: action.payload.nowTime,
                    toTime: action.payload.toTime,
                    timelineTimes: action.payload.timelineTimes,
                    initialized: true
                });

            case DispatcherActions.FetchedPilotAppointments:
                return Object.assign({}, state, {
                    appointments: action.payload,
                    isBusy: false
                });

            case DispatcherActions.PilotageDetails:
                return Object.assign({}, state, {
                    pilotDetails: action.payload
                });

            case DispatcherActions.NowTime:
                return Object.assign({}, state, {
                    nowTime: action.payload
                });

            case DispatcherActions.WorkTimes:
                return Object.assign({}, state, {
                    workTimes: action.payload
                });

            case DispatcherActions.PilotDetails:
                return Object.assign({}, state, {
                    pilotDetails: action.payload
                });

            default:
                return state;
        }
    };

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    }

    // Initialize function
    useEffectOnce(() => {
        document.body.classList.add("no-overscroll-behavior");
        publishHeaderTopic("Tidslinje");

        initializeAsync();
        
        return function cleanupEventListeners() {
            document.body.classList.remove("no-overscroll-behavior");
        }

    }, []);

    useEffect(() => {
        const intervalReference = setInterval(updateNow, 1000);
        return function cleanupEventListeners() {
            clearInterval(intervalReference);
        }

    }, [updateNow]);

    return (
        <>
            
            <ContentGrid title="Tidslinje" titleIcon="pulse" fullSize={false}>
                <div className="simulate-worktime">
                    <TimelineView
                        timelineViewType={{
                            ...TimelineViewType,
                            timelineTimes: state.timelineTimes,
                            nowTime: state.nowTime,
                            fromTime: state.fromTime,
                            toTime: state.toTime,
                            workTimes: state.workTimes,
                            appointments: state.appointments,
                            pilotPilotages: state.pilotDetails.pilotages,
                        }}>
                        <TimelineViewNow />
                        <TimelineViewWorkTimes />
                        <TimelineViewAppointments />
                        <Spacer />
                    </TimelineView>
                </div>
            </ContentGrid>

            <WorkTimeBottomNavigation id="timeline" />

            <Overlay isBusy={state.isBusy} onReloadClick={onReset} hasErrors={state.hasErrors} />
        </>
    );

    function updateNow() {
        //if (state.nowTime === null) return;

        //const newNowTime = CommonService.getFormatMoment(moment());

        //if (newNowTime.minutes() === state.nowTime.minutes()) return;

        //dispatch(DispatcherActions.NowTime, newNowTime);

        //getSimulatedPeriodsAsync(state.pilotDetails, moment().add(-3, 'd'), moment().add(3, 'd'), newNowTime);
    }

    async function initializeAsync() {
        const configuration = await getConfigurationAsync();
        const now = getFormatMoment(moment());

        let sourceFromTime = getFromTime(configuration);
        let fromTime = getFormatMoment(copyMoment(sourceFromTime));
        const toTime = copyMoment(now).add(configuration.pilotWorkTimeSimulationHoursIntoFuture, 'h');

        let array = [];
        while (fromTime < toTime) {
            array.push(moment(fromTime.toDate()));
            fromTime.add(1, 'h');
        }
        
        if (array[array.length - 1].hour() % 2 === 0) {
            array.push(moment(fromTime.toDate()));
        }
        
        dispatch(DispatcherActions.Initialized, {
            fromTime: copyMoment(sourceFromTime),
            nowTime: getFormatMoment(moment()),
            toTime: array.pop(),
            timelineTimes: array
        });
        
        getPilotDetailsAsync(now, sourceFromTime, moment().add(-3, 'd'), moment().add(3, 'd'));
    }

    function getFromTime(configuration) {
        const minutes = moment().minutes();
        return getFormatMoment(moment()).subtract(configuration.pilotWorkTimeSimulationHoursBackInTime, 'h').subtract(minutes, 'm');
    }

    async function getPilotDetailsAsync(now, sourceFromTime, fromTime, toTime) {
        const response = await PilotApiRepository.getPilotDetailsAsync(formatMoment(fromTime), formatMoment(toTime));

        if (response.ok === true) {
            const data = await response.json();
            dispatch(DispatcherActions.PilotDetails, data);
            getSimulatedPeriodsAsync(data, sourceFromTime, toTime, now);
        } else {
            handleError(response);
        }
    }

    async function getSimulatedPeriodsAsync(pilot, fromTime, toTime, now) {
        const pilotWorkTime = pilot.workTime.filter((item) => {
            return moment(item.started) < now;
        });
        pilot.workTime = pilotWorkTime;

        const query = {
            pilot: pilot,
            fromTime: formatMoment(fromTime),
            now: formatMoment(now)
        };
        const response = await WorkTimeApiRepository.searchSimulateAsync(query);

        if (response.ok === true) {
            const data = await response.json();

            dispatch(DispatcherActions.WorkTimes, TimelineViewService.setSimulationPeriodsProperties(data.periods));

            getPilotAppointmentsAsync(pilot, fromTime, toTime);

        } else {
            handleError(response);
        }
    }

    async function getPilotAppointmentsAsync(pilot, fromTime, toTime) {
        const query = {
            periodFrom: formatMoment(fromTime),
            periodTo: formatMoment(toTime)
        };
        const response = await PilotApiRepository.searchAppointmentAsync(query);

        if (response.ok === true) {

            const data = await response.json();
            const calendarAppointments = TimelineViewService.createAppointments(data);
            const pilotageAppointments = TimelineViewService.createPilotageAppointments(pilot.pilotages);
            const result = calendarAppointments.concat(pilotageAppointments);

            dispatch(DispatcherActions.FetchedPilotAppointments, result);

            if (!state.initialized) {
                TimelineViewService.scrollToNow();
            }


        } else {
            handleError(response);
        }
    }

    function handleError(response) {
        publishWarningNotificationTopic("Feil i innhenting av data til tidslinjen", response.status);
        dispatch(DispatcherActions.HasErrors, response.status);
    }

    function onReset() {
        dispatch(DispatcherActions.Reset);
        initializeAsync();
    }
}

export default Timeline;