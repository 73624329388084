import React from 'react';
import { IconColors } from '../../../../services/SystemNames';
import Icons from '../../icons/Icons';
import { CardRightSectionModes } from './CardProperties';

export const CardItemRightSection = (
    {
        properties = {},
        rightSectionMode = CardRightSectionModes.None,
        isSelected = false,
        isSwipeLeftActive = false,
        handleOnToggleDelete,
        handleOnselect
    }) => {
    
    return (
        <div
            className={`card-item-right-section ${getClassNames()}`}
            onClick={handleRightSectionClick}
        >
            {
                {
                    [CardRightSectionModes.Delete]: (<Icons color={IconColors.Primary} iconName="trash" />),
                    [CardRightSectionModes.Select]: (<Icons color={IconColors.Secondary} iconName={isSelected ? "ok" : "square"} />)
                }[rightSectionMode]
            }
        </div>
    );

    function getClassNames() {
        if (!isSwipeLeftActive) return "";

        let classNames = "card-item-right-section-active";

        switch (rightSectionMode) {
            case CardRightSectionModes.Delete:
                classNames += " card-item-right-section-warning";
                break;
            case CardRightSectionModes.Select:
                classNames += ` card-item-right-section-${!isSelected ? 'default' : 'success'}`;
                break;
            default:
                break;
        }

        return classNames;
    }

    function handleRightSectionClick() {

        switch (rightSectionMode) {
            case CardRightSectionModes.Delete:
                properties.onDeleteClick();

                handleOnToggleDelete();
                break;

            default:
                handleOnselect(!isSelected);
                break;
        }
    }
};