import '../pilot/styles/pilot.css';

import Moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from '../../components/useEffectOnce';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import { PilotHistoryContent } from './components/PilotHistoryContent';
import { useReducer } from 'react';
import Overlay from '../../components/layout/overlay/Overlay';
import { publishHeaderTopic } from '../../components/helpers/PubSubHelpers';
import { addElementListener, hasScrolledToBottom, removeElementListener } from '../../components/helpers/ElementHelpers';
import { formatDateTime } from '../../components/helpers/DateTimeHelpers';


const data = {
    pilotHistory: [],
    isLoading: false,
    fromTime: Moment().subtract(50, 'days'),
    toTime: Moment()
}

const Actions = {
    Add: 'add',
    SetFromTime: 'setFromTime',
    SetToTime: 'setToTime',
    SetIsLoading: 'setIsLoading',
}

const History = () => {
    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [httpStatusCode, setHttpStatusCode] = useState(0);
    const [pilotHistoryData, dispatch] = useReducer(pilotHistoryReducer, data);

    const fetchData = useCallback(async () => {

        const fetchHistoryItems = async () => {
            let toTime = pilotHistoryData.pilotHistory.length > 0 ? pilotHistoryData.toTime : Moment();
            return await PilotApiRepository.getHistory(formatDateTime(pilotHistoryData.fromTime), formatDateTime(toTime));
        }

        try {
            handleSetIsLoading(true);
            const response = await fetchHistoryItems();

            if (response.ok === true) {
                const newData = await response.json();
                
                handleAddPilotHistory(newData);

                if (newData.worktimeHistoryDetails.length > 0) {
                    var earliestTimeStamp = newData.worktimeHistoryDetails.slice(-1)[0].timeStamp;
                    handleSetToTime(earliestTimeStamp);
                    handleSetFromTime(earliestTimeStamp);
                }
                else 
                    handleSetFromTime()

            } else {
                handleError(response);
            }

            setIsBusy(false);
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            handleSetIsLoading(false);
        }
    }, [pilotHistoryData])

    useEffectOnce(() => {
        initializeAsync();
    });

    useEffect(() => {
        addElementListener("appContent", "scroll", handleScrollEvent);

        return () => {
            removeElementListener("appContent", "scroll", handleScrollEvent);
        }
    }, [handleScrollEvent]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function handleScrollEvent() {
        if (hasScrolledToBottom() && !pilotHistoryData.isLoading) {
            await fetchData();
        }
    }

    const initializeAsync = async () => {
        publishHeaderTopic('Min Historikk');
        setIsBusy(true);

        await fetchData();
    }

    const handleError = (response) => {
        setHttpStatusCode(response.status);
        setHasErrors(true);
    }

    function handleAddPilotHistory(newData) {
        dispatch({
            type: Actions.Add,
            newData: newData,
        });
    }
    function handleSetFromTime(earliestTimeStamp) {
        dispatch({
            type: Actions.SetFromTime,
            earliestTimeStamp: earliestTimeStamp
        });
    }
    function handleSetToTime(earliestTimeStamp) {
        dispatch({
            type: Actions.SetToTime,
            earliestTimeStamp: earliestTimeStamp
        });
    }
    function handleSetIsLoading(isLoading) {
        dispatch({
            type: Actions.SetIsLoading,
            isLoading: isLoading,
        })
    }

    function pilotHistoryReducer(state, action) {

        switch (action.type) {
            case Actions.Add: {
                return {
                    ...state,
                    pilotHistory: [...state.pilotHistory, ...action.newData.worktimeHistoryDetails],
                }
            }
            case Actions.SetIsLoading: {
                return {
                    ...state,
                    isLoading: action.isLoading
                }
            }
            case Actions.SetFromTime: {
                return {
                    ...state,
                    fromTime: Moment(action.earliestTimeStamp ?? state.fromTime).subtract(30, 'days'),
                }
            }
            case Actions.SetToTime: {
                return {
                    ...state,
                    toTime: Moment(action.earliestTimeStamp).subtract(1, 'second'),
                }
            }
            default: {
                console.error(`Unknow action: ${action.type}`);
                setHasErrors(true);
            }
        }
    }

    return (
        <>
            <PilotHistoryContent pilotHistory={pilotHistoryData.pilotHistory} isLoading={pilotHistoryData.isLoading} />
            <Overlay isBusy={isBusy} hasErrors={hasErrors} onReloadClick={initializeAsync} statusCode={httpStatusCode} />
        </>
    );
}
export default History;

