import './home.css';

import React, { useEffect, useState } from 'react';
import {
    PageStates,
    PersonTypes,
    ApplicationRights,
} from '../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import SourceApiRepository from '../../repositories/api/SourceApiRepository';
import { DefaultCard } from './components/HomeCards';
import MetaIdbRepository from '../../repositories/idb/MetaIdbRepository';
import PubSub from 'pubsub-js';
import Overlay from '../../components/layout/overlay/Overlay';
import { isObjectNull, isNullOrEmpty } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic, PubSubTopics } from '../../components/helpers/PubSubHelpers';
import { initializeTokenAsync } from '../../components/helpers/TokenHelpers';
import { hasApplicationRightsAsync, hasPersonType, hasRightToLoginAsync, isLoggedIn } from '../../components/helpers/AuthHelpers';
import { addAppContentFullSize, removeAppContentFullSize } from '../../components/helpers/ElementHelpers';
import PushPermissionModal from '../pilot/pushNotifications/PushPermissionModal';
import { refreshDeviceKey, updateSubscriptionToken } from '../pilot/pushNotifications/PushHelpers';
import { subscribe } from '../../firebase';
import { getConfigurationAsync } from '../../components/helpers/DataHelpers';

const initialState = {
    pageState: PageStates.Default,
    isBusy: true,
    hasErrors: false,
    name: "",
    isPilot: false,
    modules: [],
    showPushPermissionModal: false
};

function Index() {

    const navigate = useNavigate();
    const [
        {
            isBusy,
            hasErrors,
            name,
            modules,
            showPushPermissionModal
        }, setState] = useState(initialState);
    const [environment, setEnvironment] = useState("");


    // Initialize function
    useEffect(() => {
        publishHeaderTopic("NjordPilotWeb", "", false);
        PubSub.subscribe(PubSubTopics.Logout, handlePubSubTopic);

        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }

        window.addEventListener('online', handleOnOffline);
        window.addEventListener('offline', handleOnOffline);

        addAppContentFullSize();

        return () => {
            window.removeEventListener('online', handleOnOffline);
            window.removeEventListener('offline', handleOnOffline);
            PubSub.unsubscribe(PubSubTopics.Logout);

            removeAppContentFullSize();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <>
                {showPushPermissionModal && <PushPermissionModal />}
            </>
            <div className="home">
                <div className="welcome">
                    {!isNullOrEmpty(environment) && <DefaultCard subTitle={`Velkommen ${name}`} />}
                    <DefaultCard modules={modules} />
                </div>
            </div>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
            
        </>
        
    );

    function shouldShowPushPermissionModal() {
        if (isLoggedIn() && !localStorage.getItem("shownAlready")) {
            try {
                if (Notification?.permission === "default" && !localStorage.getItem("shownAlready")) {
                    setState((prev) => ({
                        ...prev,
                        showPushPermissionModal: true
                    }));                    
                }                
            } catch (error) {
                console.error(error);            
            }
        }
    }

    function handleOnOffline() {
        initializeModulesAsync();
    }

    function onReloadClick() {
        setState((prev) => ({
            ...prev,
            hasErrors: false,
            isBusy: true
        }));

        initializeOnlineAsync();
    }

    function handlePubSubTopic() {
        // kommer man hit når man logger ut mens man er på forsiden?
        publishHeaderTopic("");

        setState((prev) => ({
            ...prev,
            name: "",
            modules: []
        }));

        setTimeout(() =>
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default
            })), 250);
    }

    async function initializeOnlineAsync() {

        await initializeTokenAsync();

        var configuration = await getConfigurationAsync();
        setEnvironment(configuration.environment);

        if (!isLoggedIn() || await !hasRightToLoginAsync()) {
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default,
                isBusy: false
            }));
        } else {          
            shouldShowPushPermissionModal();  
            const response = await SourceApiRepository.getWhoAmIAsync();
            if (response.ok === true) {
                const data = await response.json();

                initializeModulesAsync();

                const deviceId = localStorage.getItem("deviceId");
                if (deviceId) {
                    refreshDeviceKey(deviceId);
                    updateSubscriptionToken().then(() => localStorage.setItem("refreshed", true));
                }
                
                await MetaIdbRepository.setWhoAmIAsync(data);

                setState((prev) => ({
                    ...prev,
                    name: data.firstName,
                    pageState: PageStates.Welcome,
                    isPilot: hasPersonType(PersonTypes.Pilot)
                }));

            } else {

                publishWarningNotificationTopic("Applikasjonen f&aring;r ikke kontakt med serveren");
                initializeOfflineAsync();
            }

            setState((prev) => ({
                ...prev,
                isBusy: false
            }));
        }
    }

    async function initializeOfflineAsync() {

        if (isLoggedIn() === false) {
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default,
                isBusy: false
            }));

            //for push notifications
            localStorage.removeItem("refreshed")
            if (window?.Notification?.permission !== "granted") {
                localStorage.removeItem("shownAlready")
            }

        } else {
            initializeModulesAsync();

            const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
            let firstName = "";

            if (!isObjectNull(whoAmI)) {
                firstName = whoAmI.firstName;
            } else {
                publishWarningNotificationTopic("Kunne ikke laste inn person informasjon");
            }
            setState((prev) => ({
                ...prev,
                isPilot: true,
                pageState: PageStates.Welcome,
                name: firstName,
                isBusy: false,
                hasErrors: false
            }));
        }
    }

    async function initializeModulesAsync() {
        const modules = [];
        const isPilot = hasPersonType(PersonTypes.Pilot);

        if (isPilot) {
            if (navigator.onLine) {
                modules.push(
                    {
                        title: "Arbeidstid",
                        icon: "stopwatch",
                        onClick: () => navigate('/worktime/register'),
                        onlineOnly: true,
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push(
                    {
                        title: "Mine oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilot/pilotages/assigned'),
                        onlineOnly: false,
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push(
                    {
                        title: "Alle oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilotages/current'),
                        onlineOnly: false,  
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push(
                    {
                        title: "Losoversikt",
                        icon: "pilotNotRelevant",
                        onClick: () => navigate('/pilot/station'),
                        onlineOnly: true,
                        type: PersonTypes.Pilot,
                    }
                );
            } else {
                modules.push(
                    {
                        title: "Mine oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilot/pilotages/assigned'),
                        onlineOnly: false,
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push({
                    title: "Evalueringer",
                    icon: "evaluation",
                    onClick: () => navigate('/evaluation'),
                    onlineOnly: false,
                    type: PersonTypes.Pilot,
                });
            }
        } else {
            if (navigator.onLine) {
                const hasApplicationRightCoastalSegmentEdit = await hasApplicationRightsAsync([ApplicationRights.EditPilotCoastalSegments]);
                if (hasApplicationRightCoastalSegmentEdit) {
                    modules.push(
                        {
                            title: "Sertifikat-omr&aring;der",
                            icon: "mapSegments",
                            onClick: () => navigate('/pcs/groups'),
                            onlineOnly: true
                        }
                    );
                }

                const hasApplicationRightCoastalSegmentEvaluation = await hasApplicationRightsAsync([ApplicationRights.EditPilotCoastalSegmentsEvaluation]);
                if (hasApplicationRightCoastalSegmentEvaluation) {
                    modules.push(
                        {
                            title: "S.O. Evalueringer",
                            icon: "mapSegments",
                            onClick: () => navigate('/pcs/evaluations'),
                            onlineOnly: true
                        }
                    );
                }

                const hasApplicationRightCoastalSegmentLocation = await hasApplicationRightsAsync([ApplicationRights.EditPilotCoastalSegmentsLocations]);
                if (hasApplicationRightCoastalSegmentLocation) {
                    modules.push(
                        {
                            title: "S.O. Lokasjoner",
                            icon: "mapSegments",
                            onClick: () => navigate('/pcs/locations'),
                            onlineOnly: true
                        }
                    );
                }

                modules.push(
                    {
                        title: "Alle oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilotages/current'),
                        onlineOnly: true,
                    }
                );
            }
        }

        setState((prev) => ({
            ...prev,
            modules: modules
        }));
    }
}

export default Index;
