import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { MapMarker } from './MapMarker';
import LocationIdbRepository from '../../../../repositories/idb/LocationIdbRepository';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { isArrayEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { LocationTypes, MarkerTypes } from '../GoogleMapHelpers';


export const MapLocationMarkers = forwardRef(({
    properties,
    isLocationsActive,
    toggleMarkerInfoWindows,
    setToggleMarkerInfoWindows
}, ref) => {

    useImperativeHandle(ref, () => ({
        onBoundsChanged() {
            onChange();
        },
        onZoomChanged() {
            onChange();
        },
        onDragEnd() {
            onChange();
        },
    }));

    const map = useMap();  

    const [currentZoom, setCurrentZoom] = useState(0);
    const [locationMarkers, setLocationMarkers] = useState([]);

    const componentRef = useRef({
        hasInitialized: false
    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        if (!map) return;

        if (!isLocationsActive) {
            clear();
            return;
        }
        
        initializeAsync();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, isLocationsActive]);

    return (
        locationMarkers.map((marker, index) =>
            <MapMarker
                key={index}
                marker={marker}
                currentZoom={currentZoom}
                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
            />
        )
    )

    function onChange() {
        if (!isLocationsActive) {
            clear();
            return;
        }

        initializeAsync();
    }

    async function initializeAsync() {

        if (localRef.hasInitialized) {
            setCurrentZoom(map.getZoom());
            return;
        }

        const markers = [];

        let locations = await LocationIdbRepository.getAllAsync();

        const pilotageId = Number(properties.pilotageId);
        if (pilotageId > 0) {
            const pilotAssignment = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
            if (!isObjectNull(pilotAssignment)) {
                locations = locations.filter(l => l.locationId !== pilotAssignment.pilotage.fromLocation.locationId);
                locations = locations.filter(l => l.locationId !== pilotAssignment.pilotage.toLocation.locationId);
            }
        }

        for (let location of locations) {
            const existing = markers.filter(m => m.id === location.locationId);
            if (isArrayEmpty(existing)) {
                const latLng = new window.google.maps.LatLng(location.latitude, location.longitude);
                markers.push(
                    {
                        id: location.locationId,
                        description: location.name,
                        isEligibleForSpecialPilotageFromTimeLockDeadline: location.isEligibleForSpecialPilotageFromTimeLockDeadline,
                        position: latLng,
                        type: MarkerTypes.Location,
                        color: getLocationColor(location.systemName)
                    });
            }
        }

        setLocationMarkers(markers);
        localRef.hasInitialized = true;
        setCurrentZoom(map.getZoom());
    }

    function clear() {
        if (!localRef.hasInitialized && isArrayEmpty(locationMarkers)) return;
        localRef.hasInitialized = false;
        setLocationMarkers([]);
    }

    function getLocationColor(systemName) {
        switch (systemName) {
            case LocationTypes.Harbour:
                return "var(--map-pin-location-harbour-bg)";
            case LocationTypes.Anchorage:
                return "var(--map-pin-location-anchorage-bg)";
            case LocationTypes.LocationAtSea:
                return "var(--map-pin-location-location-at-sea-bg)";
            case LocationTypes.PilotBoarding:
                return "var(--map-pin-location-pilot-boarding-bg)";
            default:
                return "var(--map-pin-location-default-bg)";
        }
    }
})
