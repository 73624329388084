import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconColors } from "../../../../services/SystemNames";
import Icons from "../../icons/Icons";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { isArrayEmpty, isObjectNull } from "../../../helpers/ObjectHelpers";
import { addClassToElementById, getElementById, removeClassFromElementById } from "../../../helpers/ElementHelpers";
import {
    useMap,
} from '@vis.gl/react-google-maps';
import Switch from "../../switch";
import Spacer from "../../Spacer";
import { Circle } from "phosphor-react-sc";
import { NavigationArrow, Square, Triangle, MapPin, X } from "phosphor-react-sc";

const SettingsStates = {
    View: "View",
    Info: "Info"
}

export const MapSettings = forwardRef((
    {
        mapId,
        properties,
        pilotage,
        shipPosition,
        onSeaMapClick,
        onLocationsClick
    }, ref) => {

    useImperativeHandle(ref, () => ({
        deactivateLegend() {
            setIsLegendActive(false);
        }
    }));

    const map = useMap();
    const [isAdded, setIsAdded] = useState(false);
    const contentId = `googleMapControlSettingsContent-${mapId}`;

    const [isLegendActive, setIsLegendActive] = useState(true);
    
    const [state, setState] = useState(SettingsStates.View);

    useEffect(() => {

        if (!properties.showSettings) return;
        if (!map || isAdded) return;

        const content = getElementById(contentId);
        map.controls[window.google.maps.ControlPosition.LEFT_CENTER].push(content);

        setIsAdded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties]);
    
    return (
        <div id={contentId} className={`google-map-settings`}>
            <div className="google-map-settings-content">
                <div>
                    <MapSettingsMenu set={setState} state={state} />
                    <div onClick={onClose}>
                        <X color={IconColors.Primary} size={30} />
                    </div>
                </div>

                {(() => {
                    switch (state) {
                        case SettingsStates.Info:
                            return (
                                <MapSettingsInfo 
                                    pilotage={pilotage}
                                    properties={properties} />
                            );
                        default:
                            return (
                                <MapSettingsView
                                    mapId={mapId}
                                    properties={properties}
                                    shipPosition={shipPosition}
                                    onSeaMapClick={onSeaMapClick}
                                    onLocationsClick={onLocationsClick}
                                    isLegendActive={isLegendActive}
                                    setIsLegendActive={setIsLegendActive}
                                />
                            )
                    }
                })()}
            </div>
        </div>
    )

    function onClose() {
        const elem = getElementById(contentId);
        if (isObjectNull(elem)) return;
        elem.classList.remove("google-map-settings-active");
    }
})

const MapSettingsView = ({
    mapId,
    properties,
    pilotage,
    shipPosition,
    onSeaMapClick,
    onLocationsClick,
    isLegendActive,
    setIsLegendActive}) => {

    const [isMapActive, setIsMapActive] = useState(true);
    const [isMapTerrainSelected, setIsMapTerrainSelected] = useState(false);
    const [isSateliteActive, setIsSateliteActive] = useState(false);
    const [isSateliteLabelsSelected, setIsSateliteLabelsSelected] = useState(true);
    const [isSeamapActive, setIsSeamapActive] = useState(false);
    const [isLocationsActive, setIsLocationsActive] = useState(properties.showLocations);

    return (
        <div>
            <Spacer/>
            <div className="google-map-settings-alternative">
                <RadioButton 
                    size="large"
                    checked={isMapActive}
                    onChange={onMapChange}
                    label="Kart"
                />
            </div>
            <Spacer height={5} />
            <div className="google-map-settings-switch-alternative">
                <Checkbox
                    size="large"
                    label="Terreng"
                    disabled={!isMapActive}
                    onChange={onMapTerrainChange}
                    checked={isMapTerrainSelected} />
            </div>
            <Spacer height={10} />
            <div className="google-map-settings-alternative">
                <RadioButton
                    size="large"
                    checked={isSateliteActive}
                    onChange={onSateliteChange}
                    label="Satellitt"
                />
            </div>
            <Spacer height={5} />
            <div className="google-map-settings-switch-alternative">
                <Checkbox
                    size="large"
                    label="Etiketter"
                    disabled={!isSateliteActive}
                    onChange={onSateliteLabelsChange}
                    checked={isSateliteLabelsSelected} />
            </div>
            <Spacer />
            <div className="google-map-settings-header">Grunnlag</div>
            <div className="google-map-settings-alternative">
                <Switch
                    onLabel="Sjøkart"
                    size="small"
                    fontSize={16}
                    bold={false}
                    checked={isSeamapActive}
                    onChange={() => {
                        onSeaMapClick(!isSeamapActive);
                        setIsSeamapActive(!isSeamapActive);
                    }}
                />
            </div>
            <Spacer />
            <div className="google-map-settings-header">Vis</div>
            {
                (!isObjectNull(pilotage) || !isObjectNull(shipPosition) || properties.pecExam) &&
                <>
                    <div className="google-map-settings-alternative">
                        <Switch
                            onLabel="Legend"
                            size="small"
                            fontSize={16}
                            bold={false}
                            checked={isLegendActive}
                            onChange={onLegendChange}
                        />
                    </div>
                    <Spacer height={5} />
                </>
            }
            <div className="google-map-settings-alternative">
                <Switch
                    onLabel="Lokasjoner"
                    size="small"
                    fontSize={16}
                    bold={false}
                    checked={isLocationsActive}
                    onChange={() => {
                        setIsLocationsActive(!isLocationsActive);
                        setTimeout(() => {
                            onLocationsClick(!isLocationsActive);
                        }, 75);
                    }}
                />
            </div>
        </div>
    );

    function onMapChange() {
        if (isMapActive) return;

        setIsMapActive(true);
        setIsSateliteActive(false);

        onClick("Kart")
    }

    function onMapTerrainChange() {
        onChange(0, setIsMapTerrainSelected, isMapTerrainSelected);
    }

    function onSateliteChange() {
        if (isSateliteActive) return;

        setIsSateliteActive(true);
        setIsMapActive(false);

        onClick("Satellitt")
    }

    function onSateliteLabelsChange() {
        onChange(1, setIsSateliteLabelsSelected, isSateliteLabelsSelected);
    }

    function onClick(text) {
        const elem = findElementByText(text)
        if (isObjectNull(elem)) return;

        elem.click();
    }

    function onChange(menuIndex, set, val) {
        const elem = document.querySelector('[role="menubar"]');
        if (isObjectNull(elem)) return;

        const menus = elem.querySelectorAll('[role="menu"]');
        if (isArrayEmpty(menus)) return;

        const mapMenu = menus[menuIndex];
        const mapMenuItem = mapMenu.querySelector('[role="menuitemcheckbox"]')

        if (isObjectNull(mapMenuItem)) return;
        mapMenuItem.click();

        set(!val);
    }

    function findElementByText(text) {
        let button = null;
        const elems = document.querySelectorAll('[role="menuitemradio"]');

        for (const elem of elems) {
            if (elem.innerText.toUpperCase() === text.toUpperCase()) {
                button = elem;
            }
        }

        return button;
    }

    function onLegendChange() {

        const id = `googleMapLegend-${mapId}`;
        const titleId = `googleMapTitle-${mapId}`
        const className = "google-map-legend-active";
        const titleClassName = "google-map-title-with-legend";
        const elem = getElementById(id);

        if (isObjectNull(elem)) return;

        if (elem.classList.contains(className)) {
            removeClassFromElementById(id, className);
            removeClassFromElementById(titleId, titleClassName);
        } else {
            addClassToElementById(id, className);
            addClassToElementById(titleId, titleClassName);
        }

        setIsLegendActive(!isLegendActive);
    }
}

const MapSettingsInfo = ({ pilotage, properties }) => {
    return (
        <div>
            {
                (!isObjectNull(pilotage)) &&
                <>
                    <div className="google-map-settings-header">Oppdrag</div>
                    <div className="google-map-settings-legend">
                        <MapSettingsInfoLocation color={IconColors.PinStart} text={`Fra: ${pilotage.fromLocation.name}`} />
                        <MapSettingsInfoLocation color={IconColors.PinStop} text={`Til: ${pilotage.toLocation.name}`} />
                    </div>
                </>
            }
            <div className="google-map-settings-header">Lokasjoner</div>
            <div className="google-map-settings-legend">
                <MapSettingsInfoLocationType color="var(--map-pin-location-harbour-bg)" text="Havn" />
                <MapSettingsInfoLocationType color="var(--map-pin-location-pilot-boarding-bg)" text="Losbordingspunkt" />
                <MapSettingsInfoLocationType color="var(--map-pin-location-location-at-sea-bg)" text="Sted i sjøen" />
                <MapSettingsInfoLocationType color="var(--map-pin-location-anchorage-bg)" text="Ankerplass" />
                <Spacer />
                <div>
                    <div>
                        <Circle
                            size={16}
                            weight="fill"
                            className="svg-icon"
                            color={IconColors.ShipDefault}
                        />
                    </div>
                    <div>Standard låsingsfrist</div>
                </div>
                <div>
                    <div>
                        <Triangle
                            size={16}
                            weight="fill"
                            className="svg-icon"
                            color={IconColors.ShipDefault}
                            style={{
                                transform: `rotate(180deg)`
                            }}
                        />
                    </div>
                    <div>Avvikende låsingsfrist</div>
                </div>
            </div>
            {
                (!isObjectNull(pilotage) || properties.isAisActive) &&
                <>
                    <div className="google-map-settings-header">Fartøy</div>
                    <div className="google-map-settings-legend">
                        <MapSettingsInfoVesselType color={IconColors.ShipPilotVessel} text="Tilbringer" />
                        <MapSettingsInfoVesselType color={IconColors.ShipCargo} text="Fraktbåt" />
                        <MapSettingsInfoVesselType color={IconColors.ShipTanker} text="Tankbåt" />
                        <MapSettingsInfoVesselType color={IconColors.ShipPassenger} text="Passasjerbåt" />
                        <MapSettingsInfoVesselType color={IconColors.ShipFish} text="Fiskebåt" />
                        <MapSettingsInfoVesselType color={IconColors.ShipTug} text="Taubåt" />
                        <MapSettingsInfoVesselType color={IconColors.ShipDefault} text="Annet/Ukjent" />
                    </div>
                    <Spacer />
                    <div className="google-map-settings-legend">
                        <div>
                            <div>
                                <Square
                                    size={16}
                                    weight="fill"
                                    className="svg-icon"
                                    color={IconColors.ShipDefault}
                                    style={{
                                        transform: `rotate(45deg)`
                                    }}
                                />
                            </div>
                            <div>&nbsp;Fortøyd/forankret</div>
                        </div>
                        <MapSettingsInfoVessel color={IconColors.ShipDefault} text="Underveis" />
                        <Spacer />
                        <MapSettingsInfoVessel color={IconColors.ShipDefault} text="&nbsp;&nbsp;&nbsp;&nbsp;Mindre enn 75m" size={16} />
                        <MapSettingsInfoVessel color={IconColors.ShipDefault} text="&nbsp;&nbsp;Mellom 75m og 150m" size={24} />
                        <MapSettingsInfoVessel color={IconColors.ShipDefault} text="Større enn 150m" size={32} />
                    </div>
                </>
            }
            <Spacer/>
        </div>
    );
}

const MapSettingsMenu = ({ set, state }) => {
    return (
        <div className="google-map-settings-content-menu">
            <MapSettingsMenuItem icon="map" set={set} state={state} newState={SettingsStates.View} />
            <MapSettingsMenuItem icon="information" set={set} state={state} newState={SettingsStates.Info} />
        </div>
    )
}

const MapSettingsMenuItem = ({ icon, set, state, newState }) => {

    return (
        <div className={getClassName()} onClick={onClick}>
            <Icons iconName={icon} dimensions={30} />
        </div>
    )

    function getClassName() {
        if (state === newState) return "google-map-settings-content-menu-active";
        return "";
    }

    function onClick() {
        if (state === newState) return;
        set(newState);
    }
}

const MapSettingsInfoLocationType = ({ color, text }) => {
    return (
        <div>
            <div>
                <Circle weight="fill" color={color} className="svg-icon" />
                &nbsp;&nbsp;
                <Triangle weight="fill" color={color} className="svg-icon" style={{
                    transform: 'rotate(180deg)'
                }} />
            </div>
            <div>{text}</div>
        </div>
    );
}

const MapSettingsInfoVesselType = ({ color, text }) => {
    return (
        <div>
            <div>
                <Square
                    weight="fill"
                    color={color}
                    className="svg-icon"
                    size={16}
                    style={{
                        transform: `rotate(45deg)`
                    }}
                />&nbsp;&nbsp;
                <NavigationArrow
                    size={20}
                    weight="fill"
                    className="svg-icon"
                    color={color}
                    style={{
                        transform: `rotate(135deg)`
                    }}
                />
            </div>
            <div>{text}</div>
        </div>
    );
}

const MapSettingsInfoVessel = ({ color, text, size = 20 }) => {
    return (
        <div>
            <div>
                <NavigationArrow
                    size={size}
                    weight="fill"
                    className="svg-icon"
                    color={color}
                    style={{
                        transform: `rotate(135deg)`
                    }}
                />
            </div>
            <div>{text}</div>
        </div>
    );
}

const MapSettingsInfoLocation = ({ color, text }) => {
    return (
        <div>
            <div><MapPin weight="fill" color={color} size={30} className="svg-icon" /></div>
            <div>{text}</div>
        </div>
    );
}