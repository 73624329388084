import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { CardModes, CardProperties } from '../../../components/layout/card/components/CardProperties';
import { CardList } from '../../../components/layout/card/CardList';
import { isArrayEmpty, isNullOrEmpty, parseNewline } from '../../../components/helpers/ObjectHelpers';
import TextFieldWrapper from '../../../components/layout/fieldWrappers/TextFieldWrapper';
import Spacer from '../../../components/layout/Spacer';

export const ItemDefaultCard = ({ types, onClick, onDelete }) => {

    const [cards, setCards] = useState([]);

    useEffect(() => {

        function generateCards() {
            const result = [];

            for (const item of types) {
                result.push({
                    guid: item.guid,
                    properties: {
                        ...CardProperties,
                        id: item.guid,
                        title: item.displayName,
                        onClick: item.isEditableByOwner ? () => onClick(item) : null,
                        onDeleteClick: canDelete(item) ? () => handleOnDelete(item) : null,
                        isBorderLeftFat: true,
                        isSwipeEnabled: true,
                        mode: CardModes.Single
                    },
                    body: 
                        (!isNullOrEmpty(item.validTo)) &&
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <TextFieldWrapper title="Utl&oslash;psdato" boldTitle={true} text={item.validToString} />
                                    </div>
                                    <div className="col-6">
                                        {
                                            item.warnByEmailDaysBeforeExpiry > 0 ?
                                                <TextFieldWrapper title="Varsel gis" boldTitle={true} text={parse(`${item.warnByEmailDaysBeforeExpiry} dager f&oslash;r utl&oslash;p`)} />
                                                :
                                                <TextFieldWrapper title="Ingen varsel gis" boldTitle={true} />
                                        }
                                    </div>
                                    <Spacer />
                                    <div className="col-12">
                                        <TextFieldWrapper title="Merknad" boldTitle={true} text={!isNullOrEmpty(item.remark) ? parse(parseNewline(item.remark)) : 'Ingen merknad'} />
                                    </div>
                                </div>
                            </div>     
                    }
                );
            }
            
            setCards(result);
        }

        generateCards();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [types]);

    return (
        (!isArrayEmpty(cards)) &&
            <CardList
                cards={cards}
                mode={CardModes.Single}
            />
    );

    function handleOnDelete(item) {
        onDelete(item);
    }

    function canDelete(item) {
        return item.isEditableByOwner && item.validTo !== null;
    }
}
