/* eslint-disable react-hooks/exhaustive-deps */
import './actionsheet.css'

import React, { useEffect, useState } from 'react';
import { ActionSheet as KendoActionSheet, ActionSheetContent as KendoActionSheetContent } from "@progress/kendo-react-layout";
import { CancelIcon, EditIcon, TrashIcon } from './icons/Icons';
import { Button } from "@progress/kendo-react-buttons";
import { IconsContext } from '@progress/kendo-react-common';

export default function ActionSheet({
    expand = false,
    items = [],
    onOverlayClick,
    onItemClick, }) {

    const icons = {
        "trash": TrashIcon,
        "cancel": CancelIcon,
        "edit": EditIcon
    };

    const [suppressOverlayClick, setSuppressOverlayClick] = useState(true);

    useEffect(() => {
        if (expand) {
            setTimeout(() => {
                setSuppressOverlayClick(false);
            }, 250);
        } else {
            setSuppressOverlayClick(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expand]);

    return (
        <>
            <IconsContext.Provider
                value={{
                    type: 'svg',
                    icons: icons,
                }}
            >
                <KendoActionSheet
                    expand={expand}
                    animation={true}
                    onOverlayClick={handleOverlayClick}>
                    <KendoActionSheetContent>
                        <div className="action-sheet-content">
                            {
                                items.map((item, index) => 
                                    <Button
                                        key={index} onClick={() => handleItemClick(item)}
                                        themeColor={item.theme}
                                        icon={item.icon}>
                                            {item.title}
                                    </Button>
                                )
                            }
                            <Button
                                onClick={onOverlayClick}
                                icon="cancel">
                                Avbryt
                            </Button>
                        </div>
                    </KendoActionSheetContent>
                </KendoActionSheet>
            </IconsContext.Provider>
        </>
    )

    function handleOverlayClick() {
        if (suppressOverlayClick) return;

        onOverlayClick();
    }

    function handleItemClick(item) {
        onOverlayClick();
        onItemClick(item);
    }
    
}


