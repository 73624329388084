import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import Spacer from '../../../components/layout/Spacer';
import NoticeTimeRow from './NoticeTimeRow';
import Icons, { OkIcon } from '../../../components/layout/icons/Icons';
import { NoticeTimeService } from '../services/NoticeTimeService';
import { LocationList } from './LocationGroupDialog';
import LocationIdbRepository from '../../../repositories/idb/LocationIdbRepository';
import SearchTextBoxFieldWrapper from '../../../components/layout/fieldWrappers/SearchTextBoxFieldWrapper';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { getTimeMinutes } from '../../../components/helpers/DataHelpers';

export function LocationDialog({ item, general, onClose, onSave, locationIds }) {

    const [canSave, setCanSave] = useState(false);
    const [minutes, setMinutes] = useState([]);
    const [selectedMinuteDay, setSelectedMinuteDay] = useState(null);
    const [selectedMinuteNight, setSelectedMinuteNight] = useState(null);
    const [dayTime, setDayTime] = useState("");
    const [nightTime, setNightTime] = useState("");
    const [searchText, setSearchText] = useState("");
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [searchLocations, setSearchLocations] = useState([]);
    const [canSearchLocations, setCanSearchLocations] = useState(true);
    const [selectionChanged, setSelectionChanged] = useState(false);
    const [dialogSize, setDialogSize] = useState("large");
    
    // Initialize function
    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    return (
        <Dialog properties={{
            ...DialogProperties,
            title: getTitle(),
            onClose: onClose,
            size: dialogSize,
            actions: [

                {
                    onClick: onClose,
                    icon: "close",
                    text: "Avbryt"
                },
                {
                    icon: "ok",
                    onClick: handleOnSave,
                    themeColor: "primary",
                    text: "Lagre",
                    disabled: !canSave
                }
            ]
        }}>
            <Spacer height={10} />
            <div className="container">
                <div className="row align-items-center">
                    <NoticeTimeRow
                        icon={<Icons iconName="day" />}
                        time={dayTime}
                        selectedMinute={selectedMinuteDay}
                        minutes={minutes}
                        onChange={handleOnDayChange}
                    />
                </div>
                <div className="row align-items-center">
                    <Spacer height={20} />
                </div>
                <div className="row align-items-center">
                    <NoticeTimeRow
                        icon={<Icons iconName="night" />}
                        time={nightTime}
                        selectedMinute={selectedMinuteNight}
                        isDay={false}
                        minutes={minutes}
                        onChange={handleOnNightChange}
                    />
                </div>
            </div>

            {
                (
                    selectionChanged &&
                    general.defaultMinutesBeforeToNoticeDay === selectedMinuteDay?.value &&
                    general.defaultMinutesBeforeToNoticeNight === selectedMinuteNight?.value) &&
                <>
                    <Spacer />
                    <div className="container font-warning">
                        <div className="row">
                            <div className="col">
                                Lokasjoner som lagres med varslingstid dag lik {general.defaultMinutesBeforeToNoticeDay}min og varslingstid natt lik {general.defaultMinutesBeforeToNoticeNight}min, vil bli satt tilbake til generell varslingstid.
                            </div>
                        </div>
                    </div>
                </>
            }
            
            {
                (!NoticeTimeService.isOverridden(item) && canSearchLocations) &&
                    <>
                        <Spacer height={20} />
                        <SearchTextBoxFieldWrapper
                            title="Velg lokasjon"
                            value={searchText}
                            placeholder="Lokasjon eller kode"
                            onChange={onSearchTextChangeAsync}
                        />
                        <LocationList
                            locations={searchLocations}
                            title="S&oslash;keresultat"
                            icon={OkIcon}
                            onClick={onAddLocation}
                        />
                        
                    </>
            }
            <Spacer height={10} />
        </Dialog>
    );

    async function initializeAsync() {
        
        const dayTimeStart = Moment("2000-01-01").add(general.dayStartTimeInMinutes, 'minutes');
        setDayTime(dayTimeStart.format("HH:mm"));

        const nightTimeStart = Moment("2000-01-01").add(general.dayEndTimeInMinutes, 'minutes');
        setNightTime(nightTimeStart.format("HH:mm"));

        const timeMinutes = getTimeMinutes(false);
        setMinutes(timeMinutes);

        let minutesBeforeToNoticeDay = general.defaultMinutesBeforeToNoticeDay;
        let minutesBeforeToNoticeNight = general.defaultMinutesBeforeToNoticeNight;
        let locationId = 0;
        
        if (NoticeTimeService.isOverridden(item)) {
            minutesBeforeToNoticeDay = item.minutesBeforeToNoticeDay;
            minutesBeforeToNoticeNight = item.minutesBeforeToNoticeNight;
            locationId = item.sourceId;
        } else {
            if (!isObjectNull(item)) {
                locationId = item.id;
            }
        }

        if (locationId > 0) {
            const location = await LocationIdbRepository.getByIdAsync(locationId);
            if (!isNullOrEmpty(location)) {
                setSelectedLocations([location]);
            } else {
                setSelectedLocations([{
                    locationId: item.id,
                    name: item.name
                }]);
            }
            
            setCanSearchLocations(false);
            setDialogSize("medium");
        }

        const selectedMinuteDay = timeMinutes.find(m => Number(m.value) === Number(minutesBeforeToNoticeDay));
        setSelectedMinuteDay(selectedMinuteDay);

        const selectedMinuteNight = timeMinutes.find(m => Number(m.value) === Number(minutesBeforeToNoticeNight));
        setSelectedMinuteNight(selectedMinuteNight);
    }

    function handleOnDayChange(e) {
        const minute = e.target.value;
        setSelectedMinuteDay(minute);
        setCanSave(!isArrayEmpty(selectedLocations));
        setSelectionChanged(true);
    }

    function handleOnNightChange(e) {
        const minute = e.target.value;
        setSelectedMinuteNight(minute);
        setCanSave(!isArrayEmpty(selectedLocations));
        setSelectionChanged(true);
    }

    function handleOnSave() {
        const location = {
            minutesBeforeToNoticeDay: selectedMinuteDay.value,
            minutesBeforeToNoticeNight: selectedMinuteNight.value,
            locationID: selectedLocations[0].locationId,
            name: selectedLocations[0].name
        };

        onSave(location);
    }

    function getTitle() {
        if (isArrayEmpty(selectedLocations)) return "Ny lokasjon";
        return selectedLocations[0].name;
    }

    async function onSearchTextChangeAsync(e) {
        setSearchText(e.value);

        const result = await LocationIdbRepository.searchAsync(e.value);
        const locations = [];
        
        for (var location of result) {
            let shouldAdd = false;
            if (isNullOrEmpty(item)) {
                shouldAdd = !locationIds.overriddenIds.includes(location.locationId) &&
                    !locationIds.generalIds.includes(location.locationId);
            } else {
                shouldAdd = locationIds.generalIds.includes(location.locationId);
                
            }

            if (!isArrayEmpty(selectedLocations)) {
                if (selectedLocations[0].locationId === location.locationId) {
                    shouldAdd = false;
                }
            }

            if (!shouldAdd) continue;
            
            locations.push(location);
        }

        setSearchLocations(locations);
    }

    function onAddLocation(e) {
        setSelectedLocations([e]);
        setCanSave(true);
    }
}
