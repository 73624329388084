import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import { NumericTextBox } from '../../../../components/layout/NumericTextBox';
import Spacer from '../../../../components/layout/Spacer';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
    Error,
} from "@progress/kendo-react-labels";
import {
    TextBox,
} from "@progress/kendo-react-inputs";
import CompensationApiRepository from '../../../../repositories/api/CompensationApiRepository';
import { deepCopyObject, isNullOrEmpty, isNumeric } from '../../../../components/helpers/ObjectHelpers';
import { publishErrorNotificationTopic } from '../../../../components/helpers/PubSubHelpers';
import TextAreaFieldWrapper from '../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';


export const VariableCompensationBoardingDialog = ({ compensation = null, onClose, callback }) => {
    const maxOverflow = 2;
    const [maxOverriddenNumber, setMaxOverriddenNumber] = useState(0);

    const [title, setTitle] = useState("");
    const [canSave, setCanSave] = useState(false);
    const [exceeded, setExceeded] = useState(false);
    const [overriddenNumber, setOverriddenNumber] = useState(null);
    const [remark, setRemark] = useState("")

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: title,
                onClose: onClose,
                className: "dialog-std",
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClickAsync,
                        disabled: !canSave,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <div className="dialog-form">

                <FieldWrapper>
                    <Label className="k-form-label">
                        Beregnet antall:
                    </Label>
                    <div className="k-form-field-wrap">
                        <TextBox value={compensation.calculatedNumber} readOnly={true} disabled={true} />
                    </div>
                </FieldWrapper>

                <Spacer height={20} />

                <FieldWrapper>
                    <Label className="k-form-label">
                        Overstyrt av los:
                    </Label>
                    <div className="k-form-field-wrap">
                        <div className="row align-items-center">
                            <div className="col">
                                <NumericTextBox value={overriddenNumber} onChange={onOverriddenNumberChange} />
                            </div>
                        </div>
                    </div>
                    {
                        exceeded &&
                        <Error>
                            Overstyring kan ikke overstige {maxOverriddenNumber}
                        </Error>
                    }
                </FieldWrapper>

                <Spacer height={20} />

                <TextAreaFieldWrapper
                    title="Begrunnelse"
                    value={remark}
                    onChange={onDescriptionChange}
                />
            </div>
        </Dialog>
    ) 

    async function initializeAsync() {
        if (isNullOrEmpty(compensation.type)) return;

        setOverriddenNumber(compensation.overriddenNumber);
        setTitle(compensation.type.nameNor);

        const maximum = compensation.calculatedNumber + maxOverflow;
        setMaxOverriddenNumber(maximum);

        if (isNumeric(compensation.overriddenNumber)) {
            setExceeded(compensation.overriddenNumber > maximum);
        }

        if (!isNullOrEmpty(compensation.overrideReasonRemark)) {
            setRemark(compensation.overrideReasonRemark);
        } else {
            setRemark("");
        }
    }

    async function onSaveClickAsync() {
        if (!canSave) return;

        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.overrideReasonRemark = remark;
        compensationCopy.overriddenNumber = overriddenNumber;
        compensationCopy.overrideReasonType = {
            value: 1,
            systemName: "OTHER",
            nameNor: "Annet"
        }

        const response = await CompensationApiRepository.updatePilotageVariableCompensation(compensationCopy);
        if (response.ok) {
            callback(compensationCopy);
            onClose();
        } else {
            publishErrorNotificationTopic("Noe gikk galt");
        }
        
    }

    function onDescriptionChange(e) {
        setRemark(e.value);
        validate(e.value, overriddenNumber);
    }

    function onOverriddenNumberChange(e) {
        setOverriddenNumber(e.value);
        validate(remark, e.value);
    }

    function validate(text = remark, number) {
        const exceeded = number > maxOverriddenNumber;
        setCanSave(!isNullOrEmpty(text) && !exceeded);
        setExceeded(exceeded);
    }
}