import React, { useEffect, useRef, useState } from 'react';
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
import PilotageApiRepository from '../../../repositories/api/PilotageApiRepository';
import PilotageIdbRepository from '../../../repositories/idb/PilotageIdbRepository';
import DateTimePicker from '../../../components/layout/DateTimePicker';
import { Card } from '../../../components/layout/card/Card';
import Spacer from '../../../components/layout/Spacer';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { Button } from "@progress/kendo-react-buttons";
export default function PilotageDetailLocation({ state: pilotage, isReadOnly }) {

    const componentRef = useRef({
        timer: null
    });
    const { current: localRef } = componentRef;   
    const [toTime, setToTime] = useState(Moment(pilotage.pilotageDetail.fromTime)
        .add(pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes, 'm').format());
    const [disabledSaveButton, setDisabledSaveButton] = useState(true);

    useEffect(() => {
        setToTime(Moment(pilotage.pilotageDetail.fromTime)
            .add(pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes, 'm').format());
    }, [pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes, pilotage.pilotageDetail.fromTime])

    return (
        <Card properties={{
            ...CardProperties,
            title: `${pilotage.fromLocation.name}`
        }}>
            <div className="details-time">
                <div>
                    {Moment(pilotage.pilotageDetail.fromTime).format('DD.MM HH:mm')}
                </div>
                {
                pilotage.fromLocation.hasQuayManualsInProximity &&
                <div>
                    <NavLink to={`/pilot/quaymanual/location/${pilotage.fromLocation.locationId}`}>Kaibok</NavLink>
                </div>
                }     
            </div>
            <div className="details-quay">
                {pilotage.pilotageDetail?.departureQuayInfo}

            </div>
            <Spacer height={15} />
            <div className="details-card-title">
                {`${pilotage.toLocation.name}`}
            </div>
            <div className="details-time">
            {
            (!isReadOnly && navigator.onLine)
                ?
                <>
                    <div className="full">
                        <div>
                            <DateTimePicker
                                title="Velg oppm&oslash;te tid"
                                value={toTime}
                                onChange={onArrivalTimeDateTimePickerChanged}
                                canDelete={false}
                            />
                        </div>
                    </div>
                    <div>
                      <Button onClick={handleClickSaveButton} className="m-l-10" disabled={disabledSaveButton} fillMode="solid" themeColor="primary">
                        Lagre
                      </Button>
                    </div>
                </>
                :
                <div>
                    {Moment(toTime).format('DD.MM HH:mm')}
                </div>
            }
            
            </div>
            <div>
                {pilotage.pilotageDetail?.arrivalQuayInfo}
            </div>
            {(pilotage.toLocation.hasQuayManualsInProximity && navigator.onLine) &&
                <div className="details-quay-link">
                    <NavLink to={`/pilot/quaymanual/location/${pilotage.toLocation.locationId}`}>Kaibok</NavLink>
                </div>
            }
        </Card>
    )

    function onArrivalTimeDateTimePickerChanged(event) {
        clearTimeout(localRef.timer);
        setDisabledSaveButton(false);
        setToTime(event.value);
    }

    async function sendUpdatePilotageDuration(toTime) {
        
        var response = await PilotageApiRepository
            .updatePilotageDuration(pilotage.pilotageDetail.pilotageDurationId, toTime);
        if (response.ok === true) {
            let newDuration = await response.json(); 
            await handleUpdatePilotageDuration(newDuration);
            publishSuccessNotificationTopic(`Estimert oppdragsvarighet ble oppdatert`);
        } else {
            publishWarningNotificationTopic(`Oppdragsvarigheten kan ikke være etter fra dato`);
        }
        setDisabledSaveButton(true);
    }

    function calculateNewEstiamedTime(estimatedDurationInMinutes) {
        const time = Moment(pilotage.pilotageDetail.fromTime)
            .add(estimatedDurationInMinutes, 'm').format();
        setToTime(time);
        return time;
    }

    async function handleUpdatePilotageDuration(estimatedDurationInMinutes) {
        calculateNewEstiamedTime(estimatedDurationInMinutes);
        pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes = estimatedDurationInMinutes;
        await PilotageIdbRepository.setPilotageAsync(pilotage);
    }

    async function handleClickSaveButton() {
        sendUpdatePilotageDuration(toTime);
    }
}