import './styles/my-page.css';

import React, {useState } from 'react';

import { useEffectOnce } from '../../components/useEffectOnce';
import Spacer from '../../components/layout/Spacer';
import MyPageApiRepository from '../../repositories/api/MyPageApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Icons from '../../components/layout/icons/Icons';
import Overlay from '../../components/layout/overlay/Overlay';
import { Card } from '../../components/layout/card/Card';
import { UploadIcon } from '../../components/layout/icons/Icons';
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import Moment from 'moment';
import SettingsBottomMenu from './components/SettingsBottomMenu';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import DateTimePickerFieldWrapper from '../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { IconColors } from '../../services/SystemNames';

function DayNight() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [source, setSource] = useState({});
    const [canSave, setCanSave] = useState(false);

    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);

    // Functions for processing data
    const initializeAsync = async () => {

        publishHeaderTopic("Innstillinger", "Definer dag/natt");

        const response = await MyPageApiRepository.getNoticeTimeSettings();

        if (response.ok === true) {
            const data = await response.json();
            setSource(data);

            const fromTime = Moment();
            fromTime.set('hour', data.startTimeHours);
            fromTime.set('minute', data.startTimeMinutes);
            setFromTime(fromTime.toDate());

            const toTime = Moment();
            toTime.set('hour', data.endTimeHours);
            toTime.set('minute', data.endTimeMinutes);
            setToTime(toTime.toDate());

        } else {
            handleError(response.status);
        }

        setIsBusy(false);
    }

    const saveAsync = async () => {

        setIsBusy(true);

        source.startTimeHours = Number(Moment(fromTime).hour());
        source.startTimeMinutes = Number(Moment(fromTime).minute());
        source.endTimeHours = Number(Moment(toTime).hour());
        source.endTimeMinutes = Number(Moment(toTime).minute());

        source.dayStartTimeInMinutes = (source.startTimeHours * 60) + source.startTimeMinutes;
        source.dayEndTimeInMinutes = (source.endTimeHours * 60) + source.endTimeMinutes
        
        const response = await MyPageApiRepository.setNoticeTimeSettings(source);

        if (response.ok === true) {
            publishSuccessNotificationTopic(`Dag/natt ble lagret.`);
            initializeAsync();
        } else {
            handleError(response.status);
        }
    }

    // Initialize function
    useEffectOnce(() => {
        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ContentGrid title="Definer dag/natt" titleIcon="settings" fullSize={false}>
                <Card>
                    <Spacer height={15} />

                    <div className="container p-b-5">
                        <div className="row">
                            <div className="col-2 col-sm-1">
                                <Icons iconName="day" color={IconColors.Primary} />
                            </div>
                            <div className="my-page-settings-title col-10 col-sm-11">
                                Dag
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="p-b-10 col-6">
                                <DateTimePickerFieldWrapper
                                    title="Fra"
                                    value={fromTime}
                                    onChange={onDayFromTimeChanged}
                                    canDelete={false}
                                />

                            </div>
                            <div className="col-6">
                                <DateTimePickerFieldWrapper
                                    title="Til"
                                    value={toTime}
                                    onChange={onDayToTimeChanged}
                                    canDelete={false}
                                />
                            </div>
                        </div>
                    </div>
                    <Spacer height={20} />
                    <div className="container p-b-5">
                        <div className="row">
                            <div className="col-2 col-sm-1">
                                <Icons iconName="night" color={IconColors.Primary} />
                            </div>
                            <div className="my-page-settings-title col-10 col-sm-11">
                                Natt
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="p-b-10 col-6">
                                <DateTimePickerFieldWrapper
                                    title="Fra"
                                    value={toTime}
                                    onChange={onNightFromTimeChanged}
                                    canDelete={false}
                                />
                            </div>
                            <div className="col-6">
                                <DateTimePickerFieldWrapper
                                    title="Til"
                                    value={fromTime}
                                    onChange={onNightToTimeChanged}
                                    canDelete={false}
                                />
                            </div>
                        </div>
                    </div>
                    <Spacer height={15} />
                </Card>
            </ContentGrid>

            <FloatingButtonContainer hasBottomNavigation={true}>
                <FloatingActionButton
                    themeColor={canSave ? "primary" : "secondary"}
                    disabled={!canSave}
                    svgIcon={UploadIcon}
                    onClick={onSaveClickAsync}
                />
            </FloatingButtonContainer>

            <SettingsBottomMenu index={3} />

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>

            
    );

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }
    
    async function onSaveClickAsync() {
        await saveAsync();
    }

    function onDayFromTimeChanged(e) {
        setFromTime(e.value);
        setCanSave(true);
    }

    function onDayToTimeChanged(e) {
        setToTime(e.value);
        setCanSave(true);
    }

    function onNightFromTimeChanged(e) {
        setToTime(e.value);
        setCanSave(true);
    }

    function onNightToTimeChanged(e) {
        setFromTime(e.value);
        setCanSave(true);
    }

    function handleError(response) {
        publishWarningNotificationTopic("En feil oppstod ved definering av dag/natt.", response.status);
        setIsBusy(false);
        setHasErrors(true);
    }
}

export default DayNight;