import { useEffect, useRef } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import Moment from 'moment';
import { isObjectNull } from '../../../helpers/ObjectHelpers';
import { getChangesWithinBounds } from '../GoogleMapHelpers';


export const MapListeners = ({
    properties,
    onBoundsChanged,
    onZoomChanged,
    onDragEnd,
    onResize,
    setToggleMarkerInfoWindows
}) => {

    const map = useMap();  

    const componentRef = useRef({
        hasBoundsChanged: false,
        skipBoundsChanged: false,
        boundsChagnedTimeout: null
    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        if (!map) return;

        function handleBoundsChanged() {
            
            if (localRef.skipBoundsChanged) return;
            if (!isObjectNull(localRef.boundsChagnedTimeout)) {
                clearTimeout(localRef.boundsChagnedTimeout);
            }
            onBoundsChanged();

            localRef.boundsChagnedTimeout = setTimeout(() => {
                if (!localRef.hasBoundsChanged) {
                    properties.handleOnBoundsChanged(getChanges());
                    localRef.hasBoundsChanged = true;
                } else {
                    properties.handleOnDragEnd(getChanges());
                }
            }, 750);
        }

        function handleDragStart() {
            setToggleMarkerInfoWindows(Moment());
            localRef.skipBoundsChanged = true;
        }

        function handleDragEnd() {
            onDragEnd();
            properties.handleOnDragEnd(getChanges());
            localRef.skipBoundsChanged = true;
        }

        function handleZoomChanged() {
            onZoomChanged();
            properties.handleOnZoomChanged(getChanges());
        }

        function handleResize() {
            onResize();
            properties.handleOnZoomChanged(getChanges());
        }

        function getChanges() {
            return getChangesWithinBounds(map, map.data);
        }

        //window.google.maps.event.addListenerOnce(map, "tilesloaded", onTilesLoaded);

        map.addListener("bounds_changed", handleBoundsChanged);
        map.addListener("dragstart", handleDragStart);
        map.addListener("dragend", handleDragEnd);
        map.addListener("zoom_changed", handleZoomChanged);
        map.addListener("resize", handleResize);
        
        return (() => {
            window.google.maps.event.clearListeners(map, "bounds_changed");
            window.google.maps.event.clearListeners(map, "dragend");
            window.google.maps.event.clearListeners(map, "zoom_changed");
            window.google.maps.event.clearListeners(map, "resize");
        });

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

}