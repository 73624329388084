import React, { useEffect, useState } from 'react';
import SearchTextBoxFieldWrapper from '../../components/layout/fieldWrappers/SearchTextBoxFieldWrapper';
import Spacer from '../../components/layout/Spacer';
import SearchedQuayLocation from './SearchedQuayLocations';
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import { isEmptyOrSpaces, isNullOrEmpty } from '../../components/helpers/ObjectHelpers';

const SearchQuayLocation = ({setHasSearched }) => {
    const [searchText, setSearchText] = useState("");
    const [searchLocations, setSearchLocations] = useState([]);

    useEffect(() => {
        const getData = setTimeout(() => {
            onSearchLocationsAsync(searchText);
        }, 500)

        return () => clearTimeout(getData)
    }, [searchText]) 
    return (
        <>
            <SearchTextBoxFieldWrapper
                title="S&oslash;k etter kaibok"
                value={searchText}
                placeholder="Lokasjon"
                onChange={onSearchTextChange}
            />
            <Spacer height={20} />
            {searchText &&
                <h2>S&oslash;keresultat</h2>
            }
            {searchLocations &&
                searchLocations.map(item => (
                    <SearchedQuayLocation key={item.locationId} searchedLocation={item} />
                ))
            }        
        </>
    );

    function onSearchTextChange(e) {
        setSearchText(e.value);
    }

    async function onSearchLocationsAsync(searchText) {
        if (isEmptyOrSpaces(searchText)) {
            setSearchLocations(null);
            return;
        }

        const result = await QuayManualApiRepository.searchQuayManuals(searchText.toString());
        if (result.ok) {
            const quayHarbours = await result.json();
            const locations = [];

            for (var location of quayHarbours) {
                locations.push({
                    name: location.name,
                    locationId: location.locationId,
                    documents: location.documents
                });
            }
            setSearchLocations(locations);
            setHasSearched(!isNullOrEmpty(searchText));
        } else {
            setSearchLocations(null);
            setHasSearched(false);
            return;
        }
    } 
};

export default SearchQuayLocation;