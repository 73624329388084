import React from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { useNavigate } from 'react-router-dom';

export const NoticeTimeBottomNavigation = ({ id = 1 }) => {

    const navigate = useNavigate();
    return (
        <BottomNavigation
            onCallback={onClick}
            selectedAction={id}
            items={
                [
                    {
                        icon: "information",
                        text: "Generell",
                        action: 1,
                        url: "general"
                    },
                    {
                        icon: "pin",
                        text: "Lokasjon",
                        action: 2,
                        url: "locations"
                    },
                    {
                        icon: "alarmClock",
                        text: "Overstyrt",
                        action: 3,
                        url: "overridden"
                    },
                ]
            }
        />
    );

    function onClick(item) {
        navigate(`/noticetime/${item.url}`);
    }
}
