import React, { useEffect, useState } from 'react';
import "./pilot-station.css"
import StationList from './StationList';
import FavouriteStations from '../../components/layout/favourite_stations/FavouriteStations';
import BottomNavigation from '../../components/layout/BottomNavigation';
import ContentGridContainer from '../../components/layout/ContentGrid';
import { publishHeaderTopic } from '../../components/helpers/PubSubHelpers';

const PilotStations = () => {

    const [view, setView] = useState("list");

    const items = [
        {
            text: "Losoversikt",
            icon: "bulletList",
            action: "list"
        },
        {
            text: "Stasjoner",
            icon: "star",
            action: "favourites"
        },
    ];

    useEffect(() => {
        publishHeaderTopic("Losoversikt");
    }, []);

    return (
        <>
            {(() => {
                switch (view) {
                    case "list":
                        return (
                            <div className="pilot-stations-view">
                                <div className="pilot-stations-view-content">
                                    {view === "list" &&
                                        <div className="pilot-stations">
                                            <StationList />
                                        </div>
                                    }
                                    {view === "favourites" &&
                                        <ContentGridContainer title="Stasjoner" titleIcon="star" fullSize={false}>
                                            <FavouriteStations />
                                        </ContentGridContainer>
                                    }
                                </div>


                            </div>
                        );
                    case "favourites":
                        return (
                            <ContentGridContainer title="Stasjoner" titleIcon="star" fullSize={false}>
                                <FavouriteStations />
                            </ContentGridContainer>
                        );
                    default:
                        return null;
                }
            })()}
            <BottomNavigation
                items={items}
                selectedAction={view}
                onCallback={onSelect}
            />
        </>
    );

    function onSelect(item) {
        setView(item.action);
    }
};

export default PilotStations;