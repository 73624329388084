import React, { useEffect, useState } from 'react';
import PilotageTransportOrder from './PilotageDetailPilotageTransportOrder';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { isPilotAssignmentConfirmed } from '../pilot-assignment/helpers/PilotAssignmentHelpers';
import Moment from 'moment';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';

export default function PilotageDetailsTransportOrders({ pilotageId, handleUpdate }) {

    const [data, setData] = useState(null);
    const [pilotAssignmentId, setPilotAssignmentId] = useState(null);
    const [pilotAssignment, setPilotAssignemnt] = useState(null);

    useEffect(() => {
        const getPilotagePilotFromLocal = async () => {
            const result = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
            if (isNullOrEmpty(result)) {
                const readOnlyResult = await PilotApiRepository.getReadOnlyPilotageByIdAsync(pilotageId);
                const readOnlyPilotage = await readOnlyResult.json();
                setData(readOnlyPilotage);
            } else {
                setData(result.pilotage);
                setPilotAssignmentId(result.pilotagePilotId);
                setPilotAssignemnt(result);
            }       
        }
        getPilotagePilotFromLocal();

    }, [pilotageId, handleUpdate])

    return (
        <>
        {
                data && !isNullOrEmpty(data.pilotageDetail.pilotTransportOrders) &&
                <Card properties={{
                        ...CardProperties,
                        title: "Tilbringerordre"
                } }>
                        <div>
                            {data.pilotageDetail.pilotTransportOrders.map((transportOrder, index) =>
                                <div key={index} className="details-transportorder-border">
                                    <PilotageTransportOrder
                                        state={transportOrder}
                                        pilotAssignmentId={pilotAssignmentId}
                                        onReload={handleUpdatePilotTransport}
                                        index={index}
                                        pilotIsConfirmedAndOnline={isEditPilotageDetailsActive}
                                        estimatedStart={
                                            Moment(data.pilotageDetail.fromTime)
                                            .add(data.pilotageDetail.pilotageEstimatedDurationInMinutes, 'm')
                                            .format("yyyy-MM-DDTHH:mm")}
                                    />
                                </div>
                            )}
                        </div>
                    </Card>
        }
        </>
    );

    async function isEditPilotageDetailsActive() {
        if (isNullOrEmpty(pilotAssignmentId)) return false;
        return (isPilotAssignmentConfirmed(pilotAssignment) && navigator.onLine);

    }

    async function handleUpdatePilotTransport(index, updatedPilotTransportOrder) {
        let pilotTransportOrder = pilotAssignment.pilotage.pilotageDetail.pilotTransportOrders[index];
        pilotTransportOrder = updatedPilotTransportOrder;
        pilotAssignment.pilotage.pilotageDetail.pilotTransportOrders[index] = pilotTransportOrder;
        handleUpdate(pilotAssignment);
    }
}
