import React, { useEffect, useRef, useState } from 'react';
import {
    AdvancedMarker,
    useAdvancedMarkerRef,
    InfoWindow,
} from '@vis.gl/react-google-maps';
import { IconColors } from '../../../../services/SystemNames';
import uuid from 'react-uuid';
import DmsCoordinates  from "dms-conversion";
import parse from 'html-react-parser';
import { PilotageDetailsDialog } from './dialogs/PilotageDetailsDialog';
import Spacer from '../../Spacer';
import { isBrowser, isMobile } from 'react-device-detect';
import { isNullOrEmpty, isNumeric, isObjectNull } from '../../../helpers/ObjectHelpers';
import { hideOnImageError } from '../../../helpers/ElementHelpers';
import { MarkerTypes, ShipTypes, ShipTypeGroups, markersVisibleAtZoom, minimumSpeed, getShipTypeDescription } from '../GoogleMapHelpers';
import { NavigationArrow, Square, Circle, Triangle, MapPin } from "phosphor-react-sc";

export const MapMarker = ({
    marker,
    toggleMarkerInfoWindows,
    setToggleMarkerInfoWindows,
    currentZoom = 0
}) => {

    const defaultIsVisible = currentZoom === 0 ? true : currentZoom >= markersVisibleAtZoom;

    const [infowindowOpen, setInfowindowOpen] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isVisible, setIsVisible] = useState(defaultIsVisible);
    const [anchorRef, anchor] = useAdvancedMarkerRef();

    const componentRef = useRef({
        skipToggle: false,
    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        if (isObjectNull(toggleMarkerInfoWindows)) return;

        if (!localRef.skipToggle) {
            setInfowindowOpen(false);
        }
        localRef.skipToggle = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleMarkerInfoWindows]);

    useEffect(() => {

        if (currentZoom === 0) {
            setIsVisible(true);
            return;
        }
        
        const visible = currentZoom >= markersVisibleAtZoom;

        if (visible && isVisible) return;

        setIsVisible(visible);

        if (!visible && infowindowOpen) {
            setInfowindowOpen(false);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentZoom]);

    return (
        isVisible &&
            <>
                <AdvancedMarker
                    className={getClassName()}
                    zIndex={getZIndex()}
                    ref={anchorRef}
                    position={marker.position}
                    onClick={handleOnClick}>
                    {getMarkerContent()}
                </AdvancedMarker>

                {
                    infowindowOpen &&
                    <InfoWindow
                        anchor={anchor}
                        maxWidth={200}
                        headerContent={getInfoWindowHeaderContent()}
                        onCloseClick={() => setInfowindowOpen(false)}>
                        <div>
                            {getInfoWindowContent()}
                            <Spacer height={10}/>
                        </div>
                    </InfoWindow>
            }

            {
                showDialog &&
                <PilotageDetailsDialog marker={marker} onClose={() => setShowDialog(false) } />
            }
            </>
    )

    function getZIndex() {
        if (!marker.hasOwnProperty("topmost")) return 0;
        return marker.topmost ?  1: 0;
    }

    function getClassName() {
        if (marker.hasOwnProperty("shipTypeIdentifier")) return "google-map-ship";
        return "";
    }

    function handleOnClick() {
        const shipId = isNumeric(marker.shipId) ? marker.shipId : 0;
        if (!isNullOrEmpty(marker.description) && shipId > 0 && isBrowser) {
            setShowDialog(true);
        }

        if (infowindowOpen) return;

        setInfowindowOpen(true);
        localRef.skipToggle = true;
        setToggleMarkerInfoWindows(uuid());
    }

    function handleOnMouseOver() {
        setInfowindowOpen(true);
    }

    function handleOnMouseOut() {
        setInfowindowOpen(false);
    }

    function getMarkerContent() {
        switch (marker.type) {
            case MarkerTypes.Ship:
                return getShipMarkerContent();
            case MarkerTypes.Location:
                return getLocationContent();
            default:
                const color = isNullOrEmpty(marker.color) ? IconColors.Pin : marker.color;
                return (
                    <div
                        onMouseOver={handleOnMouseOver}
                        onMouseOut={handleOnMouseOut}>
                        <MapPin weight="fill" color={color} className="svg-icon" size={40}/>
                    </div>
                );
        }
    }

    function getShipMarkerContent() {

        let color = IconColors.Ship;

        if (marker.hasOwnProperty("shipTypeIdentifier")) {
            if (marker.shipTypeIdentifier === ShipTypes.PilotVessel) {
                color = IconColors.ShipPilotVessel;
            } else {
                if (marker.hasOwnProperty("shipTypeGroupIdentifier")) {
                    switch (marker.shipTypeGroupIdentifier) {
                        case ShipTypeGroups.Cargo:
                            color = IconColors.ShipCargo;
                            break;
                        case ShipTypeGroups.Tanker:
                            color = IconColors.ShipTanker;
                            break;
                        case ShipTypeGroups.Passenger:
                            color = IconColors.ShipPassenger;
                            break;
                        case ShipTypeGroups.Fish:
                            color = IconColors.ShipFish;
                            break;
                        case ShipTypeGroups.Tug:
                            color = IconColors.ShipTug;
                            break;
                        case ShipTypeGroups.Auxilliary:
                            color = IconColors.ShipAuxillary;
                            break;
                        default:
                            color = IconColors.ShipDefault;
                            break;
                    }
                } else {
                    color = IconColors.ShipDefault;
                }
            }
        }

        if (marker.speedOverGround <= minimumSpeed) {
            return (
                <div className={`google-map-ship-position google-map-ship-position-moored`} style={{
                    transform: `rotate(45deg)`
                }}
                    onMouseOver={handleOnMouseOver}
                    onMouseOut={handleOnMouseOut}
                >
                    <Square weight="fill" color={color} size={16} className="svg-icon" />
                </div>
            )
        } 

        let size = 24;
        if (marker.hasOwnProperty("length")) {
            if (marker.length < 75) {
                size = 16;
            }
            if (marker.length > 150) {
                size = 32;
            }
        }

        return (
            <div className={`google-map-ship-position google-map-ship-position-moving`} style={{
                transform: `rotate(${marker.courseOverGround + 45}deg)`
            }}
                onMouseOver={handleOnMouseOver}
                onMouseOut={handleOnMouseOut}>
                <NavigationArrow weight="fill" color={color} size={size} className="svg-icon" />
            </div>
        );
    }

    function getLocationContent() {
        return (
            <div
                onMouseOver={handleOnMouseOver}
                onMouseOut={handleOnMouseOut}>
                {
                    (marker.isEligibleForSpecialPilotageFromTimeLockDeadline) ?
                        <Triangle size={16} weight="fill" color={marker.color} style={{ transform: `rotate(180deg)` }} className="svg-icon" />
                        :
                        <Circle size={16} weight="fill" color={marker.color} className="svg-icon" />
                }
            </div>
        );
    }

    function getInfoWindowHeaderContent() {
        switch (marker.type) {
            case MarkerTypes.Ship:
                return `${marker.description} (${marker.callSign})`;
            default:
                return marker.description;
        }
    }

    function getInfoWindowContent() {
        switch (marker.type) {
            case MarkerTypes.Ship:
                return getInfoWindowShipContent()
            default:
                return getDefaultInfoWindowContent()
        }
    }

    function getInfoWindowShipContent() {

        const imageUrl = `https://www.ship-info.com/vessels/${marker.imoNo}.jpg`;
        const speedOverGround = isNumeric(marker.speedOverGround) ? marker.speedOverGround.toFixed(1) : 0;
        const courseOverGround = isNumeric(marker.courseOverGround) ? marker.courseOverGround.toFixed(1) : 0;
        const shipId = isNumeric(marker.shipId) ? marker.shipId : 0;
        
        return (
            <>
                <Spacer height={5}/>
                <div>
                    <img 
                        src={imageUrl}
                        alt={marker.description}
                        width="160"
                        onError={({ currentTarget }) => {
                            hideOnImageError(currentTarget);
                        }}
                    />
                </div>
                <div className="google-map-info-window-text">Fart&oslash;ystype: {getShipTypeDescription(marker)}</div>
                <div className="google-map-info-window-text">COG: {courseOverGround}</div>
                <div className="google-map-info-window-text">SOG: {speedOverGround} knop</div>
                {getLatLngContent()}
                {
                    (shipId === 0) ?
                    <>
                        <Spacer height={10} />
                        <div className="google-map-info-window-text">
                            <b>{parse('Fart&oslash;yinformasjon er ikke registrert i grunnlagsdata')}</b>
                        </div>
                        </>
                        :
                        <>
                            <Spacer height={10} />
                            <div className={`google-map-info-window-text ${isMobile ? "link" : ""}`} onClick={() => {
                                setShowDialog(true);
                            }}>
                                {
                                    isMobile ? "Vis kontaktinformasjon" : parse("Klikk for &aring; se kontaktinformasjon")
                                }

                            </div>
                        </>
                }
            </>
        )
    }

    function getDefaultInfoWindowContent() {
        return (
            getLatLngContent()
        )
    }

    function getLatLngContent() {

        const dmsCoords = new DmsCoordinates(marker.position.lat(), marker.position.lng());
        const { longitude, latitude } = dmsCoords.dmsArrays;

        return (
            <>
                {parseCoordinates("Lengdegrad", longitude)}
                {parseCoordinates("Breddegrad", latitude)}
            </>
        )
    }

    function parseCoordinates(title, coordinate) {
        const [d, m, s, nsew] = coordinate;

        return (
            <div className="google-map-info-window-text">{parse(`${title}: ${d}&#176;${m}'${s.toFixed(0)}"'${getCoordinateDirection(nsew)}`)}</div>
        )
    }

    function getCoordinateDirection(nsew) {
        switch (nsew) {
            case "E":
                return "&Oslash;";
            case "W":
                return "V";
            default:
                return nsew;
        }
    }
}
