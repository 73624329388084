import './card.css'

import React from 'react';
import parse from 'html-react-parser';

export const CardListEmpty = (
    {
        text = ""
    }) => {
        

    return (
        <div className="pp-cards-empty">
            {parse(text)}
        </div>
    );

};