import { isObjectNull } from "./ObjectHelpers";

function getElementById(id) {
    return document.getElementById(id);
}

function addElementListener(id, type, listener) {
    const elem = getElementById(id);
    if (isObjectNull(elem)) return;
    elem.addEventListener(type, listener, false);
}

function removeElementListener(id, type, listener) {
    const elem = getElementById(id);
    if (isObjectNull(elem)) return;
    elem.removeEventListener(type, listener, false);
}

function getElementsByClassName(className) {
    return document.getElementsByClassName(className);
}

function addClassToElementById(id, className) {
    const elem = getElementById(id);
    if (elem === undefined || elem === null) return;

    elem.classList.add(className);
}

function addClassToElementByClassName(id, className) {
    const elems = getElementsByClassName(id);
    if (elems === undefined || elems === null || elems.length === 0) return;
    if (elems[0].classList.contains(className)) return;

    elems[0].classList.add(className);
}

function removeClassFromElementById(id, className) {
    const elem = getElementById(id);
    if (elem === undefined || elem === null) return;
    elem.classList.remove(className);
}

function removeClassFromElementsByClassName(className, removeClassName) {
    const elems = getElementsByClassName(className);
    const arr = [...elems];

    for (var x = 0; x < arr.length; x++) {
        arr[x].classList.remove(removeClassName);
    }
}

function showElement(id) {
    const elem = getElementById(id);
    elem.style.display = 'block';

    return elem;
}

function hideElement(id) {
    const elem = getElementById(id);
    elem.style.display = 'none';
}

function hideOnImageError(currentTarget) {
    currentTarget.onerror = null; // prevent looping
    currentTarget.style.display = "none";
}

function hasScrolledToBottom() {
    const elem = getElementById("appContent");
    const bottom = Math.ceil(elem.offsetHeight + elem.scrollTop) >= elem.scrollHeight - elem.clientHeight;

    return bottom;
}

function scrollElementIntoView(id) {
    const elem = getElementById(id);
    if (elem === null) return;
    elem.scrollIntoView();
}

function scrollCardElementIntoView(id) {
    const cardId = `card-item-${id}`;
    scrollElementIntoView(cardId);
}

function removePdfToolbarWidthState(parentElem, state) {
    parentElem.classList.remove(`pdf-toolbar-width-state-${state}`);
}

function setPdfToolbarWidthState(parentElem, state) {
    parentElem.classList.add(`pdf-toolbar-width-state-${state}`);
}

function addAppContentFullSize(){
    addClassToElementByClassName("appContent", "appContentFullSize");
}

function removeAppContentFullSize() {
    removeClassFromElementsByClassName("appContent", "appContentFullSize");
}

function getValidationErrorClassName(hasValidationError) {
    if (!hasValidationError) return '';
    return 'validation-error-text';
}

function togglePdfToolbarWidth() {
    setTimeout(function () {

        const elems = getElementsByClassName('pdf-toolbar');
        if (elems.length === 0) return;

        const parentElem = elems[0].parentElement;

        const navMenuMaximizedElem = getElementById('navMenuMaximized');
        const navMenuMinimizedElem = getElementById('navMenuMinimized');

        removePdfToolbarWidthState(parentElem, 1);
        removePdfToolbarWidthState(parentElem, 2);
        removePdfToolbarWidthState(parentElem, 3);

        if (navMenuMaximizedElem !== null) {
            setPdfToolbarWidthState(parentElem, 1);
        } else if (navMenuMinimizedElem !== null) {
            setPdfToolbarWidthState(parentElem, 2);
        } else {
            setPdfToolbarWidthState(parentElem, 3);
        }
    }, 125);
}

function getDisabledStyle(disabled) {
    return {
        opacity: (disabled ? 0.7 : 1)
    };
}

export {
    getElementById,
    addElementListener,
    removeElementListener,
    getElementsByClassName,
    addClassToElementById,
    addClassToElementByClassName,
    removeClassFromElementById,
    removeClassFromElementsByClassName,
    showElement,
    hideElement,
    hideOnImageError,
    hasScrolledToBottom,
    scrollElementIntoView,
    scrollCardElementIntoView,
    addAppContentFullSize,
    removeAppContentFullSize,
    getValidationErrorClassName,
    togglePdfToolbarWidth,
    getDisabledStyle
}