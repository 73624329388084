import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LocationCard } from './LocationCard';
import PubSub from 'pubsub-js';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import PilotBoardingTypeIdbRepository from '../../../../repositories/idb/PilotBoardingTypeIdbRepository';
import PilotagePilotTypeIdbRepository from '../../../../repositories/idb/PilotagePilotTypeIdbRepository';
import Spacer from '../../../../components/layout/Spacer';
import { LocationSearchDialog } from '../../../../components/layout/dialogs/LocationSearchDialog';
import { DurationCard } from './DurationCard';
import Slide from '../../../../components/layout/Slide';
import { getLastElement, isNullOrEmpty, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import { getFormattedDateTime } from '../../../../components/helpers/DateTimeHelpers';
import { addAddLocationCommand, addDeleteLocationCommand, addUpdateLocationArrivalTimeCommand, addUpdateLocationBoardingTypeCommand, addUpdateLocationDepartureTimeCommand, addUpdateLocationPilotTypeCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { getPilotAssignmentAsync, getPilotAssignmentIconColor, getPilotAssignmentLocationBySequenceNo, getPilotAssignmentStatusColor, isPilotAssignmentEditable, sortLocationsBySequenceNo } from '../helpers/PilotAssignmentHelpers';

export const StandardTripCard = forwardRef((
    {
        isConfirmed,
        pilotAssignmentId,
        onLocationChanged,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    const locationsRef = useRef([]);
    const durationRef = useRef();

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync(true);
        },
        async onWastedTripChanged(isWasted) {
            setShow(!isWasted);
            const pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);
            onLocationChanged(pilotAssignment);
        },
        onValidationChanged(codeStrings) {
            for (const ref of locationsRef.current) {
                ref.onValidationChanged(codeStrings);
            }
        }
    }));

    const [show, setShow] = useState(false);
    const [pilotAssignment, setPilotAssignment] = useState({ locations: [] });
    const [isEditable, setIsEditable] = useState(false);
    const [showSearchDialog, setShowSearchDialog] = useState(false);
    const [locationBefore, setLocationBefore] = useState(null);
    const [locationAfter, setLocationAfter] = useState(null);

    useEffect(() => {

        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)

        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
        
    }, []);

    return (
        <>
            <Slide show={show}>
                <div className={`locations`}>
                    {
                        sortLocationsBySequenceNo(pilotAssignment.locations)
                            .map((location, index) => {
                                const isFirst = index === 0;
                                const isLast = index === pilotAssignment.locations.length - 1;
                                const key = `${index}-${location.locationId}`;

                                return (<LocationCard
                                    key={key}
                                    ref={(el) => locationsRef.current[index] = el}
                                    isEditable={isEditable}
                                    isFirst={isFirst}
                                    isLast={isLast}
                                    isConfirmed={isConfirmed}
                                    locations={pilotAssignment.locations}
                                    location={location}
                                    sourcePilotAssignment={pilotAssignment}
                                    handleCommands={handleCommands}
                                    iconColor={getPilotAssignmentIconColor(pilotAssignment)}
                                    borderColor={getPilotAssignmentStatusColor(pilotAssignment)}
                                />);
                            }
                            )
                    }
                    <Spacer height={30} />
                    <DurationCard
                        ref={durationRef}
                        locations={pilotAssignment.locations}
                        isEditable={isEditable}
                        borderColor={getPilotAssignmentStatusColor(pilotAssignment)}
                    />

                </div>

                <Spacer height={30} />
        </Slide >

            {
                showSearchDialog &&
                <LocationSearchDialog
                    onClose={() => setShowSearchDialog(false)}
                    callback={onSearchLocationsCallbackAsync}
                    locationBefore={locationBefore}
                    locationAfter={locationAfter}
                />
            }
        </>
    )

    async function setAndPublishAsync(pilotAssignment) {
        await onUpdatePilotAssignmentAsync(pilotAssignment);
        setPilotAssignment(pilotAssignment);
        
        onLocationsChanged(pilotAssignment);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function initializeAsync(notifyLocations = false) {
        const pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);
        setPilotAssignment(pilotAssignment);
        setShow(!pilotAssignment.isWastedTrip);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));

        if (!notifyLocations) return;

        onLocationsChanged(pilotAssignment);
    }

    function onLocationsChanged(pilotAssignment) {
        if (!isObjectNull(durationRef.current)) {
            durationRef.current.onLocationsChanged();
        }

        for (const ref of locationsRef.current) {
            if (isNullOrEmpty(ref)) continue;
             ref.onLocationsChanged(pilotAssignment);
        }
    }

    function handleCommands(command, obj) {
        switch (command) {
            case PilotAssignmentCommands.SearchLocations:
                onSearchLocations(obj);
                break;

            case PilotAssignmentCommands.DeleteLocation:
                onDeleteLocationAsync(obj);
                break;

            case PilotAssignmentCommands.UpdateLocationDepartureTime:
                onUpdateLocationDepartureTimeAsync(obj);
                break;

            case PilotAssignmentCommands.UpdateLocationArrivalTime:
                onUpdateLocationArrivalTimeAsync(obj);
                break;

            case PilotAssignmentCommands.UpdateLocationPilotType:
                onUpdateLocationPilotTypeAsync(obj);
                break;

            case PilotAssignmentCommands.UpdateLocationBoardingType:
                onUpdateLocationBoardingTypeAsync(obj);
                break;
            default:
                break;
        }
    }

    function onSearchLocations(obj) {
        const locationBefore = getPilotAssignmentLocationBySequenceNo(pilotAssignment.locations, obj.sequenceNo - 1);

        setLocationBefore(locationBefore);
        setLocationAfter(obj);

        setShowSearchDialog(true);
    }

    async function onSearchLocationsCallbackAsync(obj, objBefore) {

        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);

        const locations = pilotAssignment.locations;
        const sequenceNo = objBefore.sequenceNo;
        const locationBefore = getPilotAssignmentLocationBySequenceNo(locations, sequenceNo);

        for (var i = 0; i < locations.length; i++) {
            const location = locations[i];
            if (location.sequenceNo <= sequenceNo) continue;
            location.sequenceNo += 1;
        }

        const boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();
        let pilotType = null; 

        let newLocation = {
            locationId: obj.locationId,
            name: obj.name,
            sequenceNo: sequenceNo + 1,
            canBeDeleted: true,
            fromTime: null,
            toTime: null,
            isPilotBoardingTypeOffBoarding: true,
            pilotBoardingType: boardingType,
            pilotTypeId: 0,
            pilotType: null
        };

        const isMoreThanOnePilotRequired = pilotAssignment.pilotage.isMoreThanOnePilotRequired;
        let pilotTypeSystemName = null;
        
        if (isMoreThanOnePilotRequired) {
            pilotType = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("PARALLEL_PILOTING");
        } else {
            pilotType = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("SEQUENTIAL_PILOTING");
        }
        
        newLocation.pilotTypeId = pilotType.pilotagePilotTypeId;
        newLocation.pilotType = pilotType.pilotType;
        pilotTypeSystemName = pilotType.systemName;

        const index = locations.indexOf(locationBefore);
        locations.splice(index + 1, 0, newLocation);

        pilotAssignment = addAddLocationCommand(pilotAssignment, newLocation, boardingType.systemName, pilotTypeSystemName);

        pilotType = await PilotagePilotTypeIdbRepository.getDefaultAsync();
        locationBefore.pilotType = pilotType.pilotType;
        locationBefore.pilotTypeId = pilotType.pilotagePilotTypeId;

        pilotAssignment = addUpdateLocationPilotTypeCommand(pilotAssignment, {
            location: locationBefore,
            pilotType: pilotType
        });

        await setAndPublishAsync(pilotAssignment);
        
        onLocationChanged();
    }

    async function onDeleteLocationAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);
        const locations = [...pilotAssignment.locations];

        const filteredLocations = locations.filter(l => l.sequenceNo !== obj.sequenceNo);

        for (var i = 0; i < filteredLocations.length; i++) {
            const filteredLocation = filteredLocations[i];
            if (filteredLocation.sequenceNo < obj.sequenceNo) continue;
            filteredLocation.sequenceNo -= 1;
        }

        pilotAssignment.locations = filteredLocations;

        pilotAssignment = addDeleteLocationCommand(pilotAssignment, obj);

        await setAndPublishAsync(pilotAssignment);
        onLocationChanged();
    }

    async function onUpdateLocationDepartureTimeAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);
        const location = getPilotAssignmentLocationBySequenceNo(pilotAssignment.locations, obj.location.sequenceNo);
        location.fromTime = getFormattedDateTime(obj.departureTime);

        pilotAssignment = addUpdateLocationDepartureTimeCommand(pilotAssignment, obj);

        if (isNullOrEmpty(obj.departureTime) && obj.location.sequenceNo === 1) {

            const boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();
            boardingType.isPilotBoardingTypeOffBoarding = false;

            location.pilotBoardingType = boardingType;
            location.isPilotBoardingTypeOffBoarding = boardingType.isPilotBoardingTypeOffBoarding;

            pilotAssignment = addUpdateLocationBoardingTypeCommand(pilotAssignment,
                {
                    location: location,
                    boardingType: boardingType
                });
        }

        await setAndPublishAsync(pilotAssignment);

        onLocationChanged();
    }

    async function onUpdateLocationArrivalTimeAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);
        const location = getPilotAssignmentLocationBySequenceNo(pilotAssignment.locations, obj.location.sequenceNo);
        location.toTime = getFormattedDateTime(obj.arrivalTime);

        pilotAssignment = addUpdateLocationArrivalTimeCommand(pilotAssignment, obj);

        if (isNullOrEmpty(obj.arrivalTime)) {

            const lastLocation = getLastElement(pilotAssignment.locations);

            if (obj.location.sequenceNo === lastLocation.sequenceNo) {

                const boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();
                boardingType.isPilotBoardingTypeOffBoarding = true;

                location.pilotBoardingType = boardingType;
                location.isPilotBoardingTypeOffBoarding = boardingType.isPilotBoardingTypeOffBoarding;

                pilotAssignment = addUpdateLocationBoardingTypeCommand(pilotAssignment,
                    {
                        location: location,
                        boardingType: boardingType
                    });
            }
        }

        await setAndPublishAsync(pilotAssignment);

        onLocationChanged();
    }

    async function onUpdateLocationPilotTypeAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);

        const location = getPilotAssignmentLocationBySequenceNo(pilotAssignment.locations, obj.location.sequenceNo);
        location.pilotType = obj.pilotType.pilotType;
        location.pilotTypeId = obj.pilotType.pilotagePilotTypeId;
        
        pilotAssignment = addUpdateLocationPilotTypeCommand(pilotAssignment, obj);

        await setAndPublishAsync(pilotAssignment);
    }

    async function onUpdateLocationBoardingTypeAsync(obj) {
        
        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);

        const location = getPilotAssignmentLocationBySequenceNo(pilotAssignment.locations, obj.location.sequenceNo);
        location.pilotBoardingType = obj.boardingType;
        location.isPilotBoardingTypeOffBoarding = obj.boardingType.isPilotBoardingTypeOffBoarding;

        pilotAssignment = addUpdateLocationBoardingTypeCommand(pilotAssignment, obj);

        await setAndPublishAsync(pilotAssignment);
        onLocationChanged();
    }
})