import React from 'react';

export const CardContainer = ({
    children,
    properties = {},
    swipeHandlers }) => {

    return (
        (properties.isSwipeEnabled) ?
            <div className="card-container" {...swipeHandlers}> {children}</div >
            :
            <div className="card-container">{children}</div>

    )
}