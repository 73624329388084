import React, { useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import PilotageApiRepository from '../../../repositories/api/PilotageApiRepository';
import Moment from 'moment';
import { MiniCard } from '../../../components/layout/card/MiniCard';
import Spacer from '../../../components/layout/Spacer';
import { isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { getPilotAssignmentTransportOrderBorderColor } from '../pilot-assignment/helpers/PilotAssignmentHelpers';
import DateTimePicker from '../../../components/layout/DateTimePicker';
import DateTimePickerFieldWrapper from '../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';

const PilotageTransportOrder = ({
    state: pilotTransportOrder,
    pilotAssignmentId,
    onReload,
    index,
    pilotIsConfirmedAndOnline,
    estimatedStart
}) => {

    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [transportDate, setTransportDate] = useState(estimatedStart);
    const [orderButtonActivated, setOrderButtonActivated] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const timeType = pilotTransportOrder.pilotTransportOrderTimeType.value === "PICKUP_TIME" ? "Henting" : "Levering";

    return (
        <div>
            <div className="row">
                <div className="col-auto">
                    <MiniCard
                        item={{
                            borderColor: getPilotAssignmentTransportOrderBorderColor(pilotTransportOrder.pilotTransportOrderStatus.systemName),
                            text: `${timeType} - ${pilotTransportOrder.pilotTransportOrderStatus.nameNor.toLowerCase()}`
                        }}
                    />
                </div>
            </div>
            <Spacer height={15} />
            <div className="details-transportorder-location m-b-8">
                <div className="m-b-8">
                    {`Fra: ${pilotTransportOrder.pilotPickupLocation.name}`}
                </div>
                <div>
                    {`Til: ${pilotTransportOrder.pilotDeliverLocation.name}`}
                </div>
            </div>
            {
                RenderOrderTime()
            }
            {!editTransportTimeIsDisabled() && canPilotOrderPilotTransportOrder() &&
                <div className="details-transportorder-warning-time m-b-5">
                    {`Varslingstid er ${pilotTransportOrder.minimumMinutesBeforeToSend} minutter ${transportMessageSendtTimeText()}`}
                </div>
            }
        </div>
    )

    function RenderOrderTime() {
        if (pilotTransportOrder.pilotTransportOrderTimeType.value === "DELIVER_TIME" && pilotTransportOrder.pilotTransportOrderStatus.value === "ALLOCATED") {
            return (<></>);
        }
        if (pilotIsConfirmedAndOnline() && canPilotOrderPilotTransportOrder() && !editTransportTimeIsDisabled()) {
            return (
                <div className="details-transportorder-order">
                    <DateTimePicker
                        title="Tidspunkt"
                        value={transportDate}
                        onChange={(e) => onDateChange(e)}
                        disabled={editTransportTimeIsDisabled() || isBusy}
                        canDelete={false}
                    />
                    <Button
                        onClick={isOrdering() ? onOrderPilotTransportOrder : onUpdatePilotTransportOrder}
                        themeColor="primary"
                        disabled={!orderButtonActivated && isBusy}>
                        {isOrdering() ? "Bestill" : "Oppdater"}
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="details-transportorder-time">
                    {isNullOrEmpty(transportDate) ? `${pilotTransportOrder.pilotTransportOrderTimeType.nameNor}: ...` : `${pilotTransportOrder.pilotTransportOrderTimeType.nameNor}: ${Moment(transportDate).format('HH:mm')}`}
                </div>
            )
        }
    }

    function isOrdering() {
        return pilotTransportOrder.pilotTransportOrderStatus.value !== "ORDERED";
    }

    // should determine if one can order a pilot transport order
    function canPilotOrderPilotTransportOrder() {
        return pilotTransportOrder.pilotTransportOrderType.value === "BY_PILOT"
            && pilotTransportOrder.pilotTransportOrderTimeType.value === "PICKUP_TIME"
            && (pilotTransportOrder.pilotTransportOrderStatus.value === "ALLOCATED" || pilotTransportOrder.pilotTransportOrderStatus.value === "ORDERED");
    }

    function editTransportTimeIsDisabled() {
        return pilotTransportOrder.transportOrderMessageSendt || !navigator.onLine;
    }

    async function onOrderPilotTransportOrder() {
        setIsBusy(true);
        const response = await PilotageApiRepository.orderPilotTransportOrder(pilotAssignmentId, pilotTransportOrder.pilotTransportOrderId, transportDate);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Tilbringerordren ble bestilt`);
            setOrderButtonActivated(!orderButtonActivated);
            if (getTransportMessageSendtTime() <= new Date()) {
                pilotTransportOrder.transportOrderMessageSendt = true;
            }
            pilotTransportOrder.pilotTransportOrderStatus = {
                nameNor: 'Bestilt',
                systemName: 'ORDERED',
                value: 1
            };
            pilotTransportOrder.pilotTransportOrderTime = transportDate;
            onReload(index, pilotTransportOrder);
        } else {
            publishWarningNotificationTopic(`Kunne ikke bestille tilbringerordren`);
        }
        setIsBusy(false);
    }

    async function onUpdatePilotTransportOrder() {
        setIsBusy(true);
        const response = await PilotageApiRepository.updatePilotTransportOrder(pilotAssignmentId, pilotTransportOrder.pilotTransportOrderId, transportDate);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Tilbringerordren sitt tidspunkt ble oppdatert`);
            setOrderButtonActivated(!orderButtonActivated);
            if (getTransportMessageSendtTime() <= new Date()) {
                pilotTransportOrder.transportOrderMessageSendt = true;
            }
            pilotTransportOrder.pilotTransportOrderTime = transportDate;
            onReload(index, pilotTransportOrder);
        } else {
            publishWarningNotificationTopic(`Kunne ikke oppdatere tidspunktet på tilbringerordren`);
        }
        setIsBusy(false);
    }

    // Contains work-around for adaptive mode for timePicker. Accepted by component developers. 
    function onDateChange(e) {
        let newDate = null;

        if (!isObjectNull(e.value)) {
            newDate = Moment(e.value);
        }

        var finalizedDate = Moment();
        if (newDate.format("HH:mm") !== Moment(transportDate).format("HH:mm")) {
            setOrderButtonActivated(true);
        }
        if (newDate.format('HH:mm') < finalizedDate.format('HH:mm')) {
            finalizedDate.add(1, 'day');
        }

        finalizedDate.set('hour', newDate.get('hour'));
        finalizedDate.set('minute', newDate.get('minute'));
        
        setTransportDate(new Date(finalizedDate.format()));
        setShowDateTimePicker(!showDateTimePicker);
    }

    function transportMessageSendtTimeText() {
        const transportMessageSendtTime = Moment(getTransportMessageSendtTime());
        if (transportMessageSendtTime.isValid() && (getTransportMessageSendtTime() > new Date())) {
            return `(bestillingen sendes ${transportMessageSendtTime.format('HH:mm')})`
        } else {
            return "";
        }
    }

    function getTransportMessageSendtTime() {
        var messageSendtDate = Moment(transportDate)
            .subtract(pilotTransportOrder.minimumMinutesBeforeToSend, "minutes");
        return messageSendtDate;
    }
}

export default PilotageTransportOrder;
