import './moduleGrid.css'

import React from 'react';

export default function ModulesGrid(
    {
        children,
    }
) {
    return (
        <div className="modules-grid">
            <div className="modules">
                {children}
            </div>
        </div>
    );
}
