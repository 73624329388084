
import './styles/pilot-rotation.css';
import React, { useState } from 'react';

import PilotRotationListItem from './components/PilotRotationListItem';
import PilotRotationApiRepository from '../../repositories/api/PilotRotationApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import BottomMenu from './components/BottomMenu';
import { useEffect } from 'react';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';

function Next() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [items, setItems] = useState([]);

    // Initialize function
    useEffect(() => {
        publishHeaderTopic("T&oslash;rn", "Neste vakt");
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ContentGrid
                title="Neste vakt"
                titleIcon="next"
                fullSize={false}
                canPullToRefresh={true}
                onPullToRefresh={initializeAsync}
            >
                <div className="pilot-rotation-list">
                    {items.map((item, index) => (
                        <PilotRotationListItem
                            key={index}
                            row={index + 1}
                            pilotRotationItem={item} />
                    ))}
                </div>
            </ContentGrid>

            <BottomMenu selectedAction={2} />

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {
        const response = await PilotRotationApiRepository.getNextAsync();

        if (response.ok === true) {
            const data = await response.json();
            setItems(data);

            setIsBusy(false);
        } else {
            handleError(response.status);
        }
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }

    function handleError(response) {
        publishWarningNotificationTopic("Kunne ikke hente inn neste vaktliste", response.status);
        setHasErrors(true);
        setIsBusy(false);
    }
}

export default Next;