import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { MapMarker } from './MapMarker';
import SourceApiRepository from '../../../../repositories/api/SourceApiRepository';
import { isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { ShipTypes, createShipPositionInformationObject, hideLoader, markersVisibleAtZoom, showLoader } from '../GoogleMapHelpers';

export const MapAis = forwardRef(({
    properties,
    toggleMarkerInfoWindows,
    toggleBoundsChanged,
    toggleDragEnd,
    toggleZoomChanged,
    toggleResize,
    setToggleMarkerInfoWindows,
}, ref) => {

    useImperativeHandle(ref, () => ({
        onBoundsChanged() {
            if (!map || !properties.isAisActive ||
                localRef.hasInitialized) return;

            setTimeout(() => {
                searchAisAsync();
            }, 250);

            localRef.hasInitialized = true;
        },
        onDragEnd() {
            if (!map || !properties.isAisActive) return;

            searchAisAsync();
        },
        onZoomChanged() {
            if (!map || !properties.isAisActive) return;

            setCurrentZoom(map.getZoom());
            searchAisAsync();
        },
        onResize() {
            if (!map || !properties.isAisActive) return;
            searchAisAsync();
        }
    }));

    const map = useMap();

    const [isBusy, setIsBusy] = useState(false);
    const [shipMarkers, setShipMarkers] = useState([]);
    const [currentZoom, setCurrentZoom] = useState(0);

    const componentRef = useRef({
        hasInitialized: false,
    });
    const { current: localRef } = componentRef;
    
    useEffect(() => {
        if (!map ||
            !properties.isAisActive ||
            localRef.hasInitialized ||
            isNullOrEmpty(toggleBoundsChanged)) return;

        setTimeout(() => {
            searchAisAsync();
        }, 250);

        localRef.hasInitialized = true;
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleBoundsChanged]);

    useEffect(() => {
        if (!map ||
            !properties.isAisActive ||
            isNullOrEmpty(toggleDragEnd)) return;

        searchAisAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleDragEnd]);

    useEffect(() => {
        if (!map ||
            !properties.isAisActive ||
            isNullOrEmpty(toggleZoomChanged)) return;

        setCurrentZoom(map.getZoom());
        searchAisAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleZoomChanged]);

    useEffect(() => {
        if (!map ||
            !properties.isAisActive ||
            isNullOrEmpty(toggleResize)) return;
            
        searchAisAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleResize]);
    

    return (
        shipMarkers.map((marker, index) =>
            <MapMarker
                key={index}
                marker={marker}
                currentZoom={currentZoom}
                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
            />
        )
    )

    async function searchAisAsync() {

        const canSearch = map.getZoom() >= markersVisibleAtZoom;

        if (!canSearch || isBusy) return;

        busy(true);

        const bounds = map.getBounds();
        const southWest = bounds.getSouthWest();
        const nothEast = bounds.getNorthEast();
        const width = nothEast.lat() - southWest.lat();
        const height = nothEast.lng() - southWest.lng();
        const excludeShipId = Number(properties.shipId);

        const response = await SourceApiRepository.getShipPositionsWithinAreaAsync(southWest.lat(), southWest.lng(), width, height);
        if (response.ok) {
            const data = await response.json();
            
            for (const position of data) {
                if (excludeShipId > 0 && position.shipID === excludeShipId) continue;

                const latLng = new window.google.maps.LatLng(position.latitude, position.longitude);
                const existing = shipMarkers.find(s => s.mmsi === position.mmsi);

                if (!isObjectNull(existing)) {
                    existing.position = latLng;
                    existing.courseOverGround = position.courseOverGround;
                    existing.speedOverGround = position.speedOverGround;
                } else {
                    const shipMarker = createShipPositionInformationObject(position, position.shipTypeIdentifier === ShipTypes.PilotVessel);
                    shipMarker.shipTypeGroupIdentifier = position.shipTypeGroupIdentifier;
                    shipMarker.shipTypeIdentifier = position.shipTypeIdentifier;
                    shipMarker.length = position.length;
                    shipMarkers.push(shipMarker);
                }
            }
        }

        busy(false);
    }

    function busy(val) {
        setIsBusy(val);
        if (val) {
            showLoader();
        } else {
            hideLoader();
        }
    }
})
