import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Spacer from '../../../../components/layout/Spacer';
import { PilotActualWorkTimeStateEnum, PilotagePilotVariableCompensationTypeEnums } from '../../../../services/SystemNames';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import Moment from 'moment';
import PilotApiRepository from '../../../../repositories/api/PilotApiRepository';
import { VariableCompensationItem } from './VariableCompensationCard';
import { VariableCompensationBridgeCalculateDialog } from './dialogs/VariableCompensationBridgeCalculateDialog';
import { VariableCompensationBridgeOverrideDialog } from './dialogs/VariableCompensationBridgeOverrideDialog';
import { VariableCompensationBridgeEditDialog } from './dialogs/VariableCompensationBridgeEditDialog';
import { Card } from '../../../../components/layout/card/Card';
import MetaIdbRepository from '../../../../repositories/idb/MetaIdbRepository';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { deepCopyObject, errorsContainsFromObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../../components/helpers/PubSubHelpers';
import { addUpdateVariableCompensationOverriddenNumberCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { getPilotAssignmentFromAndToTime, getPilotAssignmentPilotageEndTime, getPilotAssignmentPilotageStartTime, hasMultiplePilotsConfirmedByPilotDispatcher, isPilotAssignmentCompensationEqual, isPilotAssignmentEditable, isPilotAssignmentExaminerOnly, isPilotAssignmentTrainee } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

const itemAction = {
    Calculate: "Calculate",
    View: "View"
}

const dialogStates = {
    Override: "Override",
    Calculated: "Calculated",
    Edit: "Edit"
}

export const VariableCompensationBridgeCard = forwardRef((
    {
        pilotAssignmentId,
        setIsBusy,
        onUpdatePilotAssignmentAsync
    }
    , ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        async onLocationChanged() {
            initializeAsync();
        },
        onExaminerOnlyChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            setDayHasValidationError(errorsContainsFromObject(codeStrings, {
                BridgeTimeDay: PilotAssignmentErrorCodes.BridgeTimeDay,
            }));
            setNightHasValidationError(errorsContainsFromObject(codeStrings, {
                BridgeTimeNight: PilotAssignmentErrorCodes.BridgeTimeNight,
            }));
        }
    }));

    const [hasDayValidationError, setDayHasValidationError] = useState(false);
    const [hasNightValidationError, setNightHasValidationError] = useState(false);
    const [dialogState, setDialogState] = useState("");
    const [bridgeTimeDay, setBridgeTimeDay] = useState(null);
    const [bridgeTimeNight, setBridgeTimeNight] = useState(null);
    const [currentCompensation, setCurrentCompensation] = useState(null);
    const [pilotAssignment, setPilotAssignment] = useState({ pilotage: { pilotagePilotsInformation: [], fromLocation: {}, toLocation: {} } });
    const [summary, setSummary] = useState({});
    const [dayTime, setDayTime] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [canCalculate, setCanCalculate] = useState(false);
    const [hasCalculated, setHasCalculated] = useState(false);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);
        initializeAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    useEffect(() => {

        if (
            isObjectNull(bridgeTimeDay) ||
            isObjectNull(bridgeTimeNight) ||
            !canCalculate ||
            hasCalculated) return;
        
        onCalculateBridgeTimeFromWorktimeAsync(true);
        setHasCalculated(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bridgeTimeDay, bridgeTimeNight, canCalculate]);

    const InfoRow = ({ info }) => {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {info}
                    </div>
                </div>
                <Spacer height={10} />
            </>
        )
    }

    return (
        <>
            <Card
                properties={{
                    ...CardProperties,
                    hasValidationError: (hasDayValidationError || hasNightValidationError),
                    isDirty: isDirty,
                    title: "Brotillegg",
                    actions: (canCalculate) ?
                        [
                            {
                                type: "items",
                                items: [
                                    {
                                        name: "Beregn brotimer fra arbeidstid",
                                        action: itemAction.Calculate,
                                        onClick: onCalculateBridgeTimeFromWorktimeAsync
                                    },
                                    {
                                        name: "Se utregnet brotid",
                                        action: itemAction.View,
                                        onClick: onShowCalculatedBridgeTimeFromWorktimeAsync
                                    }
                                ]
                            }
                        ] : []
                }}>
                {
                    isPilotAssignmentExaminerOnly(pilotAssignment) &&
                    <InfoRow info="Sensor for farledspr&oslash;ve har ikke rett p&aring; brotillegg." />
                }

                {
                    isPilotAssignmentTrainee(pilotAssignment) &&
                    <InfoRow info="Los p&aring; oppl&aelig;ring har ikke rett p&aring; brotillegg." />
                }

                {
                    (!isPilotAssignmentExaminerOnly(pilotAssignment) && !isPilotAssignmentTrainee(pilotAssignment)) &&
                    <>
                        {
                            hasMultiplePilotsConfirmedByPilotDispatcher(pilotAssignment) &&
                            <InfoRow info="For oppdrag med 2 loser er brotillegg beregnet fra registrert arbeidstid." />
                        }

                        <div className="row">
                            <VariableCompensationItem
                                title="Dag"
                                hasValidationError={hasDayValidationError}
                                isEditable={isEditable}
                                compensation={bridgeTimeDay}
                                onEdit={onBridgeTimeClick}
                                onDelete={onDeleteBridgeTime}
                            />
                            <VariableCompensationItem
                                title="Natt"
                                hasValidationError={hasNightValidationError}
                                isEditable={isEditable}
                                compensation={bridgeTimeNight}
                                onEdit={onBridgeTimeClick}
                                onDelete={onDeleteBridgeTime}
                            />
                        </div>
                    </>
                }

            </Card>

            {
                (() => {
                    switch (dialogState) {

                        case dialogStates.Override:
                            return (
                                <VariableCompensationBridgeOverrideDialog
                                    onCancel={() => setDialogState("")}
                                    onClick={() => {
                                        setDialogState(dialogStates.Edit);

                                    }}
                                />);
                        case dialogStates.Calculated:
                            return (
                                <VariableCompensationBridgeCalculateDialog
                                    onClose={() => setDialogState("")}
                                    pilotagePilot={pilotAssignment}
                                    summary={summary}
                                    dayTime={dayTime}
                                />
                            );

                        case dialogStates.Edit:
                            return (
                                <VariableCompensationBridgeEditDialog
                                    onClose={() => setDialogState("")}
                                    compensation={currentCompensation}
                                    callback={onBridgeTimeCallback}
                                />
                            );

                        default:
                            return null;
                    }
                })()
            }
        </>
    )

    async function getPilotAssignmentAsync() {
        const dto = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        return dto;
    }

    async function setAndPublishAsync(pilotAssignment) {
        await onUpdatePilotAssignmentAsync(pilotAssignment);
        initializeAsync();
    }

    async function initializeAsync() {
        const pilotAssignment = await getPilotAssignmentAsync();
        setPilotAssignment(pilotAssignment);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));

        const calculated = await getCalculateBridgeTimeAsync(pilotAssignment);

        setCompensation(pilotAssignment, setBridgeTimeDay, PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay, calculated.dayTimeMinutes);
        setCompensation(pilotAssignment, setBridgeTimeNight, PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight, calculated.nightTimeMinutes);

        initializeIsDirty(pilotAssignment);

        const canCalculate =
            hasMultiplePilotsConfirmedByPilotDispatcher(pilotAssignment) &&
            navigator.onLine &&
            isPilotAssignmentEditable(pilotAssignment) &&
            !isPilotAssignmentExaminerOnly(pilotAssignment) &&
            !isPilotAssignmentTrainee(pilotAssignment);

        setCanCalculate(canCalculate);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }


    function initializeIsDirty(pilotAssignment) {
        const originalArr = pilotAssignment.original.variableCompensations;
        const updatedArr = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;

        let isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BRIDGE_TIME_DAY");

        if (isEqual) {
            isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BRIDGE_TIME_NIGHT");
        }

        setIsDirty(!isEqual);
    }

    function setCompensation(pilotAssignment, set, systemName, calculatedNumber) {
        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        if (isArrayEmpty(compensations)) return;
        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === systemName);
        if (isObjectNull(compensation)) return;

        compensation.calculatedNumber = (calculatedNumber / 60);     
        set(compensation);
    }

    function onBridgeTimeClick(compensation) {
        if (!isEditable) return;

        setCurrentCompensation(compensation);

        if (pilotAssignment.pilotage.canConvertToPilotIncl) {
            setDialogState(dialogStates.Override);
        } else {
            setDialogState(dialogStates.Edit);
        }
    }

    function onDeleteBridgeTime(compensation) {
        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonRemark = null;
        compensationCopy.overridenNumber = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonTypeId = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonType = null;

        onBridgeTimeCallback(compensationCopy);
    }

    function onBridgeTimeCallback(compensation) {
        
        if (compensation.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay) {
            setBridgeTimeDay(compensation);
        } else {
            setBridgeTimeNight(compensation);
        }

        onUpdateVariableCompensationOverriddenNumberAsync(compensation);
    }

    async function onUpdateVariableCompensationOverriddenNumberAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync();

        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === obj.pilotagePilotVariableCompensationSystemName);
        const index = compensations.indexOf(compensation);

        compensations[index] = obj;

        pilotAssignment = addUpdateVariableCompensationOverriddenNumberCommand(pilotAssignment, obj);

        setAndPublishAsync(pilotAssignment);
    }

    async function onCalculateBridgeTimeFromWorktimeAsync(skipWarning = false) {

        const pilotAssignment = await getPilotAssignmentAsync();
        const fromTime = getPilotAssignmentPilotageStartTime(pilotAssignment);
        const toTime = getPilotAssignmentPilotageEndTime(pilotAssignment);

        if ((isNullOrEmpty(fromTime) || isNullOrEmpty(toTime)) && !skipWarning) {
            publishWarningNotificationTopic("Start og stopp tid m&aring; v&aelig;re utfylt for &aring; beregne brotid fra arbeidstid.");
            return;
        }

        if ((Moment(fromTime) > Moment(toTime)) && !skipWarning) {
            publishWarningNotificationTopic("Start tid m&aring; v&aelig;re f&oslash;r stopp tid.");
            return;
        }

        setIsBusy(true);

        const response = await PilotApiRepository
            .getWorkTimeActualAsync(Moment(fromTime), Moment(toTime));

        if (response.ok && response.status === 200) {
            const dto = await response.json();
            
            if (isObjectNull(dto) ||
                !isNumeric(dto.dayInMinutes) ||
                !isNumeric(dto.nightInMinutes)) {
                if (!skipWarning) {
                    publishWarningNotificationTopic("Kunne ikke beregne brotid.<br/>Har du relevant arbeidstid for oppdraget?");
                }
            } else {
                if (dto.dayInMinutes === 0 && dto.nightInMinutes === 0 && !skipWarning) {
                    publishWarningNotificationTopic("Kunne ikke beregne brotid.<br/>Har du relevant arbeidstid for oppdraget?");
                }

                let day = 0;
                let night = 0;

                if (dto.dayInMinutes > 0) {
                    day = (dto.dayInMinutes / 60);
                }

                if (dto.nightInMinutes > 0) {
                    night = (dto.nightInMinutes / 60);
                }
                
                bridgeTimeDay.calculatedNumber = Number(day.toFixed(2));
                bridgeTimeNight.calculatedNumber = Number(night.toFixed(2));
                
                setBridgeTimeDay(bridgeTimeDay);
                setBridgeTimeNight(bridgeTimeNight);

                const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;

                if (!isArrayEmpty(compensations)) {
                    let compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay);
                    let index = 0;
                    if (!isObjectNull(compensation)) {
                        index = compensations.indexOf(compensation);
                        compensations[index] = bridgeTimeDay;
                    }

                    compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight);
                    if (!isObjectNull(compensation)) {
                        index = compensations.indexOf(compensation);
                        compensations[index] = bridgeTimeNight;
                    }

                    pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations = compensations;

                    await PilotAssignmentIdbRepository.setAsync(pilotAssignment);
                }
            }
        } else {
            if (!skipWarning) {
                publishWarningNotificationTopic("Kunne ikke beregne brotimer fra arbeidstid", response.status);
            }
        }

        setIsBusy(false);
    }

    async function onShowCalculatedBridgeTimeFromWorktimeAsync() {

        setIsBusy(true);

        const pilotAssignment = await getPilotAssignmentAsync();
        const response = await PilotApiRepository.getWorkTimeActualDetailsSummaryAsync(pilotAssignment.pilotage.pilotageId);
        const pilotDayTimeResponse = await PilotApiRepository.getDayTimeAsync();

        if (response.ok && pilotDayTimeResponse.ok) {
            const data = await response.json();
            const pilotDayTimeData = await pilotDayTimeResponse.json();
            switch (data.state) {
                case PilotActualWorkTimeStateEnum.NOT_PILOT:
                    publishWarningNotificationTopic(`Du er ikke registrert som los p&aring; losoppdrag ${data.pilotageNo}.`)
                    break;
                case PilotActualWorkTimeStateEnum.MISSING_DATES:
                    publishWarningNotificationTopic(`For &aring; se utregnet brotid, m&aring; skipperbeviset lagres f&oslash;rst.`)
                    break;
                default:

                    if (isArrayEmpty(data.workTimesDay) && isArrayEmpty(data.workTimesNight)) {
                        publishWarningNotificationTopic("Kunne ikke hente faktisk brotid.<br/>Har du relevant arbeidstid for oppdraget?");
                    } else {
                        setSummary(data);
                        setDayTime(pilotDayTimeData);
                        setDialogState(dialogStates.Calculated);
                    }
                    break;
            }
        } else {
            publishWarningNotificationTopic("Kunne ikke vise utregnet brotid", response.status);
        }

        setIsBusy(false);
    }

    async function getCalculateBridgeTimeAsync(pilotAssignment) {

        if (hasMultiplePilotsConfirmedByPilotDispatcher(pilotAssignment) || pilotAssignment.isTrainee)
            return { dayTimeMinutes: 0, nightTimeMinutes: 0 };

        const dayTime = await MetaIdbRepository.getDayTimeAsync();
        const fromToTime = getPilotAssignmentFromAndToTime(pilotAssignment.locations);

        const dayStartHour = Moment(`2000-01-01T${dayTime.dayStart}`).hour();
        const dayEndHour = Moment(`2000-01-01T${dayTime.dayEnd}`).hour();

        let dayTimeMinutes = 0;
        let nightTimeMinutes = 0;

        if (!isNullOrEmpty(fromToTime.fromTime) && !isNullOrEmpty(fromToTime.toTime)) {

            const diffMinutes = Moment(fromToTime.toTime).diff(Moment(fromToTime.fromTime), 'minutes');
            let from = Moment(fromToTime.fromTime);

            for (let i = 1; i <= diffMinutes; i++) {
                const hour = from.hour();
                if (hour >= dayStartHour && hour < dayEndHour) {
                    dayTimeMinutes += 1;
                } else {
                    nightTimeMinutes += 1;
                }
                from = from.add(1, 'm');
            }

        }
        return {
            dayTimeMinutes: dayTimeMinutes,
            nightTimeMinutes: nightTimeMinutes
        }
    }
})
