import React, { useCallback, useRef } from 'react';
import { PDFViewer as KendoPDFViewer } from "@progress/kendo-react-pdf-viewer";
import './pdfViewer.css'
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { loadMessages } from '@progress/kendo-react-intl';
import nbMessages from '../../nb.json';
import Icons from './icons/Icons';
import { Button } from "@progress/kendo-react-buttons";
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';
import { isObjectNull } from '../helpers/ObjectHelpers';
import { togglePdfToolbarWidth } from '../helpers/ElementHelpers';

loadMessages(nbMessages, 'nb-NO');

export default function PDFViewer(
    {
        data,
        onCloseClick = null,
        className = ""
    }
) {

    const pdfViewerTools = [
        "pager",
        "spacer",
        "zoomInOut",
        "zoom",
        "selection",
        "spacer",
        "search",
        "download",
        "print",
    ];

    const pdfViwerCustomTool = () => {
        return (
            (!isObjectNull(onCloseClick)) &&
                <Button
                    key="Custom"
                    className="pdf-toolbar-close"
                    size="small"
                    themeColor="error"
                    onClick={onCloseClick}>
                    <Icons iconName="close" />
                </Button>
        );
    };

    const resizePdfToolbar = () => {
        togglePdfToolbarWidth();
    }

    const onRenderToolbar = useCallback((toolbar) => {
        const buttons = React.Children.toArray(toolbar.props.children);

        const pager = buttons[pdfViewerTools.indexOf("pager")];
        const selection = buttons[pdfViewerTools.indexOf("selection")];
        const zoom = buttons[pdfViewerTools.indexOf("zoom")];
        const zoomInOut = buttons[pdfViewerTools.indexOf("zoomInOut")];
        const searchTool = buttons[pdfViewerTools.indexOf("search")];
        const download = buttons[pdfViewerTools.indexOf("download")];
        const spacer = buttons[pdfViewerTools.indexOf("spacer")];
        const printTool = buttons[pdfViewerTools.indexOf("print")];

        return (
            <toolbar.type {...toolbar.props}>
                {[
                    <React.Fragment key="pager"><div className="pdf-toolbar-large">{pager}</div></React.Fragment>,
                    <React.Fragment key="firstSpacer"><div className="pdf-toolbar-large">{spacer}</div></React.Fragment>,
                    <React.Fragment key="zoomInOut"><div className="pdf-toolbar pdf-toolbar-zoom">{zoomInOut}</div></React.Fragment>,
                    zoom,
                    <React.Fragment key="selection"><div className="pdf-toolbar-medium">{selection}</div></React.Fragment>,
                    // wrap it in fragment to resolve keys duplication when multiple spacers are needed
                    <React.Fragment key="secondSpacer">{spacer}</React.Fragment>,
                    <React.Fragment key="searchTool"><div className="pdf-toolbar-medium">{searchTool}</div></React.Fragment>,
                    download,
                    printTool,
                    pdfViwerCustomTool()

                ]}
            </toolbar.type>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //const divId = "PdfDistance";
    //const divRef = useRef();
    //const useGesture = createUseGesture([dragAction, pinchAction])

    //useGesture(
    //    {
    //        onPinchStart: ({ offset: [s] }) => {
    //            setLocalValue(s);
    //        },
    //        onPinch: (state) => {
    //            console.log(state);
    //        },
    //        onPinchEnd: ({ pinching: p, direction: [d], offset: [s], type: t }) => { //, { offset: [d], touches: t, type: tp, direction: [wd], event: e }) => {
    //            console.log(p);
    //            if (t === "wheel") {
    //                onZoomClick(d > 0 ? 1 : 0);
    //            } else {
    //                const scaleStart = getLocalValue();
    //                console.log(scaleStart, s);
    //                if (s !== scaleStart) {
    //                    onZoomClick(s < scaleStart ? 0 : 1);
    //                }
    //            }
    //            setLocalValue(0);
    //        }
    //    },
    //    {
    //        target: divRef,
    //        eventOptions: { passive: false },
    //        pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    //    }
    //);

    return (
        /*<div id={divId} style={{display: "none"}}/>*/
        <LocalizationProvider language='nb-NO'>
            <IntlProvider locale='en'>
                {/*<div ref={divRef}>*/}
                <div className={className}>
                    <KendoPDFViewer
                        tools={pdfViewerTools}
                        saveFileName={data.fileName}
                        data={data.base64}
                        onRenderToolbar={onRenderToolbar} />
                    {resizePdfToolbar()}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    );

    //function onZoomClick(index = 0) {
    //    const toolbarElems = document.getElementsByClassName("pdf-toolbar-zoom");
    //    if (toolbarElems[0].children[0].children[index].disabled) return;
    //    toolbarElems[0].children[0].children[index].click();
    //}

    //function setLocalValue(val) {
    //    const elem = document.getElementById(divId);
    //    elem.setAttribute("val", val);
    //}

    //function getLocalValue() {
    //    const elem = document.getElementById(divId);
    //    const val = elem.getAttribute("val");

    //    if (CommonService.isNullOrEmpty(val)) return 0;

    //    return Number(val);
    //}
}
