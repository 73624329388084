import './styles/notice-time.css';

import React, { useEffect, useRef, useState } from 'react';

import { Button } from "@progress/kendo-react-buttons";
import NoticeTimeApiRepository from '../../repositories/api/NoticeTimeApiRepository';
import Icons, { PinIcon, UploadIcon } from '../../components/layout/icons/Icons';
import { IconColors } from '../../services/SystemNames';
import { Card } from '../../components/layout/card/Card';
import Spacer from '../../components/layout/Spacer';
import DateTimePicker from '../../components/layout/DateTimePicker';
import { GoogleMapProperties } from '../../components/layout/map/GoogleMapProperties';
import { GoogleMapCard } from '../../components/layout/map/GoogleMapCard';
import SourceApiRepository from '../../repositories/api/SourceApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import { NoticeTimeBottomNavigation } from './components/NoticeTimeBottomNavigation';
import Overlay from '../../components/layout/overlay/Overlay';
import Moment from 'moment';
import { DialogConfirm } from '../../components/layout/dialogs/components/DialogConfirm';
import { CardProperties } from '../../components/layout/card/components/CardProperties';
import { DialogProperties } from '../../components/layout/dialogs/DialogProperties';
import DropDownList from '../../components/layout/DropDownList';
import { isNullOrEmpty, isObjectNull, removeQuotesFromString } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { getRandomLocation } from '../../components/helpers/GeometryHelpers';
import { getTimeMinutes } from '../../components/helpers/DataHelpers';
import { GoogleMapContainer } from '../../components/layout/map/GoogleMapContainer';

export default function Overridden (){

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [noticeTime, setNoticeTime] = useState();
    const [canDelete, setCanDelete] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [minutes, setMinutes] = useState([]);
    const [selectedMinute, setSelectedMinute] = useState(null);
    const [untilTime, setUntiltime] = useState(null);
    const [canGetCurrentLocation, setCanGetCurrentLocation] = useState(true);
    const [mapClickType, setMapClickType] = useState(null);
    const [mapClickPosition, setMapClickPosition] = useState(null);

    const mapRef = useRef();

    useEffect(() => {
        publishHeaderTopic("Overstyrt varslingstid");
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ContentGrid
                title="Varslingstid"
                titleIcon="alarmClock"
                fullSize={false}
                subTitle={"Overstyrt varslingstid"}>
                <Card properties={{
                    ...CardProperties,
                    actions: canDelete ? [
                        {
                            onClick: () => setShowDeleteDialog(true),
                            icon: "trash",
                            text: "Avbryt"
                        }
                    ]: []
                }}>
                    {
                        !canDelete &&
                        <Spacer height={30} />
                    }
                    <div className="overridden-container">
                        <div className="overridden-row">
                            <div><Icons color={IconColors.Primary} iconName="alarmClock" /></div>
                            <div>Varslingstid:</div>
                            <div>
                                <div>
                                    <DropDownList
                                        data={minutes}
                                        value={selectedMinute}
                                        textField="text"
                                        onChange={handleOnMinuteChange}
                                        adaptive={true}
                                        adaptiveTitle="Velg varslingstid"
                                        defaultText="Velg"
                                    />
                                </div>
                                <div>&nbsp;min</div>
                            </div>

                        </div>
                        <Spacer height={20} />
                        <div className="overridden-row">
                            <div><Icons iconName="hourglass" /></div>
                            <div>Gjelder til kl.:</div>
                            <div style={{
                                gridTemplateColumns: "1fr"
                            }}>
                                <DateTimePicker
                                    value={untilTime}
                                    canDelete={false}
                                    onChange={handleOnUntilTimeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Spacer height={20} />
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <Button
                                    disabled={!canGetCurrentLocation}
                                    fillMode="outline"
                                    themeColor="primary"
                                    size="large"
                                    svgIcon={PinIcon}
                                    onClick={onGetCurrentLocationClick}>
                                    Finn min posisjon
                                </Button>
                            </div>
                        </div>
                    </div>

                    {
                        !isNullOrEmpty(getValueByPropertyName("overriddenPositionName")) &&
                        <>
                            <Spacer height={20} />
                            <div className="container">
                                <div className="row">
                                    <div className="col text-center">
                                        <div className="h5">Din lokasjon: {getValueByPropertyName("overriddenPositionName")}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    
                    <Spacer height={20} />
                    <GoogleMapContainer>
                        <GoogleMapCard
                            ref={mapRef}
                            properties={{
                                ...GoogleMapProperties,
                                canMapClick: true,
                                mapClickType: mapClickType,
                                onMapClick: onMapClick,
                                mapClickPosition: mapClickPosition
                            }}
                        />
                    </GoogleMapContainer>
                </Card>
                <Spacer height={40} />
            </ContentGrid>

            <FloatingButtonContainer hasBottomNavigation={true}>
                <FloatingActionButton
                    themeColor={canSave ? "primary" : "secondary"}
                    disabled={!canSave}
                    svgIcon={UploadIcon}
                    onClick={onSave}
                />
            </FloatingButtonContainer>

            <NoticeTimeBottomNavigation id={3} />

            {
                showDeleteDialog &&
                <DialogConfirm properties={{
                        ...DialogProperties,
                        title: "Slett overstyrt varslingstid",
                        onClose: onCloseConfirmDialog,
                        onClick: onDelete
                } }>
                    &Oslash;nsker du &aring; slette overstyrt varslingstid?
                </DialogConfirm>
            }

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {

        const response = await NoticeTimeApiRepository.getGeneralAsync();

        if (response.ok) {
            const data = await response.json();
            setNoticeTime(data);

            const timeMinutes = getTimeMinutes(false);
            setMinutes(timeMinutes);
            
            if (!isNullOrEmpty(data.overriddenUntilTime)) {
                const untilTime = new Date(data.overriddenUntilTime);
                setUntiltime(untilTime);

                const selectedMinute = timeMinutes.find(t => Number(t.value) === Number(data.overriddenMinutes));
                setSelectedMinute(selectedMinute);

                setMapClickType("circle");
                setMapClickPosition({ lat: data.overriddenPositionLatitude, lng: data.overriddenPositionLongitude });
                setCanDelete(true);
            }
            else {
                const selectedMinute = timeMinutes.find(t => Number(t.value) === 0);
                setSelectedMinute(selectedMinute);
                setCanDelete(false);
                setUntiltime(null);
                setMapClickPosition(null);
                mapRef.current.onSetSelectedPosition(null);
            }

            setCanSave(false);

        } else {
            handleError(response.status);
        }

        setIsBusy(false);

    }

    function onSave() {
        const untilTime = noticeTime.overriddenUntilTime;
        if (!isNullOrEmpty(untilTime)) {
            noticeTime.overriddenUntilTimeHours = Moment(untilTime).hour();
            noticeTime.overriddenUntilTimeMinutes = Moment(untilTime).minute();
        }
        thenSaveAsync(noticeTime);
    }

    function onDelete() {
        setShowDeleteDialog(false);
        noticeTime.overriddenPositionTime = null;
        noticeTime.overriddenUntilTime = null;
        noticeTime.overriddenMinutes = null;
        noticeTime.overriddenUntilTimeHours = null;
        noticeTime.overriddenUntilTimeMinutes = null;
        noticeTime.overriddenPositionName = null;
        noticeTime.overriddenPositionTime = null;
        noticeTime.overriddenPositionLongitude = null;
        noticeTime.overriddenPositionLatitude = null;

        thenSaveAsync(noticeTime);
    }

    async function thenSaveAsync(noticeTime) {
        setIsBusy(true);
        const response = await NoticeTimeApiRepository.setGeneralAsync(noticeTime);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Overstyrt varslingstid ble lagret.`);
            initializeAsync();
        } else {
            handleError(response.status);
        }
    }

    function getValueByPropertyName(propertyName, defaultValue = "") {
        if (isNullOrEmpty(noticeTime)) return defaultValue;
        return noticeTime[propertyName];
    }

    function onCloseConfirmDialog() {
        setShowDeleteDialog(false);
    }

    function handleOnMinuteChange(e) {
        setSelectedMinute(e);

        noticeTime.overriddenMinutes = Number(e.value);
        noticeTime.overriddenChanged = true;

        updateNoticeTime(noticeTime);
        validate(noticeTime);
    }

    function handleOnUntilTimeChange(e) {

        if (isObjectNull(e.value)) {
            noticeTime.overriddenUntilTime = null;
            noticeTime.overriddenUntilTimeHours = null;
            noticeTime.overriddenUntilTimeMinutes = null;
            setUntiltime(e.value);
        } else {
            noticeTime.overriddenUntilTime = e.value;
            setUntiltime(e.value);
        }

        noticeTime.overriddenChanged = true;

        updateNoticeTime(noticeTime);
        validate(noticeTime);
    }

    async function getPositionAsync(position, callback) {
        const response = await SourceApiRepository.getPositionAsync(position);
        callback(response, position);
    }

    function onMapClick(e) {
        const position = {
            lat: e.lat,
            lng: e.lng

        };

        setMapClickPosition(position);
        setMapClickType("marker");

        getPositionAsync(position, onMapClickCallbackAsync);
    }

    async function onMapClickCallbackAsync(response, position) {
        let positionName = "Ukjent";

        if (response.ok) {
            const data = await response.text();
            if (isNullOrEmpty(data) === false) {
                positionName = removeQuotesFromString(data);
                noticeTime.overriddenPositionLatitude = position.lat;
                noticeTime.overriddenPositionLongitude = position.lng;
            }
        } 

        setCanGetCurrentLocation(true);
        onPositionChange(position, positionName);
        validate(noticeTime);
    }

    function onPositionChange(position, positionName) {

        noticeTime.overriddenPositionName = positionName;
        noticeTime.overriddenPositionLatitude = position.lat;
        noticeTime.overriddenPositionLongitude = position.lng;
        noticeTime.overriddenPositionTime = new Date();
        noticeTime.overriddenChanged = true;

        updateNoticeTime(noticeTime);

        validate(noticeTime);
    }

    function onGetCurrentLocationClick() {
        if (canGetCurrentLocation === false) return;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onGetCurrentLocationSuccess, onGetCurrentLocationError);
        } else {
            console.warn("geolocation not supported.");
        }
    }

    function onGetCurrentLocationSuccess(location) {
        const randomLocation = getRandomLocation(location.coords.latitude, location.coords.longitude, 450);
        const randomLocationObj = { lat: randomLocation[0], lng: randomLocation[1] }
        
        setCanGetCurrentLocation(false);
        setMapClickPosition(randomLocationObj);
        setMapClickType("circle");

        getPositionAsync(randomLocationObj, onGetCurrentLocationSuccessCallbackAsync);
    }

    async function onGetCurrentLocationSuccessCallbackAsync(response, position) {

        if (response.ok) {
            const data = await response.text();
            if (!isNullOrEmpty(data)) {
                onPositionChange(position, removeQuotesFromString(data));
            }
        }
    }

    function onGetCurrentLocationError(error) {
        switch (error.code) {
            // PERMISSION_DENIED
            case 1:
                console.warn('Lokasjon tilgang ikke tillatt.');
                publishWarningNotificationTopic("Lokasjon tilgang ikke tillatt.");
                break;
            // POSITION_UNAVAILABLE
            case 2:
                console.warn('Lokasjon ikke tilgjengelig');
                publishWarningNotificationTopic("Lokasjon ikke tillatt.");
                break;
            // TIMEOUT
            case 3:
                publishWarningNotificationTopic("Timeout oppstod.");
                break;
            default:
                break;
        }
    }

    function handleError(response) {
        publishWarningNotificationTopic("En feil oppstod ved behandling av overstyrt varslingstid.", response.status);
        setIsBusy(false);
        setHasErrors(true);
        setCanDelete(false);
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }

    function updateNoticeTime(newNoticeTime) {
        setNoticeTime(noticeTime => ({
            ...noticeTime,
            ...newNoticeTime
        }));
    }

    function validate(noticeTime) {

        const hasOverriddenChanged = noticeTime.hasOwnProperty("overriddenChanged") ? noticeTime.overriddenChanged : false;

        if (!hasOverriddenChanged) {
            setCanSave(false);
        }
        else {
            setCanSave(
                !isNullOrEmpty(noticeTime.overriddenUntilTime) &&
                noticeTime.overriddenMinutes > 0 &&
                noticeTime.overriddenPositionLatitude > 0 &&
                noticeTime.overriddenPositionLongitude > 0);
        } 
    }
}

