import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { hseApiSlice } from './reducers/slices/hseApi.slice';

export const store = configureStore({
    reducer: {
        [hseApiSlice.reducerPath]: hseApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(hseApiSlice.middleware),
});

setupListeners(store.dispatch);