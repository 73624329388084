import React, { useState, useEffect } from 'react';
import './styles/hse.css';

import {
    ItemStates
} from '../../services/SystemNames';
import { useParams } from 'react-router-dom';
import Spacer from '../../components/layout/Spacer';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import { ItemDefaultCard } from './components/ItemDefaultCard';
import { ItemEditDialog } from './components/ItemEditDialog';
import { HseBottomNavigation } from './components/HseBottomNavigation';
import { useDeleteTypeMutation, useLazyGetTypesByGroupTypeSystemNameQuery, useSaveTypeMutation } from '../../reducers/slices/hseApi.slice';
import { ItemDeleteDialog } from './components/ItemDeleteDialog';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishReloadAlertsTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { scrollElementIntoView } from '../../components/helpers/ElementHelpers';

function Edit() {

    const { id } = useParams();

    const [
        onGroupRefetch,
        {
            data: groupData,
            isFetching: groupIsFetching,
            isError: groupIsError,
        }
    ] = useLazyGetTypesByGroupTypeSystemNameQuery();
    
    const [
        onSaveType, {
            isFetching: saveIsFetching,
            isSuccess: saveIsSuccess,
            isError: saveIsError
        }] = useSaveTypeMutation();

    const [
        onDeleteType, {
            isFetching: deleteIsFetching,
            isSuccess: deleteIsSuccess,
            isError: deleteIsError
        }] = useDeleteTypeMutation();

    const [itemState, setItemState] = useState(ItemStates.Default);
    const [selectedItem, setSelectedItem] = useState(null);
    
    // Initialize function
    useEffect(() => {
        onGroupRefetch(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {

        if (groupIsFetching) return;

        if (isArrayEmpty(groupData)) {
            publishHeaderTopic("HMS-data");
        } else {
            publishHeaderTopic("HMS-data", groupData[0].groupDisplayName);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupIsFetching]);
    
    useEffect(() => {
        scrollSelectedItemIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemState]);
    
    useEffect(() => {
        if (!groupIsError) return;
        publishWarningNotificationTopic(`Kunne ikke hente HMS-data`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupIsError]);

    useEffect(() => {
        handleSave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveIsSuccess, saveIsError]);

    useEffect(() => {
        handleDelete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteIsSuccess, deleteIsError]);
    
    return (
        <>
            <ContentGrid
                title="HMS-data"
                titleIcon="hse"
                subTitle={getGroupTitle()} fullSize={false}
                canPullToRefresh={true}
                onPullToRefresh={() => onGroupRefetch(id) }
            >
                <div className="menu-bar-list">
                    <ItemDefaultCard
                        types={groupData ?? []}
                        onClick={onEditClick}
                        onDelete={onDeleteClick}/>
                </div>
                <Spacer height={30} />
            </ContentGrid>

            {
                canEditType() &&
                <ItemEditDialog
                    selectedItem={selectedItem}
                    onSave={onTypeSave}
                    onError={onTypeError}
                    onClose={() => setItemState(ItemStates.Default) }
                />
            }

            {
                canDeleteType() &&
                <ItemDeleteDialog
                    selectedItem={selectedItem}
                    onClose={() => setItemState(ItemStates.Default)}
                    onDelete={onDeleteType}
                    onError={onTypeError}
                />
            }

            <Overlay isBusy={groupIsFetching || saveIsFetching || deleteIsFetching} />

            <HseBottomNavigation id={id} />
        </>
    );

    function getGroupTitle() {
        if (isObjectNull(groupData)) return "";
        return groupData[0].groupDisplayName;
    }

    function canEditType() {
        return itemState === ItemStates.Edit && !isNullOrEmpty(selectedItem);
    }

    function canDeleteType() {
        return itemState === ItemStates.Delete && !isNullOrEmpty(selectedItem);
    }

    function onEditClick(item) {
        setSelectedItem(item);
        setItemState(ItemStates.Edit);
    }

    function onTypeError() {
        setSelectedItem(null);
        setItemState(ItemStates.Default);
    }
    
    async function onTypeSave(item) {
        setItemState(ItemStates.Default);
        onSaveType(item);
    }

    function handleSave() {
        if (isNullOrEmpty(selectedItem)) return;

        const displayName = selectedItem.displayName;

        if (saveIsSuccess) {
            publishSuccessNotificationTopic(`HMS-data ble lagret for ${displayName}`);
        }
        if (saveIsError) {
            publishWarningNotificationTopic(`HMS-data ble ikke lagret for ${displayName}`);
        }
        publishReloadAlertsTopic();
        setSelectedItem(null);
    }

    function handleDelete() {
        if (isNullOrEmpty(selectedItem)) return;

        const displayName = selectedItem.displayName;

        if (deleteIsSuccess) {
            publishSuccessNotificationTopic(`HMS-data ble slettet for ${displayName}`);
            publishReloadAlertsTopic()
        }
        if (deleteIsError) {
            publishWarningNotificationTopic(`HMS-data ble ikke slettet for ${displayName}`);
        }

        setSelectedItem(null);
    }

    async function onDeleteClick(item) {
        setSelectedItem(item);
        setItemState(ItemStates.Delete);
    }

    function scrollSelectedItemIntoView() {
        if (itemState !== ItemStates.Edit|| isObjectNull(selectedItem)) return;

        scrollElementIntoView(`hse-item-${selectedItem.systemName}`);
    }
}

export default Edit;
