import queryString from 'query-string';
import { getToken } from '../../components/helpers/TokenHelpers';

const BaseApiRepository = {

    async optionsAsync(method = 'GET') {
        const token = await this.baseGetToken();
        const options = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
                }
        };

        return options;
    },

    async postOptionsAsync(obj) {
        const options = await this.optionsAsync('POST');
        options.body = JSON.stringify(obj);

        return options;
    },

    async deleteOptionsAsync(obj) {
        const options = await this.optionsAsync('DELETE');
        options.body = JSON.stringify(obj);

        return options;
    },

    async putOptionsAsync(obj) {
        const options = await this.optionsAsync('PUT');
        options.body = JSON.stringify(obj);

        return options;
    },

    async getAsync(url) {
        const options = await this.optionsAsync();
        const response = await this.fetchAsync(url, options);
        return response;
    },

    async postAsync(url, obj) {
        const options = await this.postOptionsAsync(obj);
        const response = await this.fetchAsync(url, options);
        
        return response;
    },

    async deleteAsync(url, obj) {
        const options = await this.deleteOptionsAsync(obj);
        const response = await this.fetchAsync(url, options);

        return response;
    },

    async putAsync(url, obj) {
        const options = await this.putOptionsAsync(obj);
        const response = await this.fetchAsync(url, options);

        return response;
    },

    async baseGetToken() {
        const searchParams = queryString.parse(window.location.search);
        if (!searchParams.token) {
            return getToken();
        }
        return searchParams.token;
    },

    async fetchAsync(url, options) {
        try {
            const response = await fetch(`api/${url}`, options);
            return response;
        } catch (error) {
            console.warn(error);
            return {
                ok: false,
                status: 503
            }
        }
    }
};

export default BaseApiRepository;