import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { getElementById } from '../../../helpers/ElementHelpers';
import { MapControlsTop } from './controls/MapControlsTop';
import { MapControlsBottom } from './controls/MapControlsBottom';

export const MapControls = forwardRef((
    {
        mapId,
        properties,
        onMultiSelectClick,
        onMultiSelectOkClick,
        isLocationsActive,
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onZoomChanged() {
            topRef.current.onZoomChanged();
        }
    }));

    const map = useMap();
    const topRef = useRef();

    const loaderElemId = `googleMapControlLoader-${mapId}`;
    const [hasAddedBaseControls, setHasAddedBaseControls] = useState(false);

    useEffect(() => {
        if (!map) return;

        if (hasAddedBaseControls) return;

        const loaderElem = getElementById(loaderElemId);
        map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(loaderElem);

        setHasAddedBaseControls(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties]);

    useEffect(() => {
        topRef.current.onLocationActiveChanged(isLocationsActive);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocationsActive]);

    return (
        <>
            <MapControlsTop
                ref={topRef}
                mapId={mapId}
                properties={properties}
            />

            <div id={loaderElemId} className="google-map-loader" />

            <MapControlsBottom
                mapId={mapId}
                properties={properties}
                onMultiSelectClick={onMultiSelectClick}
                onMultiSelectOkClick={onMultiSelectOkClick}
            />
            
        </>

    );
})
