import './textareafieldwrapper.css'

import React from 'react';

import { TextArea as KendoTextArea } from "@progress/kendo-react-inputs";
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import { CopyToClipboard as CTC } from 'react-copy-to-clipboard';
import Icons from '../icons/Icons';
import { isNullOrEmpty, isObjectNull } from '../../helpers/ObjectHelpers';
import { publishSuccessNotificationTopic } from '../../helpers/PubSubHelpers';

function TextAreaFieldWrapper({
    title = "",
    value = "",
    rows = 6,
    canCopyToClipboard = true,
    onChange,
    disabled = false,
    boldTitle = false,
    className = "",
    readOnly = false,
}) {

    return (
        <FieldWrapper>
            <Label className="k-form-label">
                <div className="textarea-copy-to-clipboard">
                    {!isNullOrEmpty(title) &&
                        <div style={{
                            fontWeight: boldTitle ? "500" : "normal"
                        }}>
                            {title}:
                        </div>
                    }
                    <div>
                        {
                            (canCopyToClipboard && !isNullOrEmpty(value)) &&
                                <CTC text={value} onCopy={() => publishSuccessNotificationTopic("Teksten ble kopiert til utklippstavlen.")}>
                                    <Icons iconName="copy" dimensions={14} />
                                </CTC>
                        }
                    </div>
                </div>
                
            </Label>
            <div className="k-form-field-wrap">
                <KendoTextArea
                    value={value}
                    onChange={handleOnChange}
                    rows={rows}
                    autoSize={false}
                    placeholder={`${title}...`}
                    className={className}
                    disabled={disabled}
                    readOnly={readOnly}
                />
            </div>
        </FieldWrapper>
    );

    function handleOnChange(e) {
        if (disabled || isObjectNull(onChange)) return;

        onChange(e);
    }
}

export default TextAreaFieldWrapper; 