import React, { useEffect } from 'react';
import { useState } from 'react';
import Moment from 'moment';
import { PilotAssignmentCommands } from '../../../services/DispatcherActions';
import uuid from 'react-uuid';
import Spacer from '../../../../../components/layout/Spacer';
import {
    Error,
} from "@progress/kendo-react-labels";
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import TextBoxFieldWrapper from '../../../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import DateTimePickerFieldWrapper from '../../../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import SwitchFieldWrapper from '../../../../../components/layout/fieldWrappers/SwitchFieldWrapper';
import TextAreaFieldWrapper from '../../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { DateTimePickerModes } from '../../../../../components/layout/DateTimePicker';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from '../../../../../components/helpers/ObjectHelpers';
import { formatMoment } from '../../../../../components/helpers/DateTimeHelpers';
import { edit } from '../../../../../components/layout/icons/IconExporter';

export const OvertimeDialog = ({ onClose, overtime = null, callback, isOvertimeAvailable }) => {

    const [isEditable, setIsEditable] = useState(false);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [isMinimum, setIsMinimum] = useState(false);
    const [isTwoThird, setIsTwoThird] = useState(false);
    const [description, setDescription] = useState("");
    const [hours, setHours] = useState("");
    const [warnings, setWarnings] = useState([]);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Overtid",
                onClose: onClose,
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClick,
                        disabled: !isEditable,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <div className="dialog-form">

                {
                    !isOvertimeAvailable &&
                    <>
                        <Error>
                            <p>
                                Merk at losformidler verken har krysset av for overtid, reise overtid eller &gt; 12t arb tid.
                                Er det riktig &aring; rapportere overtid relatert til losoppdraget?
                            </p>
                        </Error>
                    </>
                }

                <DateTimePickerFieldWrapper
                    title="Fra dato"
                    mode={DateTimePickerModes.DateTime}
                    value={fromTime}
                    onChange={onFromTimeChange}
                />

                <Spacer height={20} />

                <DateTimePickerFieldWrapper
                    title="Til dato"
                    mode={DateTimePickerModes.DateTime}
                    value={toTime}
                    onChange={onToTimeChange}
                    warnings={warnings}
                />

                <Spacer height={20} />


                <SwitchFieldWrapper
                    title="Minimumstid 2t"
                    checked={isMinimum}
                    onChange={onMinimumTimeChange}
                />

                <Spacer height={20} />

                <SwitchFieldWrapper
                    title="2/3 timer"
                    checked={isTwoThird}
                    onChange={onTwoThirdshange}
                />

                <Spacer height={20} />

                <TextBoxFieldWrapper
                    title="Antall"
                    value={hours}
                    disabled={true}
                    readOnly={true}
                />

                <Spacer height={20} />

                <TextAreaFieldWrapper
                    title="Beskrivelse"
                    rows={3}
                    value={description}
                    onChange={onDescriptionChange}
                />
            </div>
        </Dialog>
    )

    function initializeAsync() {
        if (isObjectNull(overtime)) return;
        
        setDescription(overtime.description);
        setIsMinimum(overtime.isMinimum);
        setIsTwoThird(overtime.isTwoThird);
        setHours(overtime.number);
        setFromTime(Moment(overtime.fromTime).toDate());
        setToTime(Moment(overtime.toTime).toDate());
    }

    function onSaveClick() {
        if (!isEditable) return;

        let compensation = {
            guid: uuid(),
            pilotagePilotOvertimeId: 0,
            command: PilotAssignmentCommands.AddPilotAssignmentOvertime
        };

        if (!isObjectNull(overtime)) {
            compensation = deepCopyObject(overtime);
            compensation.command = PilotAssignmentCommands.AddPilotAssignmentOvertime;
            compensation.pilotagePilotOvertimeId = overtime.pilotagePilotOvertimeId;

            if (compensation.hasOwnProperty("pilotagePilotOvertimeId")) {
                if (compensation.pilotagePilotOvertimeId > 0) {
                    compensation.command = PilotAssignmentCommands.UpdatePilotAssignmentOvertime;
                }
            }

            if (isNullOrEmpty(compensation.guid)) {
                compensation.guid = uuid();
            }
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        compensation.fromTime = formatMoment(from);
        compensation.toTime = formatMoment(to);
        compensation.isMinimum = isMinimum;
        compensation.isTwoThird = isTwoThird;
        compensation.number = Number(hours);
        compensation.description = description;
        
        callback(compensation);
        onClose();
    }

    function onDescriptionChange(e) {
        setDescription(e.value);
        validate(e.value, fromTime, toTime);
    }

    function onFromTimeChange(e) {
        setFromTime(e.value);
        calculateHours(e.value, getParsedMomentDate(toTime), isMinimum, isTwoThird);

        validate(description, e.value, toTime);
    }

    function onToTimeChange(e) {
        setToTime(e.value);
        calculateHours(getParsedMomentDate(fromTime), e.value, isMinimum, isTwoThird);

        validate(description, fromTime, e.value);
    }

    function onMinimumTimeChange(e) {
        setIsMinimum(e.target.value);
        calculateHours(getParsedMomentDate(fromTime), getParsedMomentDate(toTime), e.target.value, isTwoThird);
    }

    function onTwoThirdshange(e) {
        setIsTwoThird(e.target.value);
        calculateHours(fromTime, toTime, isMinimum, e.target.value);
    }

    function calculateHours(fromTime, toTime, minimumTime, twoThirds) {

        const warnings = [];

        if (isObjectNull(fromTime) || isObjectNull(toTime)) {
            if (isNullOrEmpty(fromTime)) {
                warnings.push("Fra-dato kan ikke v&aelig;re tom.");
            }
            if (isNullOrEmpty(toTime)) {
                warnings.push("Til-dato kan ikke v&aelig;re tom.");
            }

            setHours("");
            setWarnings(warnings);
            return;
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        if (from >= to) {
            warnings.push("Til-dato kan ikke v&aelig;re f&oslash;r fra-dato.")
        }

        const diff = to.diff(from, 'minutes');
        let hours = (diff / 60);

        if (minimumTime) {
            hours = 2;
        }
        if (twoThirds) {
            hours = (hours / 3) * 2;
        }

        setHours(hours.toFixed(2));
        setWarnings(warnings);
    }

    function getParsedMomentDate(val) {
        return isNullOrEmpty(val) ? null : val;
    }

    function validate(description, fromTime, toTime) {
        let editable = !isNullOrEmpty(description) && !isNullOrEmpty(fromTime) && !isNullOrEmpty(toTime);
        if (editable) {
            const fromMoment = Moment(fromTime);
            const toMoment = Moment(toTime);
            editable = fromMoment < toMoment;
        }
        setIsEditable(editable);
    }
}
