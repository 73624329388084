import './contentgrid.css'

import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import PullToRefresh from 'react-simple-pull-to-refresh';
import TitleContainer from './TitleContainer';
import { isNullOrEmpty } from '../helpers/ObjectHelpers';
import { addAppContentFullSize, removeAppContentFullSize } from '../helpers/ElementHelpers';

export default function ContentGrid(
    {
        children,
        title = "",
        titleIcon = "",
        subTitle = "",
        defaultContentClassName = "",
        className = "",
        canPullToRefresh = false,
        onPullToRefresh,
        canCopySubTitle = false,
        fullSize = true
    }
) {

    const [warning, setWarning] = useState("");
    const [hasPulled, setHasPulled] = useState(false);

    useEffect(() => {

        if (fullSize) {
            addAppContentFullSize();
        }

        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        handleOnOffline();

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);

            if (fullSize) {
                removeAppContentFullSize();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRefresh = () => {

        if (hasPulled) return;

        setHasPulled(true);
        
        return new Promise(res => {
            setTimeout(() => {
                res(onPullToRefresh());
                setHasPulled(false);
            }, 250);
        });
        
    };

    return (
        <PullToRefresh onRefresh={handleRefresh} isPullable={canPullToRefresh}
            pullingContent={<div className="ptr-content">&darr;  Dra ned for &aring; laste inn p&aring; ny &darr;</div>}>
            <div className={`content-grid ${className}`}>
                {
                    !isNullOrEmpty(warning) &&
                    <div className="content-warning">{parse(warning) }</div>
                }
                <div className="content-title">
                    {
                        !isNullOrEmpty(title) &&
                        <TitleContainer
                            title={title}
                            subTitle={subTitle}
                            canCopySubTitle={canCopySubTitle}
                            icon={titleIcon}
                            size="large" />
                    }
                </div>

                <div className={`default-content ${defaultContentClassName}`}>
                    {children}
                </div>
            </div>
        </PullToRefresh>
    );

    function handleOnOffline() {
        setWarning(navigator.onLine ? "" : "Du er ikke p&aring; nett og lagring skjer kun lokalt");
    }
}
