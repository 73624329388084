import { useEffect } from 'react';
import {
    useMap
} from '@vis.gl/react-google-maps';
import { isObjectNull } from '../../../helpers/ObjectHelpers';

export const MapClickPosition = ({ properties, selectedPosition, setSelectedPosition }) => {

    const map = useMap();   

    useEffect(() => {

        if (!map) return;

        if (!isObjectNull(properties.mapClickPosition)) {
            if (isObjectNull(selectedPosition)) {
                setSelectedPosition(properties.mapClickPosition);
                map.setCenter(properties.mapClickPosition);
            } else if (properties.mapClickPosition.lat !== selectedPosition.lat &&
                properties.mapClickPosition.lng !== selectedPosition.lng) {
                setSelectedPosition(properties.mapClickPosition)
                map.setCenter(properties.mapClickPosition);
            }
        }
    }, [map, properties, selectedPosition, setSelectedPosition])
    
}
