import { createApi } from '@reduxjs/toolkit/query/react';
import { apiSliceService } from './apiSliceService';
import uuid from 'react-uuid';

export const hseApiSlice = createApi({
    recuderPath: 'apiSlice',
    baseQuery: apiSliceService.baseQuery(),
    tagTypes: ['HseTypeGroup', 'HseType'],
    endpoints: (builder) => ({
        getTypeGroups: builder.query({
            query: () => ({
                url: '/hse/type-groups',
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            providesTags: ['HseTypeGroup']
        }),

        getTypesByGroupTypeSystemName: builder.query({
            query: (systemName) => ({
                url: `hse/type-group/${systemName}`,
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            transformResponse: (response) => {
                const result = [];
                for (const item of response) {
                    item.guid = uuid();
                    item.eTagValue = "";
                    result.push(item);
                }
                return result;
            },
            providesTags: (result, error, arg) =>
                result ?
                    [
                        ...result.map(({ id }) => ({ type: 'HseType', id })),
                        'HseType'
                    ]
                    :
                    ['HseType'],
        }),

        getTypeBySystemName: builder.query({
            query: (systemName) => ({
                url: `hse/type/${systemName}`,
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            providesTags: ['HseType']
        }),

        saveType: builder.mutation({
            query: (obj) => ({
                url: 'hse/type',
                method: 'PUT',
                headers: apiSliceService.headers(),
                body: obj
            }),
            invalidatesTags: ['HseType']
        }),
        
        deleteType: builder.mutation({
            query: (obj) => ({
                url: 'hse/type',
                method: 'DELETE',
                headers: apiSliceService.headers(),
                body: obj
            }),
            invalidatesTags: ['HseType']
        })
        
    })
});

export const {
    useGetTypeGroupsQuery,
    useLazyGetTypeGroupsQuery,
    useGetTypesByGroupTypeSystemNameQuery,
    useLazyGetTypesByGroupTypeSystemNameQuery,
    useGetTypeBySystemNameQuery,
    useLazyGetTypeBySystemNameQuery,
    useSaveTypeMutation,
    useDeleteTypeMutation

} = hseApiSlice;