import './modulecard.css'

import React from 'react';
import parse from 'html-react-parser';
import { IconColors } from '../../services/SystemNames';
import Icons from './icons/Icons';
import {
    Card,
    CardBody
} from "@progress/kendo-react-layout";
import { isNullOrEmpty } from '../helpers/ObjectHelpers';
import { hasPersonType, isBetaUser } from '../helpers/AuthHelpers';

export function ModuleCard({ module }) {

    let show = true;
    
    if (!isNullOrEmpty(module.type)) {
        show = hasPersonType(module.type);
    }
    if (module.onlineOnly === true && navigator.onLine === false) {
        show = false;
    }
    if (module.beta === true) {
        show = isBetaUser();
    }
    
    return (
        show && 
            <Card className="module-card" onClick={module.onClick}>
                <CardBody>
                    <div>
                        <Icons color={IconColors.Primary} iconName={module.icon} dimensions={60} />
                    </div>
                    <div className="text-truncate">
                        {parse(module.title)}
                    </div>
                </CardBody>
            </Card>
    );
}

