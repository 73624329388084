import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import SelectOption from '../SelectOption';

function SelectOptionFieldWrapper({
    title = "",
    hasValidationError = false,
    disabled = false,
    options = [],
    selectedOption = null,
    optionTextField = "",
    onCallback = null,
    boldTitle = false
}) {

    return (
        <FieldWrapper>
            <Label hasValidationError={hasValidationError} style={{
                fontWeight: boldTitle ? "500" : "normal"
            }}>
                {parse(title)}:
            </Label>
            <div className="k-form-field-wrap">
                <SelectOption
                    title={title}
                    options={options}
                    selectedOption={selectedOption}
                    optionTextField={optionTextField}
                    onCallback={onCallback}
                    disabled={disabled}
                    hasValidationError={hasValidationError}
                />
            </div>
        </FieldWrapper>
    );
}

export default SelectOptionFieldWrapper; 