import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ValidateAccess from '../access/ValidateAccess';
import NoAccess from '../access/NoAccess';
import Offline from '../access/Offline';
import AuthApiRepository from '../../repositories/api/AuthApiRepository';
import { isBoolean } from '../helpers/ObjectHelpers';
import { getToken, initializeTokenAsync } from '../helpers/TokenHelpers';
import { isBetaUser, isLoggedIn } from '../helpers/AuthHelpers';
import { RouteGuardStates } from '../../services/SystemNames';
import { RestoreDatabase } from '../access/RestoreDatabase';
import BaseIdbRepository from '../../repositories/idb/BaseIdbRepository';
import Login from '../access/Login';

export const RouteGuardApplicationRights = ({ applicationRights: ApplicationRights, onlineOnly = true, beta: Beta }) => {

    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    useEffect(() => {

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        // execute validate application right
        validateApplicationRightAsync();

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function validateApplicationRightAsync () {

        setGuardState(RouteGuardStates.Busy);

        await initializeTokenAsync();

        const isDatabaseValid = await BaseIdbRepository.isDatabaseValidAsync();
        if (isDatabaseValid) {

            let canContinue = true;
            if (isBoolean(Beta)) {
                if (Beta) {
                    canContinue = isBetaUser();
                }
            }

            if (isLoggedIn() === true && canContinue) {
                if (navigator.onLine) {
                    //check user has JWT token, and if token has ApplicationRight
                    const token = getToken();
                    const flag = token !== undefined;

                    if (flag === true) {
                        const response = await AuthApiRepository.validateApplicationRightsAsync(ApplicationRights);
                        if (response.ok === true) {
                            const data = await response.json();
                            setGuardState(data === true ? RouteGuardStates.Valid : RouteGuardStates.Login)
                        } else {
                            setGuardState(RouteGuardStates.Login);
                        }
                    } else {
                        setGuardState(RouteGuardStates.NoAccess);
                    }
                } else if (navigator.onLine === false) {
                    setGuardState(RouteGuardStates.Offline);
                }
            } else {
                setGuardState(RouteGuardStates.NoAccess);
            }
        } else {
            setGuardState(RouteGuardStates.RestoreDatabase);
        }
    }

    function handleOffline() {
        setGuardState(RouteGuardStates.Offline);
    }

    function handleOnline() {
        validateApplicationRightAsync();
    }

    return (
        (() => {
            switch (guardState)
            {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.Offline:
                    return (<Offline />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.RestoreDatabase:
                    return (<RestoreDatabase />);
                case RouteGuardStates.Login:
                    return (<Login />);

                default:
                    return (<ValidateAccess />);
            }
        })()
    )
};
