import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import NoAccess from '../access/NoAccess';
import PleaseWait from '../access/PleaseWait';
import { isBetaUser, isLoggedIn } from '../helpers/AuthHelpers';
import { isBoolean } from '../helpers/ObjectHelpers';
import { initializeTokenAsync } from '../helpers/TokenHelpers';
import { RouteGuardStates } from '../../services/SystemNames';
import { RestoreDatabase } from '../access/RestoreDatabase';
import BaseIdbRepository from '../../repositories/idb/BaseIdbRepository';
import Login from '../access/Login';

const RouteGuardLoggedIn = ({ beta: Beta }) => {

    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    useEffect(() => {

        const initializeAsync = async () => {

            await initializeTokenAsync();

            const isDatabaseValid = await BaseIdbRepository.isDatabaseValidAsync();
            if (isDatabaseValid) {

                let canContinue = true;

                if (isBoolean(Beta)) {
                    if (Beta) {
                        canContinue = isBetaUser();
                    }
                }
                setGuardState((canContinue && isLoggedIn()) ? RouteGuardStates.Valid : RouteGuardStates.Login);
            } else {
                setGuardState(RouteGuardStates.RestoreDatabase);
            }
        }

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        (() => {
            switch (guardState) {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.RestoreDatabase:
                    return (<RestoreDatabase />);
                case RouteGuardStates.Login:
                    return (<Login />);

                default:
                    return (<PleaseWait />);
            }
        })()
    )
};

export default RouteGuardLoggedIn
