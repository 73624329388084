import PilotApiRepository from "../../../repositories/api/PilotApiRepository"
import { subscribe, unsubscribe } from '../../../firebase';
import { publishErrorNotificationTopic } from "../../../components/helpers/PubSubHelpers";
import { isNullOrEmpty } from "../../../components/helpers/ObjectHelpers";

const types = ["TENTATIVELY_ASSIGNED_PILOTAGE",
    "ASSIGNED_PILOTAGE", "SHIFT_SCHEDULE_SIGNED"]

function checkLocalStorageForDeviceID() {
    const id = localStorage.getItem("deviceId")
    if (id) return id
}

function checkForPermission() {
    return Notification.permission === "granted"
}

// API CALLS
async function getCurrentDevice(setSubscriptions, setDeviceId, setIsExpiredDatePassed, setIsSubscriptionMissing ) {
    const response = await PilotApiRepository.getCurrentDeviceAsync();
    if (response.ok) {
        const device = await response.json();
        const hasExpired = new Date(device.expirationDate) < Date.now();
        setIsExpiredDatePassed(hasExpired);
        setSubscriptions(device.pushSubscriptions);
        setDeviceId(device.deviceId);
        setIsSubscriptionMissing(device.pushSubscriptions.length === 0);
    }
    else
        setDeviceId(0);
}
async function getDeviceById(id, setSubscriptions, setErrorMessage, setIsSubscriptionMissing) {
    const response = await PilotApiRepository.getDeviceByIdAsync(id);
    if (response.ok) {
        const device = await response.json();
        setSubscriptions(device.pushSubscriptions);
        setIsSubscriptionMissing(device.pushSubscriptions.length === 0);
    } else {
        setErrorMessage("Noe gikk galt med henting av abonnement")
    }
}

async function refreshDeviceKey(id) {
    const response = await PilotApiRepository.refreshPushSubscriptionsAsync(id)
    if (response.ok) {
        localStorage.setItem("refreshed", true)
    }
}

async function getSubscriptions(setSubscriptions) {
    const response = await PilotApiRepository.getPushSubscriptionsAsync()
    if (response.ok) {
        const data = await response.json()
        setSubscriptions(data)
    } else {
        publishErrorNotificationTopic("Kunne ikke henter Push-Varsler abonnement");
        console.error(response)
    }
}

async function getSubscriptionsById(subscriptionId, setSubscriptions) {
    const response = await PilotApiRepository.getPushSubscriptionByIdAsync(subscriptionId)
    if (response.ok) {
        const data = await response.json()
        setSubscriptions(data)
    }
}

async function updateSubscriptionToken(callback) {
    const subscription = await subscribe();
    if (isNullOrEmpty(subscription)) return;
    const storedFcmKey = localStorage.getItem("fcmKey")
    if (storedFcmKey !== subscription) {
        localStorage.setItem("fcmKey", subscription);
        const response = await PilotApiRepository.savePushTokensAsync(subscription);
        if (response.ok) {
            if (callback) callback(true);
        }
    }  
}

async function addSubscription(subValue) {
    const subscriptionType = types[subValue]
    const response = await PilotApiRepository.addPushSubscriptionsAsync(subscriptionType)
    if (response.ok) {

    } else {
        publishErrorNotificationTopic("Kunne ikke legge til Push-Varsler Type: " + subscriptionType);
        console.error(response)
    }
}

async function removeSubscription(subValue) {
    const subscriptionType = types[subValue]
    const response = await PilotApiRepository.removePushSubscriptionsAsync(subscriptionType)
    if (response.ok) {

    } else {
        publishErrorNotificationTopic("Kunne ikke sletter Push-Varsler Type: " + subscriptionType);
        console.error(response)
    }
}

async function deleteSubscription(setSubscriptions) {
    await unsubscribe().then(async (result) => {
        if (result) {
            const response = await PilotApiRepository.deleteAllPushSubscriptionsAsync()
            if (response.ok) {
                setSubscriptions([])
            }
        }
    })
}

async function registerDevice(setIsRegistered, setErrorMessage) {
    const response = await PilotApiRepository.registerDeviceAsync()
    if (response.ok) {
        const id = await response.json();
        localStorage.setItem("deviceId", Number(id));
        setIsRegistered(true);
    } else {
        setErrorMessage("Noe gikk galt med registrering av enheten")
    }
}

export {
    types,
    refreshDeviceKey,
    registerDevice,
    removeSubscription,
    getSubscriptions,
    getCurrentDevice,
    getDeviceById,
    deleteSubscription,
    updateSubscriptionToken,
    addSubscription,
    getSubscriptionsById, checkForPermission,
    checkLocalStorageForDeviceID
}
