import SourceApiRepository from "../../repositories/api/SourceApiRepository";
import { createTimeObject } from "./ObjectHelpers";

function getTimeHours() {
    let arrHours = [];

    let currentHour = 0;
    while (currentHour < 24) {
        arrHours.push(createTimeObject(currentHour));
        currentHour++;
    }

    return arrHours;
}

function getTimeMinutes(isTime = true) {
    let arrMinutes = [];

    let currentMinute = 0;
    const max = isTime ? 60 : 125;

    while (currentMinute < max) {
        arrMinutes.push(createTimeObject(currentMinute));
        currentMinute = currentMinute + 5;
    }

    return arrMinutes;
}

async function getConfigurationAsync() {

    const response = await SourceApiRepository.getConfigAsync();
    if (response.ok === true) {
        const data = await response.json();
        return data;

    } else {
        return {};
    }
}

export {
    getTimeHours,
    getTimeMinutes,
    getConfigurationAsync,
}