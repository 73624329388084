import Moment from 'moment';
import { updateSubscriptionToken } from '../../pages/pilot/pushNotifications/PushHelpers';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import SourceApiRepository from '../../repositories/api/SourceApiRepository';
import DepartmentIdbRepository from '../../repositories/idb/DepartmentIdbRepository';
import HourCompensationTypeIdbRepository from '../../repositories/idb/HourCompensationTypeIdbRepository';
import LocationIdbRepository from '../../repositories/idb/LocationIdbRepository';
import ManeuverResponsibleTypeIdbRepository from '../../repositories/idb/ManeuverResponsibleTypeIdbRepository';
import MetaIdbRepository from '../../repositories/idb/MetaIdbRepository';
import PilotagePilotTypeIdbRepository from '../../repositories/idb/PilotagePilotTypeIdbRepository';
import PilotagePilotWaitingHoursReasonTypeIdbRepository from '../../repositories/idb/PilotagePilotWaitingHoursReasonTypeIdbRepository';
import PilotBoardingTypeIdbRepository from '../../repositories/idb/PilotBoardingTypeIdbRepository';
import VariableCompensationOverrideReasonTypeIdbRepository from '../../repositories/idb/VariableCompensationOverrideReasonTypeIdbRepository';
import { isLoggedIn, setWhoAmIAsync } from './AuthHelpers';


async function initCacheAsync() {

    if (!navigator.onLine || !isLoggedIn()) return;
        
    const cacheObj = await MetaIdbRepository.getCacheLastExpireDateAsync();

    let currentDate = Moment();
    let shouldReload = cacheObj === undefined || Moment(cacheObj.value).toDate() < currentDate.toDate();

    if (shouldReload === false) {
        const pilotStations = await DepartmentIdbRepository.getAllAsync();
        if (pilotStations.length === 0) {
            shouldReload = true;
        }

        const locations = await LocationIdbRepository.getAllAsync();
        if (locations.length === 0) {
            shouldReload = true;
        }
    }

    if (shouldReload === false) return;

    updateSubscriptionToken();
        
    await initPilotStationsAsync();
    await initLocationsAsync();
    await initTypesForPilotagePilotAsync();
    await initConfigurationAsync();
    await initMetaAsync();
            
    MetaIdbRepository.setCacheLastExpireDateAsync(currentDate.add(7, 'd').toDate());
}

async function initPilotStationsAsync() {
    const response = await SourceApiRepository.getPilotStationsAsync();
    if (response.ok === true) {

        await DepartmentIdbRepository.clearAsync();

        const data = await response.json();
        data.map(async (department) => {
            await DepartmentIdbRepository.setAsync(department);
        });
    } else {
        console.warn("Could not cache pilot stations");
    }
}

async function initLocationsAsync() {
    const response = await PilotApiRepository.getLocationsAsync();
    if (response.ok === true) {

        await LocationIdbRepository.clearAsync();

        const data = await response.json();
        data.map(async (location) => {
            await LocationIdbRepository.setAsync(location);
        });
    } else {
        console.warn("Could not cache locations");
    }
}

async function initTypesForPilotagePilotAsync() {
    const response = await SourceApiRepository.getPilotagePilotTypesAsync();
    if (response.ok === true) {
        const data = await response.json();
        await setTypesForPilotagePilotAsync(ManeuverResponsibleTypeIdbRepository, data.responsibleTypes);
        await setTypesForPilotagePilotAsync(HourCompensationTypeIdbRepository, data.hourCompensationTypes);
        await setTypesForPilotagePilotAsync(VariableCompensationOverrideReasonTypeIdbRepository, data.variableCompensationOverrideReasonTypes);
        await setTypesForPilotagePilotAsync(PilotBoardingTypeIdbRepository, data.pilotBoardingTypes);
        await setTypesForPilotagePilotAsync(PilotagePilotTypeIdbRepository, data.pilotagePilotTypes);
        await setTypesForPilotagePilotAsync(PilotagePilotWaitingHoursReasonTypeIdbRepository, data.waitingHoursReasonType);
            
    } else {
        console.warn("Could not cache types for pilotage pilot");
    }
}

async function initConfigurationAsync() {
    const response = await SourceApiRepository.getConfigAsync();
    if (response.ok === true) {
        const data = await response.json();
        const pilotagePilotGracePeriod = data.PilotagePilotGracePeriod;
        await MetaIdbRepository.setConfigurationAsync({ pilotagePilotGracePeriod: pilotagePilotGracePeriod })
    }
}

async function initMetaAsync() {
    await setWhoAmIAsync();

    const dayTimeResponse = await PilotApiRepository.getDayTimeAsync();
    if (dayTimeResponse.ok === true) {
        const data = await dayTimeResponse.json();
        await MetaIdbRepository.setDayTimeAsync(data);
    }
}

async function setTypesForPilotagePilotAsync(repository, data) {

    await repository.clearAsync();

    data.map(async (obj) => {
        await repository.setAsync(obj);
    });
}

export {
    initCacheAsync
}
