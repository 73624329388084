import ContentGridContainer from "../../../components/layout/ContentGrid";
import FloatingButtonContainer from "../../../components/layout/FloatingButtonContainer";
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import { AddIcon } from "../../../components/layout/icons/Icons";

export default function PilotageDetailsObservations({ pilotage }) {

    return (
        <>
            <ContentGridContainer
                title="Observasjoner"
                titleIcon="ship"
                subTitle={`${pilotage.pilotageNo}`}
                canCopySubTitle={true}
                fullSize={false}
            >
            <p>her kommer alle observasjonene....</p>
            </ContentGridContainer>

            <FloatingButtonContainer hasBottomNavigation={true}>
                <FloatingActionButton
                    themeColor={"primary"}
                    svgIcon={AddIcon}
                    onClick={onAddClick}
                />
            </FloatingButtonContainer>

        </>
    )

    function onAddClick() {
        alert("legg til observasjon");
    }
}