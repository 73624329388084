import "./styles/pcs.css"

import React, { useRef, useState } from 'react';
import { useEffectOnce } from '../../components/useEffectOnce';
import { GroupListCards } from './components/GroupListCards';
import { GroupLocationsCard } from './components/GroupLocationsCard';
import PcsApiRepository from '../../repositories/api/PcsApiRepository';
import { GoogleMapCard } from '../../components/layout/map/GoogleMapCard';
import { GoogleMapProperties } from '../../components/layout/map/GoogleMapProperties';
import { PcsNoEdit } from "./components/PcsNoEdit";
import { PcsBottomMenu } from "./components/PcsBottomMenu";
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import Spacer from "../../components/layout/Spacer";
import TitleContainer from "../../components/layout/TitleContainer";
import Overlay from "../../components/layout/overlay/Overlay";
import { isObjectNull } from "../../components/helpers/ObjectHelpers";

function Locations() {

    const [isBusy, setIsBusy] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [groupLocations, setGroupLocations] = useState(null);
    const [zoom, setZoom] = useState(8);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [mapChanges, setMapChanges] = useState(null);
    const [hasBottomNavigation, setHasBottomNavigation] = useState(false);

    const mapRef = useRef();
    const listRef = useRef();

    // Initialize function
    useEffectOnce(() => {
        publishHeaderTopic("Sertifikatomr&aring;der", "Lokasjoner");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`pcs-container ${hasBottomNavigation ? "pcs-container-mnu" : "pcs-container-no-mnu"}`}>
                <div>
                    <div>
                        <Spacer height={10} />
                        <div className="container">
                            <div className="row">
                                <TitleContainer
                                    title={getTitle()}
                                    icon={getTitleIcon()}
                                    iconDimensions={32}
                                />
                            </div>
                        </div>
                        <Spacer height={10} />
                    </div>
                    {
                        (isObjectNull(groupLocations)) ?
                            <GroupListCards
                                ref={listRef}
                                title="Sertifikatomr&aring;ders lokasjoner"
                                onClick={onGroupClickAsync}
                                onMouseOver={onGroupListMouseOver}
                                onMouseOut={onGroupListMouseOut}
                                mapChanges={mapChanges}
                            />
                            :
                            <GroupLocationsCard
                                groupLocations={groupLocations}
                                onCancel={onCancelLocationsClick}
                                onSave={onSaveLocationsAsync}
                            />
                    }
                </div>            
                
                <GoogleMapCard
                    ref={mapRef}
                    properties={{
                        ...GoogleMapProperties,
                        zoom: zoom,
                        center: center,
                        showPcsGroups: true,
                        showLocations: true,
                        onDragEnd: onMapChange,
                        onZoomChanged: onMapChange,
                        onTilesLoaded: onMapChange,
                        onResize: onMapChange,
                        onGeoJsonLoaded: onMapChange,
                        onFeatureClick: onMapFeatureClick,
                        onFeatureMouseOver: onMapFeatureMouseOver,
                        onFeatureMouseOut: onMapFeatureMouseOut
                    }}
                />
                <PcsNoEdit />
            </div>

            <PcsBottomMenu callback={onBottomMenuCallback} index={3} />

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    function handleError(response) {
        publishWarningNotificationTopic("Kunne ikke laste inn sertifikatomr&aring;ders lokasjoner.", response.status);
        setHasErrors(true);
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
    }

    function onCancelLocationsClick() {
        setGroupLocations(null);

        if (!isObjectNull(listRef.current)) {
            listRef.current.onGroupMouseOut();
        }
        
        selectFeatureInMap(0);
    }

    async function onSaveLocationsAsync(obj) {

        setIsBusy(true);

        const response = await PcsApiRepository.setGroupLocationDetailsAsync(obj.locations);

        if (response.ok === true) {
            publishSuccessNotificationTopic(`Lokasjoner for sertifikaromr&aring;det ${groupLocations.groupName} ble lagret.`);
            onCancelLocationsClick();
        } else {
            handleError(response);
        }

        setIsBusy(false);
    }

    async function onGroupClickAsync(groupId) {

        setIsBusy(true);

        const groupResponse = await PcsApiRepository.getGroupAsync(groupId);
        const locationsResponse = await PcsApiRepository.getGroupLocationDetailsAsync(groupId);

        if (locationsResponse.ok === true) {
            const locationsData = await locationsResponse.json();
            const groupData = await groupResponse.json();

            setGroupLocations({
                groupName: groupData.groupName,
                locations: locationsData
            });
            setZoom(11);
            setCenter({ lat: groupData.latitude, lng: groupData.longitude });
            selectFeatureInMap(groupId);

        } else {
            handleError(locationsResponse);
        }

        setIsBusy(false);
    }

    function onMapChange(e) {
        setMapChanges(e);
    }

    function onMapFeatureClick(e) {
        onGroupClickAsync(e);
    }

    function selectFeatureInMap(e) {
        mapRef.current.onFeatureSelected({
            property: "segmentGroupId",
            value: e
        });
    }

    function onGroupListMouseOver(groupId) {
        mapRef.current.onFeatureMouseOver({
            property: "segmentGroupId",
            value: groupId
        });
    }

    function onGroupListMouseOut() {
        mapRef.current.onFeatureMouseOut();
    }

    function onMapFeatureMouseOver(e) {
        if (isObjectNull(listRef.current)) return;
        listRef.current.onGroupMouseOver(e);
    }

    function onMapFeatureMouseOut() {
        if (isObjectNull(listRef.current)) return;
        listRef.current.onGroupMouseOut();
    }

    function onBottomMenuCallback(e) {
        setHasBottomNavigation(e);
    }

    function getTitle() {
        if (isObjectNull(groupLocations)) return "Sertifikatomr&aring;ders lokasjoner";
        return groupLocations.groupName;
    }

    function getTitleIcon() {
        if (isObjectNull(groupLocations)) return "bulletList";
        return "pin";
    }
}

export default Locations;