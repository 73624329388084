import { isArrayEmpty, isNullOrEmpty, isObjectNull } from "../../../helpers/ObjectHelpers"

export const CardModes = {
    Single: "single",
    Multible: "multible",
    None: "none"
}

export const CardActionTypes = {
    Delete: "delete",
    None: "none"
}

export const CardRightSectionModes = {
    Delete: "delete",
    Select: "select",
    None: "none"
}

export const CardProperties = {
    sourceId: 0,
    title: "",
    subTitle: "",
    titleClassName: "",
    subTitleClassName: "",
    className: "card-item-content-default",
    hoverClassName: "card-item-hover",
    borderColor: "var(--card-border)",
    backgroundColor: "var(--card-body-bg)",
    clipboardText: "",
    type: "",
    mode: CardModes.None, // "none", "single" or "multible"
    onClick: null,
    onDeleteClick: null,
    onSelect: null,
    isBorderLeftFat: false,
    isTentative: false,
    isSwipeEnabled: false,
    isDirty: false,
    hasValidationError: false,
    canSelect: true,
    actions: [],
    sheetActions: [],
    toggleDelete: null,
    onToggleDelete: null,
    toggleSelect: null,
    onToggleSelect: null,
    onMouseOver: null,
    onMouseOut: null,

    intervalCounterLimit: 20,
    actionSheetMaxWidth: 460,

    canClick: function () {
        return !isObjectNull(this.onClick);
    },

    canDelete: function () {
        return !isObjectNull(this.onDeleteClick);
    },

    canParentSelect: function () {
        return !isObjectNull(this.onSelect);
    },

    canUseActionSheet: function () {
        return !isArrayEmpty(this.sheetActions);
    },

    hasHeader: function () {
        return !isNullOrEmpty(this.title) ||
            !isNullOrEmpty(this.subTitle) ||
            !isArrayEmpty(this.actions) ||
            this.canDelete()
    },

    hasHeaderContent: function () {
        return !isNullOrEmpty(this.title) ||
            !isNullOrEmpty(this.subTitle) ||
            !isArrayEmpty(this.actions)
    },

    handleOnMouseOver: function () {
        if (isObjectNull(this.onMouseOver)) return;
        this.onMouseOver();
    },

    handleOnMouseOut: function () {
        if (isObjectNull(this.onMouseOut)) return;
        this.onMouseOut();
    },
}