import React from 'react';
import {
    CardBody as KendoCardBody,
} from "@progress/kendo-react-layout";
import { isObjectNull } from '../../../helpers/ObjectHelpers';
import { isMobile } from '../../../helpers/DeviceHelpers';

export const CardContentBody = (
    {
        children,
        properties = null
    }) => {
        

    return (
        (!isObjectNull(children)) &&
        <KendoCardBody className={`card-item-content-body ${getClassName()}`}>
            <div
                onClick={handleOnClick}
                style={{
                    paddingLeft: properties.isBorderLeftFat ? 10 : 2
                }}
            >
                {children}
            </div>
        </KendoCardBody>
    );

    function getClassName() {

        if (properties.hasHeaderContent()) return '';

        if (properties.canDelete()) return 'card-item-content-body-header-content'

        return '';
    }

    function handleOnClick() {
        if (!properties.canClick()) return;
        if (!properties.isSwipeEnabled) {
            properties.onClick();
        }
        else {
            if (isMobile()) return;
            properties.onClick();
        }
    }
};