
function getStringUrlParam(param) {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get(param);
}

function getNumericUrlParam(param) {
    return Number(getStringUrlParam(param));
}

function cleanUrl(url) {
    return url.replace('&amp;', '&');
}


export {
    getStringUrlParam,
    getNumericUrlParam,
    cleanUrl
}