import React from 'react';
import parse from 'html-react-parser';
import { ModuleCard } from '../../../components/layout/ModuleCard';
import ModulesGrid from '../../../components/layout/ModulesGrid';

export function DefaultCard({ title, subTitle, description, modules = [], isPilot }) {

    return (
        <>
            <DefaultContentCard className="title" content={title} />
            <DefaultContentCard className="subtitle" content={subTitle} />
            <DefaultContentCard className="description" content={description} />
            {
                (modules.length > 0) && 
                <ModulesGrid>
                    {
                        modules.map((module, index) =>
                            <ModuleCard key={index} module={module} isPilot={isPilot} />
                        )
                    }
                </ModulesGrid>
            }
            
        </>
    );
}

export function DefaultContentCard({ className, content = "" }) {

    return (
        <>
            {
                (content !== null && content.length > 0) &&
                    <div className={className}>{parse(content)}</div>
            }
        </>
    );
}


